import React from 'react';
import styled from '@emotion/styled';
import * as utils from '@utils';
import { Accessory } from '@store/types';

import { colors, mixins, respondTo, respondFrom, breakpoints, css } from '@styles';
import Image from 'gatsby-image';
import ButtonCart from '@components/common/ButtonCart';
import BadgeRecommended from '@components/common/BadgeRecommended';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

const ProductAccessoriesListItem = styled.div`
  margin: 10px 0;
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex: 1 0 25%;
      padding: 8px;
      min-width: 180px;

      ${mixins.transitionDefault}
    `
  )}

  ${respondFrom(
    breakpoints.xxl,
    css`
      max-width: 280px;
    `
  )}
`;

const ProductAccessoriesListItemInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  height: 100%;
  background-color: ${colors.white};
  padding: 15px;
  min-height: 95px;

  ${mixins.borderOnHover}
  border: ${colors.gray} solid 2px;

  ${respondFrom(
    breakpoints.lg,
    css`
      height: 100%;
      min-height: 130px;
    `
  )}

  .inactive > & {
    opacity: 0.5;
    border-color: ${colors.gray} !important;
  }
  .active > & {
    border-color: ${colors.blue.default} !important;
    cursor: pointer;
  }
`;

const ProductAccessoriesItemImage = styled(Image)`
  align-self: center;
  margin-right: 10px;
  max-height: 100%;

  ${respondTo(
    breakpoints.lg,
    css`
      width: 90px !important;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 40%;
      max-height: 75px;
      align-self: flex-start;
    `
  )}
`;

const ProductAccessoriesItemContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;

  ${respondFrom(
    breakpoints.lg,
    css`
      min-width: 100px;
    `
  )}

  > div {
    ${respondFrom(
      breakpoints.sm,
      css`
        display: flex;
        justify-content: space-between;
      `
    )}

    ${respondFrom(
      breakpoints.lg,
      css`
        display: block;
      `
    )}
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 0.2em;
  }

  span {
    display: inline-block;
    white-space: nowrap;
    /* min-width: 50px; */
  }

  button {
    padding: 5px 0 0;
    width: 80px; /* zmiana tekstu, stała szerokość */

    ${respondFrom(
      breakpoints.sm,
      css`
        position: absolute;
        right: 10px;
        bottom: 15px;
      `
    )}

    ${respondFrom(
      breakpoints.lg,
      css`
        position: static;
      `
    )}
  }
`;

const BadgeRecommendedStyled = styled.div`
  position: absolute;
  top: -32px;
  left: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      top: -25px;
      left: 8px;
      right: 8px;
    `
  )}
`;

interface AccessoriesListItemProps extends LocationDependent {
  isInactive: boolean;
  isAdded: boolean;
  accessory: Accessory;
  toggleForCurrentProduct: Function;
  iconPlus?: boolean;
  pageSettings: ConfiguratorSettings;
}

export default (props: AccessoriesListItemProps) => (
  <ProductAccessoriesListItem
    onClick={() => {
      if (!props.isInactive) {
        props.toggleForCurrentProduct(props.accessory);
      }
    }}
    className={`
      ${props.isInactive ? 'inactive' : ''}
      ${props.isAdded ? 'active' : ''}
    `}
  >
    {utils.setRecommendedBadge(props.location, props.accessory.recommended || []) && (
      <BadgeRecommendedStyled>
        <BadgeRecommended text={props.pageSettings.field_proste_pola[89]} />
      </BadgeRecommendedStyled>
    )}
    <ProductAccessoriesListItemInner>
      {props.accessory.image && (
        <ProductAccessoriesItemImage
          {...props.accessory.image.childImageSharp}
          imgStyle={{ objectFit: 'contain' }}
        />
      )}
      <ProductAccessoriesItemContent>
        <div>
          <h5>{props.accessory.name}</h5>
          <span>{utils.formatCurrency(props.accessory.price.toFixed(2))}</span>
        </div>

        {!props.isInactive && (
          <ButtonCart
            title={
              !props.isAdded
                ? props.pageSettings.field_proste_pola[32]
                : props.pageSettings.field_proste_pola[36]
            }
            action={() => {}}
            choiceImg={'addToCart'}
            iconPlus={props.iconPlus}
            added={props.isAdded ? true : false}
          />
        )}
      </ProductAccessoriesItemContent>
    </ProductAccessoriesListItemInner>
  </ProductAccessoriesListItem>
);
