import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import styled from '@emotion/styled';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorBasket } from '@store/types';
import { breakpoints, colors, css, mixins, respondFrom } from '@styles';
import React, { useMemo } from 'react';
import ReactSlider from 'react-slider';

const StyledLocalizations = styled.div`
  margin-bottom: 55px;
`;

const StyledHeadline = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.green.summary};
  text-align: center;
  margin-bottom: 15px;
`;

const StyledSlider = styled.div`
  position: relative;
  height: 70px;
  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #707070;
    z-index: 0;
  }
`;

const StyledSliderWrapper = styled.div`
  max-width: 430px;
  margin: 0 auto;
`;

const StyledSliderTrack = styled.div`
  height: 22px;
`;

const StyledSliderMark = styled.div<StyledNumberProps>`
  top: 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transform: translateX(-50%);
  ${mixins.transitionDefault};
  ${respondFrom(
    breakpoints.md,
    css`
      white-space: nowrap;
    `
  )};
  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  ${({ selected }) =>
    selected &&
    css`
      color: ${colors.green.pastel};
    `};
`;

const StyledSliderThumb = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${colors.green.pastel};
  border-radius: 50%;
  transform: translateX(-50%);
  ${mixins.transitionDefault};

  &:focus-visible {
    outline: none;
  }
`;

interface StyledNumberProps {
  inactive: boolean;
  selected: boolean;
}

interface Props {
  configuratorActions: typeof ConfiguratorActions;
  basket: ConfiguratorBasket;
  maxLocalizations: number;
  pageSettings: ConfiguratorSettings;
}

const TariffLocalizations = (props: Props) => {
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props.pageSettings]);

  if(props.basket.products.length < 2) return null;

  return (
    <StyledLocalizations>
      <StyledHeadline>{pPole[174]}</StyledHeadline>
      <StyledSlider>
        <StyledSliderWrapper>
          <ReactSlider
            marks={Array(props.maxLocalizations + 1)
              .fill(null)
              .map((_, i) => i + 1)}
            defaultValue={1}
            min={1}
            max={props.maxLocalizations + 1}
            value={props.basket.tariffLocalizations}
            onChange={value => {
              if (value <= props.basket.products.length) {
                props.configuratorActions.basketSetTariffLocalizations(value);
              }
            }}
            renderTrack={trackProps => <StyledSliderTrack {...trackProps} />}
            renderThumb={thumbProps => <StyledSliderThumb {...thumbProps} />}
            renderMark={markProps => (
              <StyledSliderMark
                {...markProps}
                inactive={markProps.key > props.basket.products.length}
                selected={markProps.key === props.basket.tariffLocalizations}
              >
                {markProps.key <= props.maxLocalizations && markProps.key}
                {markProps.key > props.maxLocalizations &&
                  `${pPole[175]} ${props.maxLocalizations}`}
              </StyledSliderMark>
            )}
          />
        </StyledSliderWrapper>
      </StyledSlider>
    </StyledLocalizations>
  );
};

export default TariffLocalizations;
