import React, { useLayoutEffect, useMemo, useRef } from 'react';
import Title from '@components/common/Title';
import { Subtitle } from '@components/common/Subtitle';
import { ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import MetaPage from '@components/common/MetaPage';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import Container from '@components/layout/Container';
import styled from '@emotion/styled';
import FiscalizationsList from '@components/configurator/fiscalizations/FiscalizationsList';
import ContactFormCalendarPanel from '@components/configurator/contactForm/ContactFormCalendarPanel';
import { FormCalendar, Fiscalization } from '@store/content/types';
import smoothscroll from 'smoothscroll-polyfill';

if (typeof window !== typeof undefined) {
  smoothscroll.polyfill();
}

const StyledWrapper = styled.div``;

const StyledSubtitle = styled(Subtitle)`
  br {
    display: block;
  }
`;

interface Props {
  configuratorActions: typeof ConfiguratorActions;
  basket: ConfiguratorBasket;
  pageSettings: ConfiguratorSettings;
  calendarDaysToVisit: number;
  formCalendar: FormCalendar;
  fiscalizations: Fiscalization[];
}

export default (props: Props) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props.pageSettings]);
  // on mount, update
  useLayoutEffect(() => {
    if (
      (calendarRef.current &&
        props.basket.fiscalization &&
        props.basket.fiscalization.appointmentAvailable) ||
      (calendarRef.current && props.formCalendar.selectServiceDate)
    ) {
      // scroll to calendar
      calendarRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [props.basket.fiscalization, props.formCalendar.selectServiceDate]); // only when fiscalization changes

  return (
    <StyledWrapper>
      <Container mode={{ breakpoints: 'md', width: 'narrow' }}>
        <MetaPage title="Krok 3 - Sklep" />
        <Title>{pPole[169]}</Title>
        <StyledSubtitle>
          {pPole[170]} <br /> {pPole[171]}
        </StyledSubtitle>
        <FiscalizationsList
          configuratorActions={props.configuratorActions}
          basket={props.basket}
          pageSettings={props.pageSettings}
          fiscalizations={props.fiscalizations}
        />
        <ContactFormCalendarPanel
          ref={calendarRef}
          configuratorActions={props.configuratorActions}
          formCalendar={props.formCalendar}
          pageSettings={props.pageSettings}
          calendarDaysToVisit={props.calendarDaysToVisit}
          visible={
            !!(props.basket.fiscalization && props.basket.fiscalization.appointmentAvailable)
          }
          fiscalization={props.basket.fiscalization}
        />
      </Container>
    </StyledWrapper>
  );
};
