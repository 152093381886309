import styled from '@emotion/styled';
import { respondFrom, breakpoints, css, dimensions } from '@styles';

export const Subtitle = styled.p`
  padding: 0 30px;
  text-align: center;
  margin-bottom: 30px;
  font-size: ${dimensions.fontSize.small}px;

  ${respondFrom(
    breakpoints.md,
    css`
      font-size: ${dimensions.fontSize.regular}px;

      br {
        display: none;
      }
    `
  )};
`;
