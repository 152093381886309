import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import ButtonLink from '@components/common/ButtonLink';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import Image from 'gatsby-image';

const StyledTariffPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImage = styled(Image)`
  margin-bottom: 40px;
`;

const StyledTariffPhoneContent = styled.div`
  margin-bottom: 40px;
  max-width: 625px;
  text-align: center;
  h3 {
    font-size: 30px;
    font-weight: 300;
  }
`;

const StyledButtonLink = styled.div`
  z-index: 0;
`;

interface TariffPhoneProps {
  pageSettings: ConfiguratorSettings;
}

const TariffPhone = (props: TariffPhoneProps) => {
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props.pageSettings]);
  const iPole = useMemo(() => props.pageSettings.field_images || [], [props.pageSettings]);
  return (
    <StyledTariffPhone>
      {iPole[0] && (
        <StyledImage {...iPole[0].childImageSharp} imgStyle={{ objectFit: 'contain' }} />
      )}
      <StyledTariffPhoneContent>
        <h3>{pPole[180]}</h3>
      </StyledTariffPhoneContent>
      <StyledButtonLink>
        <ButtonLink extend target="_self" to="javascript:callpage('api.widget.open');">
          {pPole[176]}
        </ButtonLink>
      </StyledButtonLink>
    </StyledTariffPhone>
  );
};

export default TariffPhone;
