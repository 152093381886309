import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css, colors } from '@styles';
import { Accessory, BasketProduct, ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

import Title from '@components/common/Title';
import AccessoriesTable from '@components/configurator/accessories/AccessoriesTable';
import AccessoriesList from '@components/configurator/accessories/AccessoriesList';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import * as utils from '@utils';
import { Funding } from '@store/content/types';

const StyledTitle = styled(Title)`
  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    `
  )}
`;

const ProductAccessoriesWrapper = styled.section`
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 100px);
    `
  )}
`;

const StyledAccessoriesList = styled(AccessoriesList)`
  ${respondFrom(
    breakpoints.lg,
    css`
      max-height: 30%;
      background: ${colors.ui.whisper};
    `
  )}
`;

interface Props extends LocationDependent {
  accessories: Accessory[];
  basket: ConfiguratorBasket;
  currentProductIndex: number;
  configuratorActions: typeof ConfiguratorActions;
  currentProduct: BasketProduct;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  allFundings: Funding[];
}

class ConfiguratorAccessories extends React.Component<Props> {
  toggleForCurrentProduct = (accessory: Accessory) => {
    const { name, id, price } = accessory;

    if (!this.props.currentProduct.accessories.find(a => a.id === accessory.id)) {
      this.props.configuratorActions.basketAddAccessory({ accessory });
      utils.setDataLayerAddToCart(name, id, price, 'Accessory');
    } else {
      this.props.configuratorActions.basketRemoveAccessory({ accessory });
      utils.setDatalayerRemoveFromCart(name, id, price, 'Accessory');
    }
  };

  componentDidUpdate() {
    const { basket, currentProductIndex, configuratorActions, langcode, urls } = this.props;

    if (this.props.basket.products.length <= 0 && typeof window !== typeof undefined) {
      navigate('/sklep');
    } else if (!utils.productHasAvailableAccessories(basket.products[currentProductIndex])) {
      if (
        currentProductIndex + 1 === basket.products.length &&
        typeof window !== typeof undefined
      ) {
        // last product in basket
        navigate(utils.langLink(langcode, urls[6]));
        return null;
      } else {
        // iterate through products until we find one with colors
        for (let i = currentProductIndex + 1; i < basket.products.length; i++) {
          if (utils.productHasAvailableAccessories(basket.products[i])) {
            configuratorActions.setCurrentProductIndex(i);
            break;
          }
        }
      }
    }
  }

  render() {
    return (
      <ProductAccessoriesWrapper>
        <MetaPage title="Krok 1.5 - Sklep" />
        <StyledTitle>
          {this.props.pageSettings.field_proste_pola
            ? this.props.pageSettings.field_proste_pola[35]
            : ''}
        </StyledTitle>

        <AccessoriesTable {...this.props} toggleForCurrentProduct={this.toggleForCurrentProduct} />

        <StyledAccessoriesList {...this.props} />
      </ProductAccessoriesWrapper>
    );
  }
}

export default ConfiguratorAccessories;
