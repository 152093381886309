import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import * as utils from '@utils';

import ShoppingBasket from '@components/configurator/shoppingCart/ShoppingBasket';
import ShoppingSummaryMobile from '@components/configurator/shoppingCart/ShoppingSummaryMobile';
import ShoppingSummaryLoader from '@components/configurator/shoppingCart/ShoppingSummaryLoader';
import Modal from '@components/common/Modal';
import ShoppingSummaryLeasingModal from '@components/configurator/shoppingCart/ShoppingSummaryLeasingModal';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

import { RootState, RootActions } from '@store/types';
import { Product, Funding, FormData, LeasingInfo } from '@store/content/types';
import { LocationDependent } from '@content/types/general';
import DataLayer from '@components/layout/DataLayer';
import BodyClassName from 'react-body-classname';
import ShoppingSummaryPaymentTypeModal from './ShoppingSummaryPaymentTypeModal';
import ShoppingSummaryDiscountModal from './ShoppingSummaryDiscountModal';
import { Agreement } from '@content/types/agreement';

const RightContainer = styled.div<StyleProps>`
  position: fixed;
  bottom: -30px;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 150px;
  padding-bottom: 30px;
  border-top: 1px solid ${colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  top: 0;
  background-color: ${colors.white};
  z-index: 10;
  ${mixins.transitionDefault};
  transform: ${props => (props.mobileSummaryOpened ? 'translateY(0)' : 'translateY(110%)')};

  &::after {
    content: '';
    position: absolute;
    bottom: -20vh;
    left: 0;
    right: 0;
    height: 20vh;
    background-color: ${colors.text.default};
    z-index: 5;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      position: static;
      width: 432px;
      height: auto;
      border: none;
      transform: none;

      &::after {
        content: none;
      }
    `
  )}
`;

interface SummaryPanel extends RootState, RootActions, LocationDependent {
  activeStep: string;
  allFundings: Funding[];
  formData: FormData;
  onPayButtonClick: Function;
  paymentType: string;
  leasingInfo: LeasingInfo;
  summaryPanelHiden: boolean;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  isOrderLoading: boolean;
  currentFundings: Funding[];
  products: Product[];
  agreements: Agreement[];
}

interface StyleProps {
  activeStep?: string;
  mobileSummaryOpened?: boolean;
}

const ClientSideShoppingSummary = React.lazy(() =>
  import('@components/configurator/shoppingCart/ShoppingSummary')
);

class ShoppingSummaryPanel extends Component<SummaryPanel> {
  state = {
    mobileSummaryOpened: false,
    productsInBasket: false,
    mobileShowAllProducts: false,
    showDiscountModal: false
  };

  onSummaryOpenOnMobile = () => this.setState({ mobileSummaryOpened: true });
  onSummaryCloseOnMobile = () => this.setState({ mobileSummaryOpened: false });
  onShowAllProductsOnMobile = () => this.setState({ mobileShowAllProducts: !this.state.mobileShowAllProducts });
  onShowDiscountModal = () => this.setState({ showDiscountModal: !this.state.showDiscountModal });

  changePaymentType = () => {
    this.props.configuratorActions.setPaymentType('cash');
  };

  checkIfProductsInBasket = () => {
    this.setState({
      productsInBasket: this.props.configurator.basket.products.length > 0 ? true : false,
    });
  };

  componentDidUpdate() {
    setTimeout(this.checkIfProductsInBasket, 500);
  }

  // componentDidUpdate() {
  //   utils.setDatalayer(this.props.location, null, this.getPrices().priceNet, null);
  // }

  render() {
    const {
      activeStep,
      configurator,
      configuratorActions,
      formData,
      onPayButtonClick,
      paymentType,
      leasingInfo,
      summaryPanelHiden,
      location,
      pageSettings,
      langcode,
      urls,
      isOrderLoading,
      currentFundings,
      products,
      agreements
    } = this.props;

    const { currentProductIndex } = configurator;
    const isSSR = typeof window === 'undefined';
    const prices = utils.prices.getConfiguratorPricesSummary(configurator.basket, paymentType, leasingInfo);
    const pricesCash = utils.prices.getConfiguratorPricesSummary(configurator.basket, 'cash', leasingInfo);

    return (
      <BodyClassName className={this.state.mobileSummaryOpened ? 'noscroll' : ''}>
        <>
          <RightContainer mobileSummaryOpened={this.state.mobileSummaryOpened}>
            <DataLayer location={location} transactionTotal={pricesCash.oneOff.total.net} />
            <ShoppingBasket
              {...this.props}
              currentProductIndex={currentProductIndex}
              basket={configurator.basket}
              mobileShowAllProducts={this.state.mobileShowAllProducts}
              onSummaryCloseOnMobile={this.onSummaryCloseOnMobile}
              onShowAllProductsOnMobile={this.onShowAllProductsOnMobile}
              paymentType={paymentType}
              products={products}
            />
            {!isSSR && (
              <React.Suspense fallback={<ShoppingSummaryLoader />}>
                <ClientSideShoppingSummary
                  activeStep={activeStep}
                  currentProductIndex={currentProductIndex}
                  configuratorActions={configuratorActions}
                  basket={configurator.basket}
                  active={this.props.configurator.basket.products.length > 0 ? true : false}
                  formData={formData}
                  onPayButtonClick={onPayButtonClick}
                  paymentType={paymentType}
                  leasingInfo={leasingInfo}
                  location={location}
                  pageSettings={pageSettings[langcode]}
                  langcode={this.props.langcode}
                  urls={this.props.urls}
                  isOrderLoading={isOrderLoading}
                  allFundings={this.props.allFundings}
                  configurator={configurator}
                  currentFundings={currentFundings}
                  hide={this.state.mobileShowAllProducts}
                  prices={prices}
                  products={products}
                  agreements={agreements}
                  onAfterRemoveDiscount={this.onShowDiscountModal}
                />
              </React.Suspense>
            )}
          </RightContainer>
          <ShoppingSummaryMobile
            activeStep={activeStep}
            currentProductIndex={currentProductIndex}
            configuratorActions={configuratorActions}
            basket={configurator.basket}
            active={this.state.productsInBasket}
            onSummaryOpenOnMobile={this.onSummaryOpenOnMobile}
            formData={formData}
            onPayButtonClick={onPayButtonClick}
            paymentType={paymentType}
            leasingInfo={leasingInfo}
            summaryPanelHiden={summaryPanelHiden}
            location={location}
            pageSettings={pageSettings[langcode]}
            langcode={this.props.langcode}
            urls={this.props.urls}
            isOrderLoading={isOrderLoading}
            prices={prices}
            agreements={agreements}
            configurator={configurator}
          />
          <Modal
            isOpened={
              paymentType === 'leasing' && prices.monthly.leasingBlocked
                ? true
                : false
            }
            onCloseModalPanel={this.changePaymentType}
            content={
              <ShoppingSummaryLeasingModal
                configuratorActions={configuratorActions}
                basket={configurator.basket}
                maxPriceForLeasing={`${leasingInfo.maxprice} zł`}
                pageSettings={pageSettings[langcode]}
                paymentType={paymentType}
              />
            }
          />
           <Modal
            isOpened={utils.isSomeProductUnavailable(configurator.basket, configurator.paymentType)}
            onCloseModalPanel={this.changePaymentType}
            content={
              <ShoppingSummaryPaymentTypeModal
                pageSettings={pageSettings[langcode]}
                configuratorActions={configuratorActions}
                basket={configurator.basket}
                paymentType={configurator.paymentType}
              />
          }
        />
        <Modal
          isOpened={this.state.showDiscountModal}
          onCloseModalPanel={this.onShowDiscountModal}
          content={
              <ShoppingSummaryDiscountModal
                  pageSettings={pageSettings[langcode]}
              />
          }
        />
        </>
      </BodyClassName>
    );
  }
}

export default ShoppingSummaryPanel;
