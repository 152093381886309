import React from 'react';

import { Product, Funding } from '@store/content/types';
import Title from '@components/common/Title';
import ProductsList from '@components/configurator/products/ProductsList';
import { ConfiguratorDependent } from '@store/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import MetaPage from '@components/common/MetaPage';
import ConfiguratorInfoMessage from '@components/common/ConfiguratorInfoMessage';
import { LocationDependent } from '@content/types/general';
import Container from '@components/layout/Container';

interface ProductsProps extends ConfiguratorDependent, LocationDependent {
  products: Product[];
  pageSettings: ConfiguratorSettings;
  langcode: string;
  allFundings: Funding[];
}

export const ConfiguratorTerminals = (props: ProductsProps) => {
  return (
    <Container mode={{ breakpoints: "md", width: "narrow" }}>
      <MetaPage title="Sklep" />
      <Title>
        {props.pageSettings.field_proste_pola ? props.pageSettings.field_proste_pola[30] : ''}
      </Title>

      {props.pageSettings.field_enable_info_text && (
        <ConfiguratorInfoMessage
          message={
            props.pageSettings.field_info_text ? props.pageSettings.field_info_text.value : ''
          }
        />
      )}

      <ProductsList {...props} />
    </Container>
  );
};

export default ConfiguratorTerminals;
