/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, breakpoints, css } from '@styles';
import addIcon from '@assets/svg/plus-icon.svg';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

const ContactFormModal = styled.div`
  h4 {
    text-align: center;

    ${respondFrom(
      breakpoints.md,
      css`
        text-align: left;
      `
    )}
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0;

  ${respondFrom(
    breakpoints.md,
    css`
      margin: 40px 0 0;
    `
  )}

  li {
    display: flex;
    flex-flow: column;
    border-bottom: 1px solid ${colors.gray};
    padding: 15px 0 10px;

    ${respondFrom(
      breakpoints.md,
      css`
        flex-flow: row;
        align-items: center;
        padding: 10px 0;
      `
    )}

    &:last-of-type {
      border: none;
    }

    div,
    span {
      font-size: ${dimensions.fontSize.small}px;

      &.hide-on-desktop {
        ${respondFrom(
          breakpoints.md,
          css`
            display: none;
          `
        )}
      }
    }

    > div {
      display: flex;
      margin: 3px 0;

      > span {
        flex: 1;

        &:first-of-type {
          width: 80px;
          flex: none;
        }
      }
    }

    &.list-header {
      font-weight: 700;
      text-transform: uppercase;
      display: none;

      ${respondFrom(
        breakpoints.md,
        css`
          display: flex;
        `
      )}
    }

    div {
      ${respondFrom(
        breakpoints.md,
        css`
          min-width: calc(100% / 6 + 10px);
          max-width: calc(100% / 6 + 10px);
          margin-right: 10px;

          &:nth-of-type(odd) {
            min-width: calc((100% / 6) * 2 - 10px);
            max-width: calc((100% / 6) * 2 - 10px);
          }
        `
      )}

      span {
        margin-right: 5px;
        display: inline-block;
      }
    }
  }
`;

const SelectAddress = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  ${respondFrom(
    breakpoints.md,
    css`
      justify-content: flex-start;
      margin: 0;
    `
  )}

  &:focus {
    outline: none;
  }

  span {
    text-transform: uppercase;
    color: ${colors.green.dark};
  }

  img {
    margin-left: 7px;
    margin-bottom: 2px;
  }
`;

interface ContactFormModal {
  nipData: any;
  updateFieldsByNip: Function;
  pageSettings: ConfiguratorSettings;
}

export default (props: ContactFormModal) => {
  const field = props.pageSettings.field_proste_pola;

  return (
    <ContactFormModal>
      <h4>{field ? field[103] : ''}</h4>
      <List>
        <li className="list-header">
          <div>{field ? field[104] : ''}</div>
          <div>{field ? field[105] : ''}</div>
          <div>{field ? field[106] : ''}</div>
          <div></div>
        </li>
        {props.nipData.map((data, index) => (
          <li key={index}>
            <div>
              <span className="hide-on-desktop">{field ? field[104] : ''}</span>
              <span>{data.name}</span>
            </div>
            <div>
              <span className="hide-on-desktop">{field ? field[105] : ''}</span>
              <span>{data.regon}</span>
            </div>
            <div>
              <span className="hide-on-desktop">{field ? field[106] : ''}</span>
              <span>
                <span>{data.address.city !== '' && `${data.address.city},`}</span>
                <span>
                  <span>{data.address.street}</span>
                  <span>{data.address.num1}</span>
                  {data.address.num2 && <span> / {data.address.num2}</span>}
                </span>
              </span>
            </div>
            <SelectAddress onClick={() => props.updateFieldsByNip(data)}>
              <span>{field ? field[32] : ''}</span>
              <img src={addIcon} alt="" />
            </SelectAddress>
          </li>
        ))}
      </List>
    </ContactFormModal>
  );
};
