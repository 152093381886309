import React from 'react';
import styled from '@emotion/styled';

import { respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';
import Button from '@components/common/Button';
import { ProductPopup } from '@content/types/product';

const StyledProductPopup = styled.div``;

const StyledProductPopupTitle = styled.h3``;

const StyledProductPopupText = styled.div`
  padding: 0 20px;
  margin-bottom: 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    max-width: 350px;
    margin-bottom: 10px;
    padding: 1em !important;
    white-space: normal !important;
  }

  ${respondFrom(
  breakpoints.lg,
  css`
      flex-direction: row;
      justify-content: space-around;
      margin: 0 -20px;

      > * {
        margin: 0 20px 10px 20px;
        white-space: nowrap !important;
      }
    `
)}
`;

interface Props {
  onClickConfirm: Function;
  onClickCancel: Function;
  popup: ProductPopup;
}

export default (props: Props) => (
  <StyledProductPopup>
    <StyledProductPopupText>
      {
        props.popup.title && 
        <StyledProductPopupTitle>{props.popup.title}</StyledProductPopupTitle>
      }
      {props.popup.body &&
        utils.SafeHtml(props.popup.body)}
    </StyledProductPopupText>
    <ButtonsWrapper>
      <Button
        color="outlined"
        size="small"
        onClick={() => props.onClickCancel()}
      >
        {props.popup.buttonBack &&
          props.popup.buttonBack}
      </Button>
      <Button
        color="primary"
        size="small"
        onClick={() => props.onClickConfirm()}
      >
        {props.popup.buttonOk &&
          props.popup.buttonOk}
      </Button>
    </ButtonsWrapper>
  </StyledProductPopup>
);
