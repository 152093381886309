import React, { Component } from 'react';
import { navigate } from 'gatsby';
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import get from 'lodash.get';

import * as utils from '@utils';
import { respondTo, respondFrom, breakpoints, css, colors } from '@styles';
import { ConfiguratorBasket, BasketProduct } from '@store/types';
import { ConfiguratorActions } from '@store/actions';

import Title from '@components/common/Title';
import ProductColorPicker from '@components/configurator/colors/ProductColorPicker';
import BadgeRecommended from '@components/common/BadgeRecommended';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { Funding } from '@store/content/types';

const ProductColorContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const ProductColorContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-flow: column;
  height: 100%;
  position: relative;
`;

const ProductColorImage = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  height: calc(100vh - 415px);

  ${respondTo(
    breakpoints.lg,
    css`
      width: 100%;
      padding: 0 20px;

      > div {
        width: 100% !important;
        height: 100% !important;

        @supports (-moz-appearance: none) and (text-align-last: auto) {
          height: 200px !important;
        }
      }
    `
  )}
`;

const StyledProductColorPicker = styled(ProductColorPicker)`
  background-color: ${colors.grayNurse};
  width: 100%;
  padding: 20px 0;
  padding-top: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 30px 0;
    `
  )}
`;

const BadgeRecommendedStyled = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  max-width: 650px;
  margin: auto;
`;

const CustomImageWrapper = styled(Image)`
  max-height: 60vh;
`;

interface ColorsProps extends LocationDependent {
  basket: ConfiguratorBasket;
  currentProductIndex: number;
  configuratorActions: typeof ConfiguratorActions;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  allFundings: Funding[];
}

class ColorConfigurator extends Component<ColorsProps> {
  changeColor = color => {
    this.props.configuratorActions.basketSetCurrentColor(color);
  };

  componentDidUpdate() {
    const { basket, currentProductIndex, configuratorActions, langcode, urls } = this.props;

    if (this.props.basket.products.length <= 0 && typeof window !== typeof undefined) {
      navigate('/sklep');
    } else if (!utils.productHasAvailableColors(basket.products[currentProductIndex])) {
      if (
        currentProductIndex + 1 === basket.products.length &&
        typeof window !== typeof undefined
      ) {
        // last product in basket
        navigate(utils.langLink(langcode, urls[10]));
        return null;
      } else {
        // iterate through products until we find one with colors
        for (let i = currentProductIndex + 1; i < basket.products.length; i++) {
          if (utils.productHasAvailableColors(basket.products[i])) {
            configuratorActions.setCurrentProductIndex(i);
            break;
          }
        }
      }
    }
  }

  render() {
    let product: BasketProduct | null = null;
    const {
      basket,
      currentProductIndex,
      configuratorActions,
      location,
      langcode,
      urls,
      allFundings,
    } = this.props;

    if (
      basket.products.length === 1 &&
      currentProductIndex !== 0 &&
      typeof window !== typeof undefined
    ) {
      navigate(utils.langLink(langcode, urls[10]));
      return null;
    }

    if (utils.productHasAvailableColors(basket.products[currentProductIndex])) {
      product = basket.products[currentProductIndex];
    }

    const imageSharp = get(product, 'currentColor.image.childImageSharp');

    return (
      <>
        <MetaPage title="Krok 1 - Sklep" />
        <ProductColorContainer>
          {product && (
            <>
              <Title>
                {this.props.pageSettings.field_proste_pola
                  ? this.props.pageSettings.field_proste_pola[33]
                  : ''}
              </Title>

              <ProductColorContent>
                {utils.setRecommendedBadge(
                  location,
                  product.currentColor ? product.currentColor.recommended : []
                ) && (
                  <BadgeRecommendedStyled>
                    <BadgeRecommended
                      text={
                        this.props.pageSettings.field_proste_pola
                          ? this.props.pageSettings.field_proste_pola[89]
                          : ''
                      }
                    />
                  </BadgeRecommendedStyled>
                )}
                {imageSharp && (
                  <ProductColorImage>
                    <CustomImageWrapper {...imageSharp} imgStyle={{ objectFit: 'contain' }} />
                  </ProductColorImage>
                )}
                <StyledProductColorPicker
                  product={product}
                  configuratorActions={configuratorActions}
                  pageSettings={this.props.pageSettings}
                  basket={basket}
                  allFundings={allFundings}
                />
              </ProductColorContent>
            </>
          )}
        </ProductColorContainer>
      </>
    );
  }
}

export default ColorConfigurator;
