/* eslint-disable @typescript-eslint/camelcase */
import React, { Component, createRef } from 'react';
/*
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
*/
import Image, { GatsbyImageProps } from 'gatsby-image';
import styled from '@emotion/styled';
import get from 'lodash.get';

import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import { BasketProduct, ConfiguratorDependent, ProductColor } from '@store/types';
import { Product, Funding } from '@store/content/types';

import Badge from '@components/common/Badge';
import BadgeRecommended from '@components/common/BadgeRecommended';
import ButtonCart from '@components/common/ButtonCart';
import ButtonRound from '@components/common/ButtonRound';
import IconTick from '@assets/svg/feature-incl-icon.svg';

import * as utils from '@utils';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import Modal from '@components/common/Modal';
import ProductPopup from './ProductPopup';
import { ProductPrice } from '@content/types/product';
import ClientOnly from '@components/common/ClientOnly';
import Portal from '@components/common/Portal';
import { keyframes } from '@emotion/core';
import { PriceListPaymentTypes } from '@graphql/types';

const ProductItemWrapper = styled.div<WrapperProps>`
  height: 100%;
  order: ${props => props.weight};

  &.recommended {
    margin-top: 50px;

    ${respondFrom(
      breakpoints.md,
      css`
        margin-top: 0;
      `
    )}
  }
`;

const ProductItem = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  &:hover {
    cursor: auto !important;
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px ${colors.gray};
  }

  ${mixins.transitionDefault}
  ${mixins.borderOnHover}
  ${mixins.borderWhenActive}

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2rem;

    ${respondFrom(
      breakpoints.lg,
      css`
        font-size: 30px;
      `
    )}
  }

  p {
    font-weight: 300;
    font-size: 14px;

    strong {
      font-weight: 700;
    }
  }
`;

const StyledProductName = styled.div`
  position: relative;
  max-width: 50%;

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 100%;
    `
  )}
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  margin-top: 30px;
  max-width: 50%;

  &.custom-price {
    position: relative;

    .custom-text {
      position: absolute;
      bottom: -20px;
      text-wrap: nowrap;
    }

    span {
      white-space: unset;
    }
  }

  p {
    width: 100%;
    margin-bottom: 0px;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      display: flex;
      white-space: nowrap;
      max-width: 100%;
    `
  )}
`;

const Price = styled.span`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
  margin-bottom: 8px;
`;

const PricePeriod = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

const RegularPrice = styled.div`
  font-size: 80%;
  font-weight: 100;
  text-decoration: line-through;
  white-space: nowrap;
  margin-right: 10px;

  ${respondFrom(
    breakpoints.xl + 100,
    css`
      font-size: 80%;
    `
  )}
`;

const AddToCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  &.price-with-lease-element {
    margin-top: 12px;
  }

  &.single-product {
    justify-content: flex-end;

    ${respondFrom(
      breakpoints.lg,
      css`
        justify-content: flex-end;
      `
    )}
  }
`;

const StyledButtonCartWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const AddToCartText = styled.div`
  width: 100%;
  margin-top: 30px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddToCartQuantity = styled.div`
  flex: 1;
  padding: 0 10px;
  margin-left: -25px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-left: -20px;
      margin-top: 0;
    `
  )}
`;

const Quantity = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  text-align: center;
  border: solid 1px ${colors.ui.light};
  background-color: ${colors.white};
  margin: 0 10px;
  pointer-events: none;
`;

const animateImage = keyframes`
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(2000px);
  }
`;

const ProductImage = styled(Image)`
  text-align: center;
  position: absolute !important;
  top: 70px;
  right: 15px;
  height: 125px !important;
  width: 180px !important;
  max-width: 50%;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: relative !important;
      height: 170px !important;
      width: calc(100% + 60px) !important;
      margin: 20px 0;
      margin-left: -30px;
      top: 0;
      right: 0;
      max-width: 100%;
    `
  )}
`;

const ProductAnimateImage = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.animate {
    display: block;
    animation: ${animateImage} 2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 99;
  }
`;

const ProductDescription = styled.div`
  font-weight: 300;
  font-size: 14px;

  ${mixins.listDefault};

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    margin-bottom: 0;
    li {
      padding-left: 20px;

      &::before {
        left: 0;
        top: 3px;
        content: url(${IconTick});
      }
    }
  }
`;

const ProductPriceWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  left: 15px;
  right: 15px;

  ${respondFrom(
    breakpoints.lg,
    css`
      left: 30px;
    `
  )}

  &.single-product {
    > p {
      margin-bottom: 70px;

      ${respondFrom(
        breakpoints.lg,
        css`
          margin-bottom: 16px;
        `
      )}
    }
  }
`;

const BadgeRecommendedStyled = styled.div`
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  z-index: 1;

  ${respondFrom(
    breakpoints.md,
    css`
      left: 15px;
      right: 15px;
    `
  )}
`;

const StyledTooltipBranchesWrapper = styled.div`
  display: none;
  ${respondFrom(
    breakpoints.lg,
    css`
      display: block;
    `
  )}
`;

const StyledTooltipBranches = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: inline-flex;
  align-items: center;
  z-index: 1;
`;

const StyledTooltipBranchesText = styled.div`
  flex: 1;
  font-size: 12px;
`;

const StyledTooltipBranchesIcon = styled.div`
  width: 17px;
  height: 17px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue.default};
  border-radius: 50%;
  color: ${colors.white};
  font-size: 13px;
  font-weight: bold;
  margin-left: 5px;
  z-index: 1;
`;

const StyledProductTop = styled.div<ProductTopProps>`
  position: relative;
  padding: 30px 15px 210px 15px;
  background-color: ${colors.ui.whisper};

  ${({ higher }) =>
    higher &&
    css`
      padding-bottom: 240px;
    `};

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 50px 30px 180px 30px;
    `
  )}
`;

const StyledProductBottom = styled.div`
  padding: 15px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 30px;
    `
  )}
`;

const StyledBadgeWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 100%;
  height: 100%;
`;

interface Props extends ConfiguratorDependent, LocationDependent {
  product: Product;
  basketMode?: 'single' | 'nolimit';
  iconPlus?: boolean;
  badge?: string;
  className?: string;
  pageSettings: ConfiguratorSettings;
  allFundings: Funding[];
  productSlug: string;
  productPrice?: ProductPrice;
  isAvailablePaymentType: boolean;
  blocked: boolean;
  onBlocked: Function;
}

interface WrapperProps {
  weight: number;
  isAvailable?: boolean;
}

interface ProductTopProps {
  higher?: boolean;
}

class ConfiguratorProductItem extends Component<Props> {
  state = {
    count: 1,
    productTemp: {
      id: '',
      name: '',
      image: null,
      imageForAccessories: null,
      hasOnline: false,
      description: '',
      price: 0,
      regularPrice: 0,
      weight: 0,
      availableColors: [],
      currentColor: {
        image: null,
      },
      accessories: [],
    },
    recommended: false,
    popupOpened: false,
  };
  productImgAnimateTimer;
  productImgRef = createRef<HTMLDivElement>();

  componentDidMount() {
    setTimeout(() => {
      this.setState({ recommended: true });
    }, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.productImgAnimateTimer);
  }

  incrementProduct = (e: MouseEvent) => {
    e.stopPropagation();
    if (this.state.count <= 10) {
      this.setState({ count: this.state.count + 1 });
    }
  };

  decrementProduct = (e: MouseEvent) => {
    e.stopPropagation();
    if (this.state.count > 1) {
      this.setState({ count: this.state.count - 1 });
    }
  };

  addOrToggle = () => {
    if (!this.isSingleMode()) {
      for (let i = 0; i < this.state.count; i++) {
        const product = this.props.product as BasketProduct;

        product.currentColor = this.props.product.availableColors[0];

        // animate effect on mobile
        if (
          window.innerWidth <= 768 &&
          this.productImgRef.current &&
          !this.productImgRef.current.classList.contains('animate')
        ) {
          this.productImgRef.current.classList.add('animate');
          this.productImgAnimateTimer = setTimeout(() => {
            this.props.configuratorActions.basketAddProduct(product);
            this.productImgRef.current && this.productImgRef.current.classList.remove('animate');
          }, 2000);
        } else {
          this.props.configuratorActions.basketAddProduct(product);
        }
      }

      const { name, id, currentColor } = this.props.product;
      const productPrice = utils.prices.getProductPrice({
        product: this.props.product,
        paymentType: 'cash',
      });
      const price = productPrice ? productPrice.iposFee : 0;
      const variant = currentColor ? currentColor.name : '';

      // add to cart product
      utils.setDataLayerAddToCart(name, id, price, 'Device', variant, this.state.count);
    } else {
      const product = (this.state.productTemp.id !== ''
        ? this.state.productTemp
        : this.props.product) as BasketProduct;

      if (this.isInBasket()) {
        const indexInBasket = this.props.configurator.basket.products.findIndex(
          prod => this.props.product.id === prod.id
        );

        // TODO: do we clean basket?
        this.props.configuratorActions.basketRemoveProduct(indexInBasket);
      } else {
        this.props.configuratorActions.basketAddProduct(product);

        this.state.productTemp.id !== '' &&
          this.props.configuratorActions.basketSetCurrentColor(this.state.productTemp.currentColor);
      }
    }

    this.closePopup();
  };

  isSingleMode = (): boolean => {
    return this.props.basketMode === 'single';
  };

  isBasketEmpty = (): boolean => {
    return !this.props.configurator.basket.products.length;
  };

  isInBasket = (): boolean => {
    return (
      !this.isBasketEmpty() &&
      !!this.props.configurator.basket.products.find(prod => this.props.product.id === prod.id)
    );
  };

  getOnlyColor = (color: ProductColor, product: BasketProduct) => {
    const productWithColor = { ...product };
    productWithColor.currentColor = color;

    this.setState({ productTemp: productWithColor });
  };

  openPopup = () => this.setState({ popupOpened: true });
  closePopup = () => this.setState({ popupOpened: false });

  handleClick = () => {
    if (this.props.blocked) {
      this.props.onBlocked && this.props.onBlocked();
    } else {
      this.addOrToggle();
    }
  };

  render() {
    const {
      product,
      className,
      configuratorActions,
      configurator,
      iconPlus,
      location,
      badge,
      pageSettings,
      productSlug,
      productPrice,
      isAvailablePaymentType,
    } = this.props;

    const currentProduct =
      typeof configurator.currentProductIndex === 'number'
        ? configurator.basket.products[configurator.currentProductIndex]
        : null;

    const imageSharp: GatsbyImageProps | null = product.image && product.image.childImageSharp
      ? product.image.childImageSharp
      : this.isSingleMode() && !!currentProduct && currentProduct.id === product.id
        ? (get(
          currentProduct.currentColor,
          'image.childImageSharp',
          product.image ? product.image.childImageSharp : null
        ) as GatsbyImageProps)
        : this.isSingleMode() && product.availableColors.length // if colors available, display first color
          ? get(
            product.availableColors[0],
            'image.childImageSharp',
            product.image ? product.image.childImageSharp : null
          )
          : null;

    const enabledPopup = this.isSingleMode()
      ? this.props.product.popup.enabled && !this.isInBasket()
      : this.props.product.popup.enabled;

    const isProductInactive = () => {
      return (this.props.configurator.basket.products.length + this.state.count >
        10 &&
        product.isAvailable) ||
        !isAvailablePaymentType ||
        productPrice === undefined ||
        Object.keys(productPrice).length < 1;
    }
    const textToDisplayIsInactive = product.textWhenUnavailable || (pageSettings.field_proste_pola && pageSettings.field_proste_pola[161]) || '';

    const isProductPriceCashAndLease =
      productPrice &&
      productPrice.lease &&
      productPrice.paymentType === PriceListPaymentTypes.casch;

    const activationPriceLabel = pageSettings.field_proste_pola
      ? isProductPriceCashAndLease && productPrice
        ? `(${pageSettings.field_proste_pola[186]} / ${productPrice.contractDuration} ${pageSettings.field_proste_pola[185]})`
        : pageSettings.field_proste_pola[167]
          ? pageSettings.field_proste_pola[167].toLowerCase()
          : ''
      : '';

    return (
      <>
        <ProductItemWrapper
          className={`
          ${className}
          ${this.state.recommended &&
            product.recommended &&
            utils.setRecommendedBadge(location, product.recommended) &&
            'recommended'}
        `}
          weight={product.weight}
          isAvailable={product.isAvailable}
        >
          {this.state.recommended &&
            product.recommended &&
            utils.setRecommendedBadge(location, product.recommended) && (
              <BadgeRecommendedStyled className="recommended-badge">
                <BadgeRecommended
                  text={pageSettings.field_proste_pola ? pageSettings.field_proste_pola[89] : ''}
                />
              </BadgeRecommendedStyled>
            )}

          <ProductItem
            className={`
              ${
                this.isSingleMode() && !this.isBasketEmpty() && !this.isInBasket() ? 'inactive' : ''
              }
              ${this.isSingleMode() && this.isInBasket() ? 'active' : ''}
          `}
          >
            {product !== undefined ? (
              <>
                <StyledProductTop
                  higher={!!product.regularPriceLease && configurator.paymentType === 'lease'}
                >
                  <StyledBadgeWrapper>
                    {product.hasOnline && (
                      <Badge
                        order={0}
                        text={
                          badge
                            ? badge
                            : pageSettings.field_proste_pola
                            ? pageSettings.field_proste_pola[90]
                            : ''
                        }
                      />
                    )}
                    {product.isVirtual && pageSettings.field_proste_pola && (
                      <Badge
                        order={1}
                        color="gray"
                        text={
                          pageSettings.field_proste_pola ? pageSettings.field_proste_pola[123] : ''
                        }
                      />
                    )}
                  </StyledBadgeWrapper>
                  <StyledProductName>
                    <h2>{product.name}</h2>
                    {product.subtitle && (
                      <StyledTooltipBranchesWrapper>
                        <StyledTooltipBranches>
                          <StyledTooltipBranchesText>{product.subtitle}</StyledTooltipBranchesText>
                          {product.tooltipTextForBranches && (
                            <StyledTooltipBranchesIcon
                              data-tip="React-tooltip"
                              data-for={`tooltipBranches-${productSlug}`}
                            >
                              ?
                            </StyledTooltipBranchesIcon>
                          )}
                        </StyledTooltipBranches>
                      </StyledTooltipBranchesWrapper>
                    )}
                  </StyledProductName>
                  {this.state.productTemp.id !== '' &&
                  this.state.productTemp.currentColor.image !== null ? (
                    <ProductImage
                      {...get(this.state.productTemp.currentColor, 'image.childImageSharp', null)}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  ) : (
                    imageSharp && (
                      <>
                        <ProductImage {...imageSharp} imgStyle={{ objectFit: 'contain' }} />
                        <ProductAnimateImage ref={this.productImgRef}>
                          <ProductImage {...imageSharp} imgStyle={{ objectFit: 'contain' }} />
                        </ProductAnimateImage>
                      </>
                    )
                  )}
                  <ClientOnly>
                    <ProductPriceWrapper
                      className={
                        this.props.basketMode && product.availableColors.length > 1
                          ? 'single-product'
                          : ''
                      }
                    >
                      {isProductInactive() ? (
                        product.priceWhenUnavailable
                          ? <PriceWrapper
                            className={'custom-price'}
                          >
                            <Price>{product.priceWhenUnavailable}</Price>
                            <p className={configurator.paymentType !== 'lease' ? 'custom-text' : ''}>{textToDisplayIsInactive}</p>
                          </PriceWrapper>
                          : <p>{textToDisplayIsInactive}</p>
                      )
                        : (
                        <PriceWrapper>
                          {!!product.regularPriceLease && configurator.paymentType === 'lease' && (
                            <RegularPrice>
                              {utils.formatCurrency(product.regularPriceLease)}
                            </RegularPrice>
                          )}
                          {!!product.regularPrice && configurator.paymentType !== 'lease' && (
                            <RegularPrice>{utils.formatCurrency(product.regularPrice)}</RegularPrice>
                          )}
                          {productPrice !== undefined && Object.keys(productPrice).length > 0 && (
                            <Price>
                              <span>
                                {isProductPriceCashAndLease
                                  ? utils.formatCurrency(productPrice.activationPrice)
                                  : utils.formatCurrency(productPrice.iposFee)}
                                {configurator.paymentType === 'lease' &&
                                pageSettings.field_proste_pola
                                  ? ' ' + pageSettings.field_proste_pola[156]
                                  : ''}
                              </span>
                              {configurator.paymentType === 'lease' &&
                                productPrice.contractDuration && (
                                  <PricePeriod>
                                    /{' '}
                                    {`${productPrice.contractDuration} ${
                                      pageSettings.field_proste_pola
                                        ? pageSettings.field_proste_pola[157]
                                        : ''
                                    }`}
                                  </PricePeriod>
                                )}
                            </Price>
                          )}
                          {productPrice && productPrice.activationPrice && (
                            <p>
                              +{' '}
                              <strong>
                                {isProductPriceCashAndLease
                                  ? utils.formatCurrencyPerMonth(productPrice.iposFee)
                                  : utils.formatCurrency(productPrice.activationPrice)}
                              </strong>{' '}
                              {activationPriceLabel}
                            </p>
                          )}
                        </PriceWrapper>
                      )}
                      {product.isAvailable ? (
                        <AddToCart
                          className={`${this.props.basketMode ? 'single-product' : ''} ${isProductPriceCashAndLease ? 'price-with-lease-element' : ''}`}
                        >
                          {this.props.basketMode !== 'single' && (
                            isProductInactive() ? (
                              <AddToCartQuantity/>
                            ) : (
                              <AddToCartQuantity>
                                <ButtonRound
                                  title="-"
                                  action={this.decrementProduct}
                                  disabled={this.state.count <= 1 ? true : false}
                                />
                                <Quantity>{this.state.count}</Quantity>
                                <ButtonRound
                                  title="+"
                                  action={this.incrementProduct}
                                  disabled={this.state.count >= 10 ? true : false}
                                />
                                </AddToCartQuantity>
                            )
                          )}
                          <StyledButtonCartWrapper>
                            <ButtonCart
                              color="primary"
                              size="full"
                              hideIcon
                              title={
                                this.isSingleMode() && this.isInBasket()
                                  ? pageSettings.field_proste_pola
                                    ? pageSettings.field_proste_pola[36]
                                    : ''
                                  : pageSettings.field_proste_pola
                                  ? !isAvailablePaymentType || productPrice === undefined
                                      ? product.buttonTextWhenUnavailable
                                        ? product.buttonTextWhenUnavailable
                                        : pageSettings.field_proste_pola[161]
                                      : pageSettings.field_proste_pola[32]
                                    : ''
                              }
                              choiceImg={'addToCart'}
                              iconPlus={iconPlus}
                              added={this.isSingleMode() && this.isInBasket() ? true : false}
                              inactive={isProductInactive()}
                              action={enabledPopup ? this.openPopup : this.handleClick}
                            />
                          </StyledButtonCartWrapper>
                        </AddToCart>
                      ) : (
                        <AddToCartText>
                          {pageSettings.field_proste_pola
                            ? pageSettings.field_proste_pola[127]
                            : ''}
                        </AddToCartText>
                      )}
                    </ProductPriceWrapper>
                  </ClientOnly>
                </StyledProductTop>
                {!this.isSingleMode() && (
                  <StyledProductBottom>
                    <ProductDescription>{utils.SafeHtml(product.description)}</ProductDescription>
                  </StyledProductBottom>
                )}
              </>
            ) : (
              false
            )}
          </ProductItem>
        </ProductItemWrapper>
        {enabledPopup && (
          <Portal>
            <Modal
              isOpened={this.state.popupOpened}
              content={
                <ProductPopup
                  onClickCancel={this.closePopup.bind(this)}
                  onClickConfirm={this.addOrToggle.bind(this)}
                  popup={this.props.product.popup}
                />
              }
              hideButtonClose
              showMobileBackdrop
              disableClickOutside
            />
          </Portal>
        )}
      </>
    );
  }
}

export default ConfiguratorProductItem;
