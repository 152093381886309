import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';

const FormRadioButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const FormRadioButtonLabel = styled.label`
  position: relative;
  padding: 10px 0 10px 30px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 18px;
    height: 18px;
    border: 1px solid ${colors.ui.light};
    border-radius: 100%;
    margin: auto;
  }

  &.is-active {
    &:before {
      border: 1px solid ${colors.green.dark};
    }

    &:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      background-color: ${colors.green.dark};
      border-radius: 100%;
      margin: auto;
    }
  }

  input {
    display: none;
  }

  > span {
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;

    &.delivery-price {
      font-size: ${dimensions.fontSize.smaller}px;
      margin-left: 5px;
    }
  }
`;

interface FormRadioButtonProps {
  name: string;
  list: RadioList[];
  handleRadioChange: Function;
  radioValue: string;
}

interface RadioList {
  value: string;
  label: string;
  price?: string;
}

const FormRadioButton = ({ name, list, handleRadioChange, radioValue }: FormRadioButtonProps) => {
  return (
    <FormRadioButtonWrapper>
      {list.map(input => (
        <FormRadioButtonLabel
          key={input.value}
          className={radioValue === input.value ? 'is-active' : ''}
        >
          <input
            type="radio"
            name={name}
            value={input.value}
            checked={radioValue === input.value}
            onChange={() => handleRadioChange(input.value)}
          />
          <span>{input.label}</span>
          {input.price && input.price !== '' && (
            <span className="delivery-price">{input.price}</span>
          )}
        </FormRadioButtonLabel>
      ))}
    </FormRadioButtonWrapper>
  );
};

export default FormRadioButton;
