import React from 'react';

import Configurator from '@containers/configurator';
import { Query } from '@graphql/types';
import useConfiguratorQuery from '@graphql/queries/ConfiguratorQuery';
import useSettingsQuery from '@graphql/queries/SettingsQuery';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface ConfiguratorPageProps extends LocationDependent {
  data: Query;
  pageContext: PageContext;
}

export default (props: ConfiguratorPageProps) => {
  return (
    <>
      <MetaPage
        title={useConfiguratorQuery().pageSettings[props.pageContext.langcode].field_meta_title}
        description={
          useConfiguratorQuery().pageSettings[props.pageContext.langcode].field_meta_description
        }
      />

      <Configurator
        {...useConfiguratorQuery()}
        {...useSettingsQuery()}
        activeStep={props.pageContext.id}
        location={props.location}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
