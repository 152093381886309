import React from 'react';
import styled from '@emotion/styled';
import { dimensions, colors, respondFrom, breakpoints, css, mixins } from '@styles';
import Collapsible from 'react-collapsible';
import ProfitItem from '@components/configurator/tariff/ProfitItem';
import { TariffProfitItemProps } from '@store/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

const Accordion = styled.div`
  .Collapsible {
    position: relative;
    padding: 20px;

    &__trigger {
      position: relative;
      display: block;
      padding: 10px;
      padding-left: 20px;
      margin-top: -10px;
      margin-bottom: -10px;
      font-size: ${dimensions.fontSize.smaller}px;
      font-weight: 300;
      color: ${colors.text.default};
      z-index: 2;
      width: 50%;

      ${respondFrom(
        breakpoints.md,
        css`
          display: none;
        `
      )};

      &::before {
        content: '';
        position: absolute;
        top: -5px;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: center;
        ${mixins.transitionDefault};
      }

      &.is-open {
        &::before {
          transform: translate(0, 5px) rotate(225deg);
        }
      }
    }

    &__contentOuter {
      ${respondFrom(
        breakpoints.md,
        css`
          height: auto !important;
        `
      )};
    }
  }
`;

const ProfitContainer = styled.div`
  padding: 20px 0;
`;

interface TariffAccordionProps {
  profits: TariffProfitItemProps[];
  pageSettings: ConfiguratorSettings;

  className?: string;
  langcode: string;
}

export default (props: TariffAccordionProps) => {
  const { profits, className, langcode } = props;

  return (
    <Accordion className={className || ''}>
      <Collapsible trigger={props.pageSettings[langcode].field_proste_pola[95]}>
        <ProfitContainer>
          {profits.map((profit, index) => (
            <ProfitItem {...profit} key={index} {...props} />
          ))}
        </ProfitContainer>
      </Collapsible>
    </Accordion>
  );
};
