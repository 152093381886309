import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';

const Button = styled.button`
  position: relative;
  background: transparent;
  border: none;
  color: ${props => (props.disabled ? colors.ui.light : colors.green.dark)};
  padding: 0;
  cursor: pointer;
  width: 45px;
  height: 45px;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 32px;
      height: 32px;
    `
  )}

  span {
    font-size: 28px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 28.5px;
    height: 28.5px;
    border-radius: 100%;
    border: 2px solid ${props => (props.disabled ? colors.ui.light : colors.green.dark)};
  }

  &:focus {
    outline: none;
  }
`;

interface ButtonProps {
  title: string;
  action: Function;
  disabled: boolean;
}

export default (props: ButtonProps) => {
  const { title, action, disabled } = props;

  return (
    <Button
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => action(e)}
      disabled={disabled}
    >
      <span>{title}</span>
    </Button>
  );
};
