import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash.get';
import { colors, dimensions, mixins, respondFrom, breakpoints, css } from '@styles';

import Image from 'gatsby-image';
import Collapsible from 'react-collapsible';
import * as utils from '@utils';
import { ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

import removeFromCartIcon from '@assets/svg/remove-from-cart-icon.svg';
import deleteIcon from '@assets/svg/delete-icon.svg';

const ProductsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 15px 0 25px;

  li {
    position: relative;
    display: flex;
    padding: 10px 0 30px;
    border-bottom: 1px solid ${colors.gray};

    ${respondFrom(
      breakpoints.md,
      css`
        padding: 10px 0;
      `
    )}
  }
`;

const ProductImage = styled.div`
  max-width: 200px;

  > div {
    max-width: 107px;
    max-height: 70px;
  }
`;

const ProductInfo = styled.div<ProductInfoProps>`
  flex: 1;
  margin-left: 20px;
  position: relative;
  top: 25px;
  left: 0;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-left: 30px;
      position: static;
    `
  )}

${({ center }) => center && css`
  ${respondFrom(
      breakpoints.md,
      css`
        display: flex;
        align-items: center;
      `
    )}
  `}
`;

const ProductInfoPrice = styled.div`
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
    `
  )}

  p {
    font-size: ${dimensions.fontSize.large}px;
    margin: 0;
    text-align: left;

    ${respondFrom(
      breakpoints.md,
      css`
        width: 200px;

        &:nth-of-type(2) {
          position: relative;
          top: 25px;
          left: 0;
          width: 95px;
          text-align: right;
        }
      `
    )}
  }
`;

const RemoveProduct = styled.div`
  max-width: 200px;
  position: relative;
  top: 25px;
  left: 0;
  cursor: pointer;
  height: 25px;

  span {
    color: ${colors.green.dark};
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 500;
    text-transform: uppercase;
  }

  img {
    height: 18px;
    top: 2px;
    left: 0;
    margin-left: 5px;
  }

  .hide-on-desktop {
    display: inline-block;

    ${respondFrom(
      breakpoints.md,
      css`
        display: none;
      `
    )}
  }

  .hide-on-mobile {
    display: none;

    ${respondFrom(
      breakpoints.md,
      css`
        display: inline-block;
      `
    )}
  }
`;

const FundingModalAccordion = styled.div`
  margin: 10px 0;
  transform: translate(-120px, 0);

  ${respondFrom(
    breakpoints.md,
    css`
      transform: none;
    `
  )}

  .Collapsible {
    &__trigger {
      color: ${colors.green.dark};
      cursor: pointer;
      font-size: ${dimensions.fontSize.small}px;
      position: relative;
      padding-right: 20px;

      &::before {
        content: '';
        position: absolute;
        top: -5px;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: center;
        ${mixins.transitionDefault};
      }

      &.is-open {
        &::before {
          transform: translate(0, 5px) rotate(225deg);
        }
      }
    }

    &__contentOuter {
      ul {
        list-style: none;
        padding: 0;
        margin: 15px 0;

        li {
          border: none;
          padding: 0;
          margin: 5px 0;
          display: flex;

          span {
            font-size: ${dimensions.fontSize.small}px;
            flex: 1;

            ${respondFrom(
              breakpoints.md,
              css`
                width: 200px;
                flex: none;

                &:nth-of-type(2) {
                  width: 95px;
                }
              `
            )}

            &:nth-of-type(2) {
              text-align: right;
            }
          }
        }
      }
    }
  }
`;

interface ProductInfoProps {
  center?: boolean;
}

interface BasketModalProps {
  configuratorActions: typeof ConfiguratorActions;
  basket: ConfiguratorBasket;
  pageSettings: ConfiguratorSettings;
  paymentType?: string;
  hideDetails?: boolean;
}

export default class ShpooingBasketModal extends React.Component<BasketModalProps> {
  render() {
    const props = this.props;

    return (
      <ProductsList>
        {props.basket.products
          .filter(
            product =>
              !utils.productHasAvailablePaymentType(product, props.paymentType) ||
              !utils.prices.getProductPrice({ product, paymentType: props.paymentType })
          )
          .map((product, index) => {
          const image_ =
            utils.productHasAvailableColors(product) && product.currentColor
              ? get(product.currentColor.image, 'childImageSharp', null)
              : get(product.image, 'childImageSharp', null);

          const fundingPriceListGroup = utils.getFundingPriceListGroup(props.basket);
          const productPrice = utils.prices.getProductPrice({ 
              product, 
              paymentType: props.paymentType,
              priceListGroup: fundingPriceListGroup
            });
          const fiscalizationPrice = utils.prices.getFiscalizationPrice({
            fiscalization: props.basket.fiscalization,
            priceListGroup: fundingPriceListGroup
          });

          return (
            <li key={index}>
              <ProductImage>
                {image_ && <Image {...image_} imgStyle={{ objectFit: 'contain' }} />}
              </ProductImage>
              <ProductInfo center={props.hideDetails}>
                <ProductInfoPrice>
                  <p>{product.name}</p>
                  {
                    !props.hideDetails &&
                    <p>
                      {
                        productPrice &&
                        utils.formatCurrency(
                          (
                            productPrice.iposFee +
                            (fiscalizationPrice ? fiscalizationPrice.iposFee : 0) +
                            (product.currentColor ? product.currentColor.price : 0) +
                            (product.accessories.length
                              ? product.accessories
                                  .map(accessory => accessory.price)
                                  .reduce((a, b) => a + b)
                              : 0)
                          ).toFixed(2)
                        )
                      }
                    </p>
                  }
                </ProductInfoPrice>
                {
                  !props.hideDetails &&
                  <FundingModalAccordion>
                    <Collapsible trigger="Szczegóły">
                      <ul>
                        <li>
                          <span>
                            {
                              props.pageSettings.field_proste_pola 
                              ? !!fundingPriceListGroup 
                                ? props.pageSettings.field_proste_pola[162]
                                : props.pageSettings.field_proste_pola[25] 
                              : ''
                            }
                          </span>
                          <span>
                            {productPrice && utils.formatCurrency(productPrice.iposFee)}
                          </span>
                        </li>
                          <li>
                            <span>{props.pageSettings.field_proste_pola[26]}</span>
                            <span>{fiscalizationPrice && utils.formatCurrency(fiscalizationPrice.iposFee)}</span>
                          </li>
                          {product.currentColor && product.currentColor.price > 0 && (
                            <li>
                              <span>
                                {props.pageSettings.field_proste_pola[27]} ({product.currentColor.name})
                              </span>
                              <span>{product.currentColor.price} zł</span>
                            </li>
                          )}
                          {product.accessories.map((acc, index) => (
                            <li key={index}>
                              <span>{acc.name}</span>
                              <span>{utils.formatCurrency(acc.price)}</span>
                            </li>
                          ))}
                      </ul>
                    </Collapsible>
                  </FundingModalAccordion>
                }
              </ProductInfo>
              <RemoveProduct onClick={() => props.configuratorActions.basketRemoveProduct(index)}>
                <span className="hide-on-mobile">{props.pageSettings.field_proste_pola[36]}</span>
                <img className="hide-on-desktop" src={deleteIcon} alt="" />
                <img className="hide-on-mobile" src={removeFromCartIcon} alt="" />
              </RemoveProduct>
            </li>
          );
        })}
      </ProductsList>
    );
  }
}
