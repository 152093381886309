import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';

const StyledTitle = styled.div`
  text-align: center;
  padding: 15px 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 26px 0;
    `
  )}

  h1 {
    font-size: 20px;
    margin: 0;

    ${respondFrom(
      breakpoints.lg,
      css`
        font-size: 24px;
      `
    )}
  }
`;

interface Props {
  className?: string;
}

const Title: React.FC<Props> = ({ children, className }) => (
  <StyledTitle className={className}>
    <h1>{children}</h1>
  </StyledTitle>
);

export default Title;
