/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash.get';
import { Node__Pages, Node__PagesEdge, Node__PagesConnection } from '../../graphql/types';
import { SimplePageDataProps } from '@content/types/simplePage';
import languages from '@content/json/languages.json';
import { FileImageSharp } from '../types/api';
import { File__File } from '../../graphql/types';

export const transformPages = (pages?: Node__PagesConnection | null): SimplePageDataProps[] => {
  if (!pages) {
    return [];
  }

  const data = get(pages.allNodePages, 'edges', []).map(
    (edge: Node__PagesEdge) => edge.node
  ) as Node__Pages[];

  const urls = get(pages, 'allSiteSettingEntityUrls.edges', []).map(item => item.node);
  const simpleFormFields = get(pages, 'allSiteSettingEntitySimpleForm.edges', []).map(
    item => item.node
  );
  const allPages = data.map((page: Node__Pages) => {
    return {
      langcode: get(page, 'langcode', ''),
      slug: page.path ? (page.path.alias || '').replace('/', '') : page.id,
      title: get(page, 'title', ''),
      paragraph: get(page, 'field_pages_text.value', ''),
      seo: {
        title: get(page, 'field_meta_title', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      allUrls: urls.find(url => url.langcode === get(page, 'langcode', '')).field_urls_array,

      components: get(page, 'relationships.field_components', []).map(component => {
        const type = get(component, 'internal.type', null);
        const fileImage = get(component, 'relationships.field_branches_image') as FileImageSharp;
        const fileImageMobile = get(
          component,
          'relationships.field_branches_image_mobile'
        ) as FileImageSharp;

        if (type && type === 'paragraph__branches_hero') {
          const fileDeviceImage = get(
            component,
            'relationships.field_branches_device_image'
          ) as FileImageSharp;

          return {
            type: 'hero',
            title: get(component.field_paragraph_headline, 'value', ''),
            paragraph: get(component.field_branches_text, 'value', ''),
            video: get(component, 'field_hero_1_video', ''),
            image: fileImage ? fileImage.localFile : null,
            imageMobile: fileImageMobile ? fileImageMobile.localFile : null,
            deviceImage: fileDeviceImage ? fileDeviceImage.localFile : null,
            buttonText: component.field_branches_button_text,
            buttonRoute: page.path ? page.path.alias || page.id : '',
            videoThumbnail: get(component.relationships, 'field_video_thumbnail.localFile', ''),
            videoThumbnailAlt: get(component, 'field_video_thumbnail.alt', ''),
          };
        } else if (type && type === 'paragraph__branches_what_is_ipos') {
          const fileIcon1 = get(component.relationships, 'field_ico_1') as File__File;
          const fileIcon2 = get(component.relationships, 'field_ico_2') as File__File;
          const fileIcon3 = get(component.relationships, 'field_ico_3') as File__File;

          return {
            type: 'what_is_ipos',
            title: get(component.field_paragraph_headline, 'value', ''),
            paragraph: get(component.field_branches_text, 'value', ''),
            image: fileImage ? fileImage.localFile : null,
            icon1: fileIcon1 ? { publicURL: get(fileIcon1.localFile, 'publicURL', '') } : undefined,
            icon2: fileIcon2 ? { publicURL: get(fileIcon2.localFile, 'publicURL', '') } : undefined,
            icon3: fileIcon3 ? { publicURL: get(fileIcon3.localFile, 'publicURL', '') } : undefined,
            text1: get(component.field_short_text_1, 'value', ''),
            text2: get(component.field_short_text_2, 'value', ''),
            text3: get(component.field_short_text_3, 'value', ''),
          };
        } else if (type && type === 'paragraph__bullets_container') {
          const items = get(component.relationships, `field_bullets_item`, []);

          return {
            type: 'bullets',
            bullets: items.map(item => {
              const fileBulletImage = get(item.relationships, 'field_image') as FileImageSharp;
              return {
                paragraph: get(item.field_text, 'value', ''),
                image: fileBulletImage ? fileBulletImage.localFile : null,
                imageAlt: get(item, 'field_image.alt', ''),
                buttonLabel: get(item, 'field_nc_button_text', ''),
                buttonHref: get(item, 'field_btn_url', ''),
                video: get(item, 'field_video', ''),
                bonusImages: get(item, 'relationships.field_bullets_icons', []).map(icon =>
                  get(icon, 'localFile.publicURL', '')
                ),
              };
            }),
          };
        } else if (type && type === 'paragraph__branches_recommendation') {
          return {
            type: 'recommendation',
            headline: get(component.field_paragraph_headline, 'value', ''),
            paragraph: get(component.field_branches_text, 'value', ''),
            image: fileImage ? fileImage.localFile : null,
            isOnline: component.field_is_online || false,
            buttonText: component.field_nc_button_text,
            buttonRoute: page.path ? page.path.alias || page.id : '',
          };
        } else if (
          (type && type === 'paragraph__branches_features') ||
          (type && type === 'paragraph__branches_check_what_s_going_on')
        ) {
          return {
            type: type && type === 'paragraph__branches_features' ? 'features' : 'features2',
            headline: get(component.field_paragraph_headline, 'value', ''),
            paragraph: get(component.field_branches_text, 'value', ''),
            image: fileImage ? fileImage.localFile : null,
            features: [...Array(4).keys()].map((e, i) => {
              const icon = get(component.relationships, `field_ico_${i + 1}.localFile.publicURL`);
              const text = get(component, `field_short_text_${i + 1}.value`);

              if (icon) {
                return {
                  image: icon,
                  text: text || '',
                };
              }
            }),
          };
        } else if (type && type === 'paragraph__branches_recommended_devices') {
          const items = get(component.relationships, `field_branches_devices`, []);

          return {
            type: 'products',
            headline: get(component.field_paragraph_headline, 'value', ''),
            branchSlug: page.path ? page.path.alias || page.id : page.id,
            products: items.map(item => {
              const imageSharp = get(item.relationships, 'field_branches_image') as FileImageSharp;

              return {
                id: item.drupal_id || '',
                name: item.field_branches_name,
                image: imageSharp ? imageSharp.localFile : null,
                isRecommended: item.field_recommended || false,
                buttonText: '', // TODO
                profits: get(item.relationships, 'field_branches_profits', []).map(profit => {
                  return {
                    active: profit.field_profits_active || false,
                    name: (profit.field_profits_name && profit.field_profits_name.value) || '',
                  };
                }),
              };
            }),
          };
        } else if (type && type === 'paragraph__branches_recommended_tariffs') {
          return {
            type: 'tariffs',
            title: get(component.field_paragraph_headline, 'value', ''),
            subtitle: get(component.field_branches_text, 'value', ''),
            buttonLabel: get(component, 'field_nc_button_text', ''),

            tariffs: get(component.relationships, 'field_branches_tarrifs', []).map(tariff => {
              return {
                name: tariff.field_branches_name,
                recommended: tariff.field_recommended,
                price: get(tariff.relationships, 'field_date_from_api.iposfee', ''),
                profits: get(tariff.relationships, 'field_branches_profits', []).map(profit => {
                  return {
                    active: profit.field_profits_active,
                    name: profit.field_profits_name.value,
                  };
                }),
              };
            }),

            // TODO
            pricePeriod: 'zł miesięcznie',
          };
        } else if (type && type === 'paragraph__branches_testimonials') {
          const imageSharp = get(component.relationships, 'field_branches_image') as FileImageSharp;

          return {
            type: 'testimonial',
            image: imageSharp ? imageSharp.localFile : null,
            paragraph: get(component.field_nq_quotation, 'value', ''),
          };
        } else if (type && type === 'paragraph__discounts_container') {
          return {
            type: 'discounts',
            title: get(component.field_paragraph_headline, 'value', ''),
            paragraph: get(component.field_text, 'value', ''),
            discounts: get(component.relationships, 'field_discounts_item', []).map(discount => {
              const imageSharp = get(
                discount.relationships,
                'field_branches_image'
              ) as FileImageSharp;

              return {
                id: get(discount, 'id', ''),
                paragraph: get(discount.field_branches_text, 'value', ''),
                buttonLabel: get(discount, 'field_branches_button_text', ''),
                image: imageSharp ? imageSharp.localFile : null,
                imageAlt: get(discount.field_branches_image, 'alt', ''),
              };
            }),
          };
        } else if (type && type === 'paragraph__simple_form') {
          const fields = simpleFormFields.find(form => form.langcode === component.langcode);

          return {
            type: 'simple-form',
            title: get(component, 'field_title', ''),
            langcode: get(component, 'langcode', ''),
            formFields: [
              {
                name: 'name',
                label: get(fields, 'field_fr_form_name', ''),
                type: 'text',
                validation: {
                  required: true,
                  requiredMessage: get(fields, 'field_settings_text_1', ''),
                },
              },
              {
                name: 'phone',
                label: get(fields, 'field_settings_text_4', ''),
                type: 'phone',
                validation: {
                  required: true,
                  requiredMessage: get(fields, 'field_settings_text_5', ''),
                  phoneMessage: get(fields, 'field_settings_text_6', ''),
                },
              },
              {
                name: 'email',
                label: get(fields, 'field_fr_form_email', ''),
                type: 'email',
                validation: {
                  required: true,
                  requiredMessage: get(fields, 'field_settings_text_2', ''),
                  emailMessage: get(fields, 'field_settings_text_3', ''),
                },
              },
            ],
            agreement: {
              name: 'agreement',
              label: get(fields, 'field_agreement_1.value', ''),
              type: 'checkbox',
            },
            button: {
              label: get(fields, 'field_settings_button_text', ''),
              tooltip: get(fields, 'field_tooltip', ''),
            },
            id: get(fields, 'drupal_id', ''),
            modal: {
              success: get(fields, 'field_modal_1.value', ''),
              error: get(fields, 'field_modal_2.value', ''),
            },
          };
        }
      }),
    };
  });

  const allPagesLanguages = {};

  languages.codes.map(lang => {
    allPagesLanguages[lang] = allPages.filter(page => page.langcode === lang);
  });

  return allPagesLanguages;
};
