import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, breakpoints, css } from '@styles';
import { ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import ShoppingBasketModal from '@components/configurator/shoppingCart/ShoppingBasketModal';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';

const PaymentTypeModal = styled.div`
  h4,
  p {
    text-align: center;

    ${respondFrom(
  breakpoints.md,
  css`
        text-align: left;
      `
)}

    br {
      display: none;

      ${respondFrom(
  breakpoints.md,
  css`
          display: block;
        `
)}
    }
  }

  p {
    font-size: ${dimensions.fontSize.small}px;
  }

  a {
    color: ${colors.text.default};
    text-decoration: none;
  }
`;

interface PaymentTypeModalProps {
  configuratorActions: typeof ConfiguratorActions;
  basket: ConfiguratorBasket;
  pageSettings: ConfiguratorSettings;
  paymentType?: string;
}

export default (props: PaymentTypeModalProps) => (
  <PaymentTypeModal>
    <>
      {
        (props.pageSettings.field_pola_wysywig && props.pageSettings.field_pola_wysywig[13]) &&
        utils.SafeHtml(props.pageSettings.field_pola_wysywig[13].value)
      }
    </>
    <ShoppingBasketModal {...props} hideDetails />
  </PaymentTypeModal>
);