import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, animation } from '@styles';
import { Link } from '@utils';
import * as utils from '@utils';

const FormCheckbox = styled.div<StyleProps>`
  margin: ${props => (props.space === 'big' ? '30px 0' : '20px 0')};
  position: relative;

  input {
    display: none;
  }

  label {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid ${colors.gray};
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 7px;
      width: 7px;
      height: 12px;
      border: 1px solid ${colors.black};
      border-left: 0;
      border-top: 0;
      transform: rotate(45deg);
      opacity: 0;
      cursor: pointer;
    }

    span {
      padding-left: 30px;
      vertical-align: middle;
      font-size: ${props =>
        props.text ? `${dimensions.fontSize[props.text]}px` : `${dimensions.fontSize.small}px`};
      font-weight: 300;
      display: inline-block;
      cursor: pointer;

      a {
        margin-left: 3px;
        text-decoration: underline;
        transition: ${animation.fast}ms ease-out;

        &:hover {
          color: ${colors.green.light};
        }
      }
    }

    &.isChecked {
      &::before {
        border-color: ${colors.text.default};
      }

      &::after {
        opacity: 1;
      }
    }
  }
`;

interface CheckboxFormProps extends StyleProps {
  name: string;
  labelText: string;
  labelLink?: LabelLink;
  onChangeCheckbox: Function;
  value: boolean;
  target?: string;
}

interface LabelLink {
  text: string;
  path: string;
}

interface StyleProps {
  space?: string;
  text?: 'smaller';
  target?: string;
}

export default (props: CheckboxFormProps) => {
  const { name, labelText, space, labelLink, value, onChangeCheckbox, text, target } = props;

  return (
    <FormCheckbox space={space} text={text}>
      <label className={value ? 'isChecked' : ''}>
        <div>
          <input type="checkbox" checked={value} onChange={() => onChangeCheckbox(name)} />
          <span>
            {labelText && utils.SafeHtml(labelText)}
            {labelLink ? (
              <>
                {target === '_blank' ? (
                  <a href={labelLink.path} target="_blank" rel="noopener noreferrer">
                    {labelLink.text}
                  </a>
                ) : (
                  <Link to={labelLink.path}>{labelLink.text}</Link>
                )}
              </>
            ) : null}
          </span>
        </div>
      </label>
    </FormCheckbox>
  );
};
