import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';
import * as utils from '@utils';

import infoIcon from '@assets/svg/info-icon-red.svg';

const MessageWrapper = styled.div`
  text-align: center;
  font-size: ${dimensions.fontSize.tiny}px;
  margin: 0 30px 15px;
  background-color: ${colors.infoMessage};
  padding: 3px 0;
`;

const MessageInner = styled.div`
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin: 0 15px;

  > img {
    position: absolute;
    top: 0;
    bottom: 2px;
    left: 0;
    margin: auto;
  }

  p {
    margin: 0;
  }
`;

interface ConfiguratorInfoMessageProps {
  message: string;
  className?: string;
}

const ConfiguratorInfoMessage: React.FC<ConfiguratorInfoMessageProps> = ({
  message,
  className,
}) => {
  return (
    <MessageWrapper className={className || ''}>
      <MessageInner>
        <img src={infoIcon} alt="" />
        <div>{utils.SafeHtml(message)}</div>
      </MessageInner>
    </MessageWrapper>
  );
};

export default ConfiguratorInfoMessage;
