import React from 'react';
import styled from '@emotion/styled';
import Button from '@components/common/Button';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';

const ContactFormModalRewriteDataWrapper = styled.div`
  p {
    &::before {
      content: none;
    }
  }
`;

const ButtonsWrapper = styled.div`
  text-align: center;

  > div {
    display: inline-block;
    margin: 15px 50px;
  }
`;

interface ContactFormModalRewriteDataProps {
  onRewriteData: Function;
  onNotRewriteData: Function;
  pageSettings: ConfiguratorSettings;
}

const ContactFormModalRewriteData = ({
  onRewriteData,
  onNotRewriteData,
  pageSettings,
}: ContactFormModalRewriteDataProps) => (
  <ContactFormModalRewriteDataWrapper>
    {pageSettings.field_pola_wysywig && utils.SafeHtml(pageSettings.field_pola_wysywig[7].value)}
    <ButtonsWrapper>
      {pageSettings.field_proste_pola && (
        <div onClick={() => onNotRewriteData()}>
          <Button color="text">{pageSettings.field_proste_pola[83]}</Button>
        </div>
      )}
      {pageSettings.field_proste_pola && (
        <div onClick={() => onRewriteData()}>
          <Button>{pageSettings.field_proste_pola[84]}</Button>
        </div>
      )}
    </ButtonsWrapper>
  </ContactFormModalRewriteDataWrapper>
);

export default ContactFormModalRewriteData;
