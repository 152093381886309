import { graphql, useStaticQuery } from 'gatsby';

import { Product, Accessory, Funding, Tariff, LeasingInfo, Fiscalization } from '@store/content/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as confTransformers from '@content/transformers/ConfiguratorTransformers';
import * as settingsTransformers from '@content/transformers/SettingsTransformers';
import { Agreement } from '@content/types/agreement';

export interface ConfiguratorDataProps {
  products: Product[];
  accessories: Accessory[];
  fundings: Funding[];
  tariffs: Tariff[];
  leasingInfo: LeasingInfo;
  pageSettings: ConfiguratorSettings;
  calendarDaysToVisit: number;
  fiscalizations: Fiscalization[];
  agreements: Agreement[];
}

const ConfiguratorQuery = (): ConfiguratorDataProps => {
  const configuratorData = useStaticQuery(graphql`
    query {
      # api data
      allNodeDevices {
        edges {
          node {
            ...IposApiDevicesFragment
          }
        }
      }
      allNodeAccessories {
        edges {
          node {
            ...IposApiAccessoriesFragment
          }
        }
      }
      allNodeTariffs {
        edges {
          node {
            ...IposApiTariffsFragment
          }
        }
      }
      allTaxonomyTermPricelistGroup {
        edges {
          node {
            ...IposApiFundingFragment
          }
        }
      }
      allSiteSettingEntityConfigurator {
        edges {
          node {
            langcode
            field_calendar_available
            field_info_text {
              value
            }
            field_enable_info_text
            field_pola_wysywig {
              value
            }
            field_proste_pola
            field_meta_description
            field_meta_title
            relationships {
              field_images {
                localFile {
                  childImageSharp {
                    fixed(quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      allSiteSettingEntityAdministration {
        edges {
          node {
            ...IposApiSettingsFragment
          }
        }
      }

      allParagraphProfitReferenceToPriceList {
        edges {
          node {
            field_text {
              value
            }
            drupal_id
            drupal_internal__id
            langcode
            relationships {
              field_price_group_reference {
                drupal_id
              }
            }
          }
        }
      }

      allNodePriceList {
        edges {
          node {
            drupal_id
            langcode
            field_pl_name
            field_pl_optionname
            field_pl_lease
            field_pl_activation_price
            field_pl_payment_type
            field_pl_contract_duration
            field_pl_lease_name
            fixedprice
            iposfee
            field_pl_group_name_in_api,
            field_pl_reduced_fee_duration
            field_pl_reduced_fee
            field_pl_locationfee
            field_pl_optionname
          }
        }
      }

      allNodeAgreement {
        edges {
          node {
            langcode
            drupal_id
            agreement_content {
              value
            }
            agreement_explication {
              value
            }
            agreement_type
            agreement_pdf_title
            relationships {
              agreement_payment_types {
                machine_api_name
              }
              agreement_pdf {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
      allNodeFiscalization {
        edges {
          node {
            ...IposApiFiscalizationFragment
          }
        }
      }
    }
  `);

  return {
    products: confTransformers.transformProducts(
      configuratorData.allNodeDevices,
      configuratorData.allNodePriceList,
      configuratorData.allTaxonomyTermPricelistGroup
    ),
    accessories: confTransformers.transformAccessories(configuratorData.allNodeAccessories),
    fundings: confTransformers.transformFundings(configuratorData.allTaxonomyTermPricelistGroup),
    tariffs: confTransformers.transformTariffs(
      configuratorData.allNodeTariffs,
      configuratorData.allParagraphProfitReferenceToPriceList,
      configuratorData.allNodePriceList,
      configuratorData.allTaxonomyTermPricelistGroup
    ),
    
    leasingInfo: settingsTransformers.transformSettings(
      configuratorData.allSiteSettingEntityAdministration
    ) as LeasingInfo,
    pageSettings: confTransformers.transformSettings(
      configuratorData.allSiteSettingEntityConfigurator
    ) as ConfiguratorSettings,
    calendarDaysToVisit: confTransformers.transformCalendarSettings(
      configuratorData.allSiteSettingEntityAdministration
    ),
    fiscalizations: confTransformers.transformFiscalizations(
      configuratorData.allNodeFiscalization,
      configuratorData.allNodePriceList,
      configuratorData.allTaxonomyTermPricelistGroup
    ),
    agreements: confTransformers.transformAgreements(configuratorData.allNodeAgreement),
  };
};

export default ConfiguratorQuery;
