import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@styles';

import Loader from '@components/common/Loader';

const ShoppingSummaryLoaderWrapper = styled.div`
  background-color: ${colors.text.default};
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShoppingSummaryLoader = () => {
  return (
    <ShoppingSummaryLoaderWrapper>
      <Loader />
    </ShoppingSummaryLoaderWrapper>
  );
};

export default ShoppingSummaryLoader;
