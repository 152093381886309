import * as React from 'react';
import styled from '@emotion/styled';
import get from 'lodash.get';

import * as utils from '@utils';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import { ProductColor, BasketProduct, Product } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { ConfiguratorBasket } from '@store/configurator/types';
import { Funding } from '@store/content/types';

interface ColorBoxStylesProps {
  active: boolean;
  color: string;
  activeText?: string;
}

const getEm = (px: number): string => {
  return `${px / 16}em`;
};

const StyledProductColorPicker = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: ${getEm(20)} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    min-height: ${getEm(35)}; /* for empty colors list  */
    ${respondFrom(
      breakpoints.md,
      css`
        height: ${getEm(44)};
      `
    )}
  }

  > div {
    text-align: center;
    display: flex;
    justify-content: space-between;
    max-width: 305px;
    margin: auto;

    ${respondFrom(
      breakpoints.md,
      css`
        max-width: 360px;
      `
    )}

    span {
      display: block;

      ${respondFrom(
        breakpoints.md,
        css`
          display: inline-block;
        `
      )}
    }
  }
`;

const StyledColorBox = styled.li<ColorBoxStylesProps>`
  position: relative;
  height: ${getEm(36)};
  width: ${getEm(36)};
  background-color: transparent;
  margin: 0 ${getEm(7)};
  cursor: pointer;

  ${mixins.transitionDefault}

  ${respondFrom(
    breakpoints.md,
    css`
      height: ${getEm(44)};
      width: ${getEm(44)};
      margin: 0 ${getEm(10)};
    `
  )}

  &:hover {
    transform: ${props => (props.active ? 'none' : 'scale(1.1)')};
  }

  &::after {
    content: '';
    width: ${getEm(28)};
    height: ${getEm(28)};
    background-color: ${props => props.color};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    ${respondFrom(
      breakpoints.md,
      css`
        width: ${getEm(32)};
        height: ${getEm(32)};
      `
    )}
  }

  /* color box border */
  &::before {
    content: '';
    ${mixins.absoluteFill}
    border: ${getEm(3)} solid
      ${props => (props.active ? colors.blue.default : 'transparent')};
    transform:  ${props => (props.active ? 'scale(1)' : 'scale(0)')};
    opacity:  ${props => (props.active ? '1' : '0')};

    ${mixins.transitionDefault}
  }

  /* text under selected color (compact mode) */
  span {
    display: inline-block;
    white-space: nowrap;
    font-size: 150%;
    position: absolute;
    bottom: ${getEm(-32)};
    left: 50%;
    transform: translate( -50%, -50% ) scale(0);
    transform-origin: center;

    opacity: 0;

    ${mixins.transitionDefault}
  }

  ${props =>
    props.active
      ? css`
          span {
            opacity: 1;
            transform: translate(-50%, 0);
          }
        `
      : null}
  
`;

export interface ProductColorPickerProps {
  product: Product | BasketProduct;
  configuratorActions: typeof ConfiguratorActions;
  getOnlyColor: Function;

  displayCompact?: boolean;
  className?: string;
  productInBasket: boolean;
  pageSettings: ConfiguratorSettings;
  basket?: ConfiguratorBasket;
  allFundings?: Funding[];
}

class ProductColorPicker extends React.Component<ProductColorPickerProps> {
  changeColor = (color: ProductColor) => {
    if (this.props.displayCompact) {
      this.props.getOnlyColor(color, this.props.product);

      if (this.props.productInBasket) {
        this.props.configuratorActions.basketSetCurrentColor(color);
      }
    } else {
      // make sure product is in basket
      if (typeof get(this.props.product, 'accessories') === typeof undefined) {
        this.props.configuratorActions.basketAddProduct(this.props.product);
      }

      this.props.configuratorActions.basketSetCurrentColor(color);
    }
  };

  render() {
    const { product, className, displayCompact } = this.props;
    const currentColor = get(product, 'currentColor', null) as ProductColor;

    return (
      <StyledProductColorPicker className={className || ''}>
        <ul>
          {utils.productHasAvailableColors(product) &&
            product.availableColors.map(color => (
              <StyledColorBox
                key={color.id}
                color={color.hex}
                active={currentColor && color.id === currentColor.id ? true : false}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  this.changeColor(color);
                }}
              >
                {displayCompact && color.price > 0 && (
                  <span>+ {utils.formatCurrency(color.price)}</span>
                )}
              </StyledColorBox>
            ))}
        </ul>
        {!displayCompact && (
          <div>
            <span>
              <strong>{get(product, 'currentColor.name', '')}</strong>
            </span>
            <span>
              {!!currentColor && currentColor.price !== 0 ? '+ ' : null}
              {!!currentColor && currentColor.price.toFixed(2)} zł
              {!!currentColor && currentColor.price === 0
                ? ' ' +
                  (this.props.pageSettings.field_proste_pola
                    ? this.props.pageSettings.field_proste_pola[34]
                    : '')
                : null}
            </span>
          </div>
        )}
      </StyledProductColorPicker>
    );
  }
}

export default ProductColorPicker;
