import Badge from '@components/common/Badge';
import ButtonCart from '@components/common/ButtonCart';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import styled from '@emotion/styled';
import { breakpoints, respondFrom, css, mixins, colors, dimensions } from '@styles';
import Image from 'gatsby-image';
import React, { useMemo } from 'react';
import * as utils from '@utils';
import { CennikActions, ConfiguratorActions } from '@store/actions';
import { Fiscalization } from '@store/types';
import { FiscalizationPrice } from '@content/types/fiscalization';

const StyledWrapper = styled.div`
  height: 100%;
  padding: 40px 20px 30px;
  position: relative;
  overflow: hidden;
  ${mixins.transitionDefault};
  ${mixins.borderOnHover};
  ${mixins.borderWhenActive};
  cursor: auto !important;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px ${colors.gray};
    z-index: -1;
  }
`;

const StyledContent = styled.div<StyledContentProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const StyledImage = styled(Image)`
  height: 120px !important;
  width: 110px !important;
  margin: 0 auto 40px;
`;

const StyledHeader = styled.h2`
  font-size: 25px;
  font-weight: 100;

  ${respondFrom(
    breakpoints.md,
    css`
      font-size: 35px;
      min-height: var(--fiscalization-header-height);
    `
  )};
`;

const StyledPrice = styled.div`
  margin-bottom: 30px;
  h3 {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
  }
  span {
    display: block;
    font-size: 14px;
  }
`;

const StyledDescription = styled.div`
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  p {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0;
    }
  }
  margin-bottom: 30px;
`;

const StyledButtonCart = styled(ButtonCart)`
  margin-top: auto;
`;

const StyledErrorMessage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 20px;
  margin: auto;
  height: max-content;
  width: auto;
  align-items: center;
  justify-content: center;

  p {
    padding: 25px;
    margin: 0;
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    border: 1px solid ${colors.gray};
    background-color: ${colors.grayNurse};
  }
`;

interface FiscalizationsListItemProps extends StyledContentProps {
  configuratorActions: typeof ConfiguratorActions | typeof CennikActions;
  pageSettings: ConfiguratorSettings;
  isSelected: boolean;
  fiscalization: Fiscalization;
  fiscalizationPrice?: FiscalizationPrice;
  hideDesc?: boolean;
}

interface StyledContentProps {
  disabled: boolean;
}

const FiscalizationsListItem = (props: FiscalizationsListItemProps) => {
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props]);

  const handleClick = () => {
    if (!props.isSelected && props.fiscalizationPrice) {
      props.configuratorActions.basketSetFiscalization(props.fiscalization);
    }
  };

  return (
    <StyledWrapper>
      <StyledContent disabled={props.disabled}>
        {props.fiscalization.isPopular && <Badge text={pPole[94]} />}
        {props.fiscalization.image && (
          <StyledImage
            {...props.fiscalization.image.childImageSharp}
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
        {props.fiscalization.title && (
          <StyledHeader role="heading">{props.fiscalization.title}</StyledHeader>
        )}
        {props.fiscalizationPrice && (
          <StyledPrice>
            <h3>{utils.formatCurrency(props.fiscalizationPrice.iposFee)}</h3>
            <span>{pPole[183]}</span>
          </StyledPrice>
        )}
        {!props.hideDesc && props.fiscalization.description && (
          <StyledDescription>{utils.SafeHtml(props.fiscalization.description)}</StyledDescription>
        )}
        {props.fiscalizationPrice && (
          <StyledButtonCart
            color="primary"
            size="full"
            title={props.isSelected ? pPole[129] : pPole[32]}
            action={handleClick}
            inactive={props.isSelected}
            hideIcon
          />
        )}
      </StyledContent>
      {props.disabled ? (
          props.fiscalization.disabledFiscalizationMesssage ? (
              <StyledErrorMessage>
                <p>{props.fiscalization.disabledFiscalizationMesssage}</p>
              </StyledErrorMessage>
          ) : (
              <StyledErrorMessage>
                <p>{pPole[168]}</p>
              </StyledErrorMessage>
          )
      ) : null}
    </StyledWrapper>
  );
};

export default FiscalizationsListItem;
