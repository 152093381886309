import { graphql, useStaticQuery } from 'gatsby';
import { SimplePageDataProps } from '@content/types/simplePage';
import * as simplePagesTransformers from '@content/transformers/SimplePagesTransformers';

export interface SimplePagesQueryProps {
  allPages: SimplePageDataProps[];
}

const SimplePagesQuery = (): SimplePagesQueryProps => {
  const pagesData = useStaticQuery(graphql`
    query {
      # api data
      allNodePages {
        edges {
          node {
            langcode
            id
            title
            field_pages_text {
              value
            }
            path {
              alias
            }
            field_meta_title
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_components {
                ...IposParagraphBranchesHeroFragment
                ...IposParagraphBranchesWhatIsIpos
                ...IposParagraphBulletsContainer
                ...IposParagraphBranchesRecommendation
                ...IposParagraphBranchesFeatures
                ...IposParagraphBranchesRecommendedDevices
                ...IposParagraphBranchesRecommendedTariffs
                ...IposParagraphBranchesCheckWhatSGoingOn
                ...IposParagraphBranchesTestimonials
                ...IposParagraphDiscountsContainer
                ...IposParagraphSimpleForm
              }
            }
          }
        }
      }
      allSiteSettingEntityUrls {
        edges {
          node {
            langcode
            field_urls_array
          }
        }
      }
      allSiteSettingEntitySimpleForm {
        edges {
          node {
            field_fr_form_email
            langcode
            drupal_id
            id
            field_agreement_1 {
              value
            }
            field_fr_form_name
            field_settings_button_text
            field_settings_text_1
            field_settings_text_2
            field_settings_text_3
            field_settings_text_4
            field_settings_text_5
            field_settings_text_6
            field_tooltip
            fieldset
            name
            field_modal_1 {
              value
            }
            field_modal_2 {
              value
            }
          }
        }
      }
    }
  `);

  return {
    allPages: simplePagesTransformers.transformPages(pagesData),
  };
};

export default SimplePagesQuery;
