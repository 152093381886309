/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash.get';
import {
  Site_Setting_Entity__Administration,
  Site_Setting_Entity__AdministrationEdge,
  Site_Setting_Entity__AdministrationConnection,
} from '../../graphql/types';
import { Settings } from '@content/types/settings';

export const transformSettings = (
  settings?: Site_Setting_Entity__AdministrationConnection | null
): Settings | {} => {
  if (!settings) {
    return {};
  }

  const data_ = get(settings, 'edges', []).map(
    (edge: Site_Setting_Entity__AdministrationEdge) => edge.node
  ) as Site_Setting_Entity__Administration[];

  const data = data_.length ? data_[0] : null;

  if (!data) {
    return {};
  }

  return Object.keys(data).reduce((result, k_) => {
    const k = k_.replace('field_', '');
    const value = data[k_];
    // value contains letters - leave it as string, otherwise try to parse
    result[k] =
      typeof data[k] === typeof '' && value.match(/[a-z]/i) ? value : parseFloat(value) || value;
    return result;
  }, {});
};
