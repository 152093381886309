import React, { Component } from 'react';
import styled from '@emotion/styled';

import { Product, Funding } from '@store/content/types';
import { respondFrom, breakpoints, css } from '@styles';
import ProductsListItem from '@components/configurator/products/ProductsListItem';
import { ConfiguratorDependent } from '@store/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

import * as utils from '@utils';
import { LocationDependent } from '@content/types/general';
import Modal from '@components/common/Modal';
import ProductBlockedModal from '@components/configurator/products/ProductBlockedModal';
import Slider from '@components/common/Slider';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  &.recommended {
    > div {
      ${respondFrom(
        breakpoints.md,
        css`
          margin-top: 30px;
        `
      )}
    }
  }
`;

interface ProductsListProps extends ConfiguratorDependent, LocationDependent {
  products: Product[];
  basketMode?: 'single' | 'nolimit';
  iconPlus?: boolean;
  badge?: string;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  allFundings?: Funding[];
  sliderSettings?: object;
}

class ProductsList extends Component<ProductsListProps> {
  state = {
    recommended: false,
    showModalBlocked: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ recommended: true });
    }, 200);
  }

  openModalBlocked = () => this.setState({ showModalBlocked: true });
  closeModalBlocked = () => this.setState({ showModalBlocked: false });

  render() {
    const {
      location,
      products,
      configurator,
      configuratorActions,
      basketMode,
      iconPlus,
      badge,
      pageSettings,
      langcode,
      allFundings,
    } = this.props;
    const productsLength = products ? products[langcode].length : 0;

    return (
      <List
        className={`
          ${
            products[langcode].find(
              product =>
                this.state.recommended &&
                product.recommended &&
                utils.setRecommendedBadge(location, product.recommended)
            )
              ? 'recommended'
              : ''
          }
      `}
      >
        <Slider
          settings={{
            slidesToShow: productsLength < 3 ? productsLength : 3,
            ...(this.props.sliderSettings && this.props.sliderSettings),
          }}
        >
          {products &&
            products[langcode].map((product, index) => {
              const productSlug = [product.name.toLowerCase().split(' '), product.langcode].join(
                '-'
              );

              const paymentType = configurator.paymentType;
              const isAvailablePaymentType = utils.productHasAvailablePaymentType(
                product,
                paymentType
              );

              const productPrice = utils.prices.getProductPrice({
                product,
                paymentType,
              });

              const blocked = !!(
                configurator.basket.fiscalization &&
                configurator.basket.fiscalization.availableForDevices.length &&
                !configurator.basket.fiscalization.availableForDevices.includes(product.id)
              );

              return (
                <ProductsListItem
                  configurator={configurator}
                  configuratorActions={configuratorActions}
                  product={product}
                  key={index}
                  basketMode={basketMode}
                  iconPlus={iconPlus}
                  location={location}
                  badge={badge}
                  pageSettings={pageSettings}
                  allFundings={allFundings}
                  productSlug={productSlug}
                  productPrice={productPrice}
                  isAvailablePaymentType={isAvailablePaymentType}
                  blocked={blocked}
                  onBlocked={this.openModalBlocked}
                />
              );
            })}
        </Slider>
        <Modal
          isOpened={this.state.showModalBlocked}
          content={
            <ProductBlockedModal
              onClickCancel={this.closeModalBlocked}
              pageSettings={this.props.pageSettings}
            />
          }
          hideButtonClose
          showMobileBackdrop
          disableClickOutside
        />
      </List>
    );
  }
}

export default ProductsList;
