import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';
import ButtonLink from '@components/common/ButtonLink';

const StyledModalContainer = styled.div``;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

interface ModalErrorProps {
  pageSettings: ConfiguratorSettings;
}

export default (props: ModalErrorProps) => {
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props.pageSettings]);
  const wPole = useMemo(() => props.pageSettings.field_pola_wysywig || [], [props.pageSettings]);
  return (
    <StyledModalContainer>
      {wPole[22] && utils.SafeHtml(wPole[22].value)}
      <StyledButtonWrapper>
        <ButtonLink extend target="_self" to="javascript:callpage('api.widget.open');">
          {pPole[176]}
        </ButtonLink>
      </StyledButtonWrapper>
    </StyledModalContainer>
  );
};
