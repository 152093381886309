import React from 'react';
import styled from '@emotion/styled';
import { colors, mixins, breakpoints, respondFrom, css } from '@styles';

import addToCart from '@assets/svg/add-to-cart-icon.svg';
import addDiscountCart from '@assets/svg/discount-cart-icon.svg';

import plusIcon from '@assets/svg/plus-icon.svg';
import minusIcon from '@assets/svg/close-icon.svg';

const Button = styled.button`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.green.dark};
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  ${mixins.transitionDefault}
  transition-property: opacity, color;

  // color versions
  &.color-primary {
    ${mixins.gradientGreen}
  }

  // size versions
  &.size-full {
    padding: 10px;
    height: 50px;
    width: 100%;
    justify-content: center;
    max-width: 120px;

    ${respondFrom(
    breakpoints.lg,
    css`
        max-width: 175px;
        `
    )}
  }

  svg,
  img {
    margin-left: 5px;
    margin-top: -5px;

    &.icon-plus {
      width: 13px;
      padding-top: 2px;
    }
  }

  &:focus {
    outline: none;
  }

  &.inactive {
    color: ${colors.text.default};
    opacity: 0.25;
    pointer-events: none;
  }

  &.align-left {
    text-align: left;
    margin-left: 30px;
    margin-right: auto;
  }
`;

interface ButtonCartProps {
  color?: 'primary' | 'text';
  size?: 'normal' | 'full';
  hideIcon?: boolean;
  title: string;
  action?: (e: React.MouseEvent) => void;
  choiceImg?: string;
  inactive?: boolean;

  className?: string;
  noBold?: boolean;
  iconPlus?: boolean;
  added?: boolean;
}

export default ({
  color,
  size,
  hideIcon,
  title,
  action,
  choiceImg,
  inactive,
  className,
  iconPlus,
  added,
}: ButtonCartProps) => (
  <Button
    onClick={!inactive ? action : () => {}}
    className={`
    ${'color-' + (color || 'text')}
    ${'size-' + (size || 'normal')}
    ${inactive && 'inactive'}
    ${className || ''}
  `}
  >
    <span>{title}</span>
    {
      !hideIcon && (
        <>
          {iconPlus ? (
            <img className="icon-plus" src={!added ? plusIcon : minusIcon} />
          ) : (
            <img src={choiceImg === 'addToCart' ? addToCart : addDiscountCart} />
          )}
        </>
      )
    }
  </Button>
);
