import styled from '@emotion/styled';
import React, { useMemo, useEffect, useRef  } from 'react';
import FiscalizationsListItem from '@components/configurator/fiscalizations/FiscalizationsListItem';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { CennikActions, ConfiguratorActions } from '@store/actions';
import { ConfiguratorBasket, Fiscalization } from '@store/types';
import * as utils from '@utils';
import Slider from '@components/common/Slider';

const StyledWrapper = styled.div`
  --fiscalization-header-height: 'auto';
`;

interface FiscalizationsListProps {
  configuratorActions: typeof ConfiguratorActions | typeof CennikActions;
  basket: ConfiguratorBasket;
  pageSettings: ConfiguratorSettings;
  fiscalizations: Fiscalization[];
  hideDesc?: boolean;
  sliderSettings?: object;
}

const FiscalizationsList = (props: FiscalizationsListProps) => {
  const fiscalizationsLength = useMemo(() => {
    return props.fiscalizations.length;
  }, [props.fiscalizations]);
  
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (ref.current) {
      ref.current.style.setProperty('--fiscalization-header-height', 'auto');
      const heights = Array.from(ref.current.children).reduce((heights: Array<number>, child) => {
        const heading: HTMLDivElement | null = child.querySelector('[role="heading"]');
        return !!heading ? [...heights, heading.offsetHeight] : heights;
      }, []);
      ref.current.style.setProperty('--fiscalization-header-height', `${Math.max(...heights)}px`);
    }
  };
  return (
    <StyledWrapper ref={ref}>
      <Slider
        settings={{
          slidesToShow: fiscalizationsLength < 3 ? fiscalizationsLength : 3,
          ...(props.sliderSettings && props.sliderSettings),
        }}
      >
        {props.fiscalizations &&
          props.fiscalizations.map(fiscalization => {
            const fiscalizationPrice = utils.prices.getFiscalizationPrice({
              fiscalization,
            });
            const isSelected = !!(
              props.basket.fiscalization && props.basket.fiscalization.id === fiscalization.id
            );
            const disabled = !!(
              fiscalization.availableForDevices.length &&
              props.basket.products.some(
                product => !fiscalization.availableForDevices.includes(product.id)
              )
            );
            return (
              <FiscalizationsListItem
                key={fiscalization.id}
                configuratorActions={props.configuratorActions}
                pageSettings={props.pageSettings}
                fiscalization={fiscalization}
                fiscalizationPrice={fiscalizationPrice}
                isSelected={isSelected}
                disabled={disabled}
                hideDesc={props.hideDesc}
              />
            );
          })}
      </Slider>
    </StyledWrapper>
  );
};

export default FiscalizationsList;
