import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { respondFrom, breakpoints, css } from '@styles';
import Button from '@components/common/Button';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

const StyledProductBlockedModal = styled.div``;

const StyledProductBlockedModalText = styled.div`
  padding: 0 20px;
  margin-bottom: 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    max-width: 350px;
    margin-bottom: 10px;
    padding: 1em !important;
    white-space: normal !important;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
      justify-content: space-around;
      margin: 0 -20px;

      > * {
        margin: 0 20px 10px 20px;
        white-space: nowrap !important;
      }
    `
  )}
`;

interface Props {
  onClickCancel: Function;
  pageSettings: ConfiguratorSettings;
}

export default (props: Props) => {
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props.pageSettings]);
  return (
    <StyledProductBlockedModal>
      <StyledProductBlockedModalText>{pPole[182]}</StyledProductBlockedModalText>
      <ButtonsWrapper>
        <Button color="outlined" size="small" onClick={() => props.onClickCancel()}>
          {pPole[125]}
        </Button>
      </ButtonsWrapper>
    </StyledProductBlockedModal>
  );
};
