import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: React.ReactElement;
}

const portalRoot: HTMLElement | null =
  typeof document !== `undefined` ? document.getElementById('portal') : null;

const Portal = ({ children }: PortalProps) => {
  const el = useRef<HTMLDivElement | null>(
    typeof document !== `undefined` ? document.createElement('div') : null
  );

  useEffect(() => {
    const current = el.current;
    if (current) {
      portalRoot!.appendChild(current);
      return () => void portalRoot!.removeChild(current);
    }
  }, []);

  if (el.current) {
    return createPortal(children, el.current);
  } else {
    return null;
  }
};

export default Portal;
