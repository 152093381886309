import React from 'react';
import styled from '@emotion/styled';
import { colors, mixins } from '@styles';

const Loader = styled.div<Props>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  background-color: ${colors.ui.light};
  transform-origin: bottom center;

  ${mixins.transitionDefault}
  transition-property: width, opacity, transform;

  opacity: ${props => (props.active ? 1 : 0)};
  transform: ${props => (props.active ? 'scaleY(1)' : 'scaleY(0)')};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${props => props.percent}%;
    background-color: ${colors.green.dark};

    ${mixins.transitionDefault}
    transition-property: width, opacity, background-color;
  }
`;

interface Props {
  active?: boolean;
  percent?: number;
}

const ConfiguratorStepLoader: React.FC<Props> = ({ active, percent }) => (
  <Loader active={active} percent={percent}></Loader>
);

export default ConfiguratorStepLoader;
