import React, { Component, createRef } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import ShoppingSummaryButton from '@components/configurator/shoppingCart/ShoppingSummaryButton';
// import ShoppingSummaryButtonReturn from '@components/configurator/shoppingCart/ShoppingSummaryButtonReturn';
import { ConfiguratorBasket, ConfiguratorState } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import { FormData, LeasingInfo } from '@store/content/types';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import ShoppingSummaryMobilePrices from './ShoppingSummaryMobilePrices';
import { PricesSummaryType } from '@content/types/prices';
import { Agreement } from '@content/types/agreement';
import { keyframes } from '@emotion/core';

interface ShoppingSummaryProps extends LocationDependent {
  active: boolean;
  basket: ConfiguratorBasket;
  activeStep: string;
  configuratorActions: typeof ConfiguratorActions;
  currentProductIndex: number;
  onSummaryOpenOnMobile: Function;
  formData: FormData;
  onPayButtonClick: Function;
  paymentType: string;
  leasingInfo: LeasingInfo;
  summaryPanelHiden: boolean;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  isOrderLoading: boolean;
  prices: PricesSummaryType;
  agreements: Agreement[];
  configurator: ConfiguratorState;
}

interface WrapperProps {
  isHidden: boolean;
}

const animateShake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const Wrapper = styled.div<WrapperProps>`
  display: ${props => (props.isHidden ? 'none' : 'block')};
  background-color: ${colors.text.default};
  /* height: 156px; */
  padding: 10px 30px 20px;
  ${mixins.fontSmoothing}

  z-index: 2;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  ${mixins.transitionDefault};
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )}

  &.shows-up {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

const OpenSummaryButton = styled.button`
  position: absolute;
  top: 10px;
  right: 30px;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 2px solid ${colors.green.dark};
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);
  }

  &.animate {
    animation: ${animateShake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;

class ShoppingSummary extends Component<ShoppingSummaryProps> {
  state = {
    panelIsVisible: true,
    lastScrollPosition: 0,
    priceNet: 0,
  };
  buttonAnimateTimer;
  buttonRef = createRef<HTMLButtonElement>();

  componentDidMount() {
    if (this.props.activeStep !== 'colors') {
      this.buttonAnimateTimer = setTimeout(() => {
        const { monthly, oneOff } = this.props.prices;
        const priceNet = monthly.total.net + oneOff.total.net;
        this.setState({ panelIsVisible: false, priceNet: priceNet });
      }, 700);
      document.addEventListener('scroll', this.onScrollHandler);
    }
  }

  componentDidUpdate(prevProps) {
    const { monthly, oneOff } = this.props.prices;
    const priceNet = (monthly.total.net ? monthly.total.net : 0) + (oneOff.total.net ? oneOff.total.net : 0);
    if (this.state.priceNet !== priceNet) {
      this.setState({ panelIsVisible: true, priceNet: priceNet });

      if(prevProps.configurator.basket.products.length < this.props.configurator.basket.products.length) {
        if(this.buttonRef.current && !this.buttonRef.current.classList.contains('animate')) {
          this.buttonRef.current.classList.add('animate');
          setTimeout(() => {
            if(this.buttonRef.current) {
              this.buttonRef.current.classList.remove('animate');
            }
          }, 1000);
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScrollHandler);
    clearTimeout(this.buttonAnimateTimer);
  }

  onScrollHandler = () => {
    let panelIsVisible;

    if (
      window.scrollY + window.innerHeight > document.body.offsetHeight - 100 ||
      this.state.lastScrollPosition > window.scrollY
    ) {
      panelIsVisible = true;
    } else {
      panelIsVisible = false;
    }

    this.setState({ panelIsVisible, lastScrollPosition: window.scrollY });
  };

  render() {
    const {
      active,
      basket,
      currentProductIndex,
      activeStep,
      formData,
      onPayButtonClick,
      summaryPanelHiden,
      location,
      pageSettings,
      langcode,
      urls,
      isOrderLoading,
      prices,
      paymentType,
      agreements
    } = this.props;

    return (
      <Wrapper
        className={`
        ${activeStep === 'krok-1' ? '' : 'bigger-area'}
        ${this.state.panelIsVisible ? 'shows-up' : ''}
      `}
        isHidden={summaryPanelHiden}
      >
        <OpenSummaryButton
          ref={this.buttonRef}
          onClick={() => this.props.onSummaryOpenOnMobile()} 
        />
        <ShoppingSummaryMobilePrices
          pageSettings={pageSettings}
          prices={prices}
        />

        <ShoppingSummaryButton
          active={active}
          basket={basket}
          activeStep={activeStep}
          currentProductIndex={currentProductIndex}
          configuratorActions={this.props.configuratorActions}
          formData={formData}
          onPayButtonClick={onPayButtonClick}
          width="full"
          location={location}
          smallOnMobile={true}
          pageSettings={pageSettings}
          langcode={langcode}
          urls={urls}
          isOrderLoading={isOrderLoading}
          agreements={agreements}
          paymentType={paymentType}
        />
        {/* <ShoppingSummaryButtonReturn
          basket={basket}
          activeStep={activeStep}
          currentProductIndex={currentProductIndex}
          configuratorActions={this.props.configuratorActions}
          location={location}
          pageSettings={pageSettings}
          langcode={langcode}
          urls={urls}
        /> */}
      </Wrapper>
    );
  }
}

export default ShoppingSummary;
