import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import ContactForm from '@components/configurator/contactForm/ContactFormPanel';
import { ConfiguratorActions } from '@store/actions';
import { FormData, FormValidation, FormDeliveryData } from '@store/content/types';
import { ConfiguratorBasket } from '@store/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import Modal from '@components/common/Modal';
import ContactFormModalNimInDatabase from '@components/configurator/contactForm/ContactFormModalNipInDatabase';
import MetaPage from '@components/common/MetaPage';
import usePagesQuery from '@graphql/queries/SimplePagesQuery';
import * as utils from '@utils';
import { Agreement } from '@content/types/agreement';
import ContactFormModalError from '@components/configurator/contactForm/ContactFormModalError';

const FormStepGrid = styled.div`
  padding-bottom: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-top: 20px;
    `
  )}
`;

interface ConfiguratorStep4 {
  configuratorActions: typeof ConfiguratorActions;
  formData: FormData;
  formValidation: FormValidation;
  nipInDatabase: boolean;
  onCloseNipInDatabaseModal: Function;
  basket: ConfiguratorBasket;
  allFieldsValidation: Function;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  deliveryData: FormDeliveryData;
  lockerValid: boolean;
  customError: CustomErrorProps;
  error: ErrorProps;
  paymentType?: string;
  agreements: Agreement[];
}

interface CustomErrorProps {
  isError: boolean;
  errorMessage: string;
  onCloseModal: Function;
}

interface ErrorProps {
  isError: boolean;
  onCloseModal: Function;
}

export default (props: ConfiguratorStep4) => {
  const allSimplePages = usePagesQuery().allPages;
  const {
    configuratorActions,
    formData,
    formValidation,
    allFieldsValidation,
    langcode,
    urls,
    deliveryData,
    lockerValid,
    customError,
    error,
    paymentType,
    agreements
  } = props;

  if (typeof window !== typeof undefined && props.basket.tariff === null) {
    navigate(utils.langLink(langcode, urls[6]));
    return null;
  } else {
    return (
      <FormStepGrid>
        <MetaPage title="Krok 4 - Sklep" />
        <ContactForm
          configuratorActions={configuratorActions}
          formData={formData}
          formValidation={formValidation}
          allFieldsValidation={allFieldsValidation}
          pageSettings={props.pageSettings[langcode]}
          langcode={langcode}
          urls={urls}
          allSimplePages={allSimplePages}
          deliveryData={deliveryData}
          lockerValid={lockerValid}
          paymentType={paymentType}
          agreements={agreements}
        />
        <Modal
          isOpened={props.nipInDatabase}
          content={<ContactFormModalNimInDatabase pageSettings={props.pageSettings[langcode]} />}
          onCloseModalPanel={props.onCloseNipInDatabaseModal}
        />
        <Modal
          isOpened={customError.isError}
          content={<p>{customError.errorMessage}</p>}
          onCloseModalPanel={customError.onCloseModal}
        />
        <Modal
          isOpened={error.isError}
          content={<ContactFormModalError pageSettings={props.pageSettings[langcode]} />}
          onCloseModalPanel={error.onCloseModal}
        />
      </FormStepGrid>
    );
  }
};
