import React from 'react';
import styled from '@emotion/styled';
import { dimensions, respondFrom, breakpoints, css } from '@styles';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';

const ModalContainer = styled.div`
  p {
    text-align: center;

    ${respondFrom(
      breakpoints.md,
      css`
        text-align: left;
      `
    )}

    &:first-of-type {
      font-size: ${dimensions.fontSize.large}px;
      margin-bottom: 30px;
    }
    &:last-of-type {
      font-size: ${dimensions.fontSize.small}px;
      margin: 0;
    }
  }
`;

interface NipInDatabaseProps {
  pageSettings: ConfiguratorSettings;
}

export default (props: NipInDatabaseProps) => (
  <>
    {props.pageSettings.field_pola_wysywig && props.pageSettings.field_pola_wysywig[8].value && (
      <ModalContainer>
        {utils.SafeHtml(props.pageSettings.field_pola_wysywig[8].value)}
      </ModalContainer>
    )}
  </>
);
