/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';
import MaskedInput from 'react-text-mask';
import errorIcon from '@assets/svg/error-icon.svg';
import { ConfiguratorActions } from '@store/actions';

const InputForm = styled.div`
  margin: 24px 0;

  > div {
    position: relative;
    padding-bottom: 5px;

    &.isError {
      padding-bottom: 15px;
    }
  }

  input {
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    color: ${colors.text.default};
    outline: none;
    padding: 16px 14px 15px;
    width: 100%;
    padding-right: 35px;
    box-shadow: none;

    &::placeholder {
      color: ${colors.gray};
    }

    &:focus {
      border-color: ${colors.text.default};
    }

    &.isError {
      border-color: ${colors.error};
    }
  }

  label {
    display: block;
    color: ${colors.text.default};
    font-weight: 700;
    font-size: ${dimensions.fontSize.small}px;
    margin-bottom: 10px;

    span {
      font-size: ${dimensions.fontSize.smaller}px;
      font-weight: 300;
      margin-left: 3px;
      vertical-align: middle;
    }
  }
`;

const Error = styled.div`
  color: ${colors.error};
  font-size: ${dimensions.fontSize.smaller}px;
  font-weight: 300;
  margin-top: 10px;
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;

  &.two-lines {
    bottom: -27px;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 12px;
`;

interface InputFormProps {
  name: string;
  type: string;
  labelText: string;
  labelDescription?: string;
  value: string;
  onChangeInput: Function;
  onBlurInput: Function;
  validation: any;
  validateSchema: any;
  configuratorActions?: typeof ConfiguratorActions;
  maxlength?: number;
  nipControlSum?: boolean;
  nipControlSumErrorMessage?: string;
  textsList: Array<string>;
}

class FormInput extends Component<InputFormProps> {
  onInputFocus = () => {
    if (this.props.configuratorActions) {
      this.props.configuratorActions.toggleSummaryPanel(true);
    }
  };

  onInputBlur = name => {
    if (this.props.configuratorActions) {
      this.props.configuratorActions.toggleSummaryPanel(false);
    }
    this.props.onBlurInput(name);
  };

  render() {
    const {
      name,
      type,
      labelText,
      labelDescription,
      value,
      onChangeInput,
      validation,
      validateSchema,
      maxlength,
      nipControlSum,
      nipControlSumErrorMessage,
      textsList,
    } = this.props;

    return (
      <InputForm
        className={!validation.required || !validation.match || nipControlSum ? 'isError' : ''}
      >
        <label>
          {labelText}
          {labelDescription ? <span>{labelDescription}</span> : null}
        </label>
        <div
          className={!validation.required || !validation.match || nipControlSum ? 'isError' : ''}
        >
          {name === 'address1PostalCode' || name === 'address2PostalCode' ? (
            <MaskedInput
              type={type}
              name={name}
              mask={[/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/]}
              placeholder="__-___"
              value={value}
              onChange={e => onChangeInput(e.target.value, name)}
              onBlur={() => this.onInputBlur(name)}
              onFocus={this.onInputFocus}
              className={!validation.required || !validation.match ? 'isError' : ''}
            />
          ) : (
            <input
              type={type}
              name={name}
              value={value}
              onChange={e => onChangeInput(e.target.value, name)}
              onBlur={() => this.onInputBlur(name)}
              onFocus={this.onInputFocus}
              className={
                !validation.required || !validation.match || nipControlSum ? 'isError' : ''
              }
              maxLength={maxlength || undefined}
            />
          )}

          {!validation.required || !validation.match || nipControlSum ? (
            <>
              <ErrorIcon src={errorIcon} />

              <Error
                className={
                  (name === 'address1PostalCode' || name === 'address2PostalCode') &&
                  validation.required
                    ? 'two-lines'
                    : ''
                }
              >
                {!validation.required
                  ? textsList[validateSchema.required.index]
                  : !validation.match
                  ? textsList[validateSchema.match.index]
                  : nipControlSum
                  ? nipControlSumErrorMessage
                  : ''}
              </Error>
            </>
          ) : null}
        </div>
      </InputForm>
    );
  }
}

export default FormInput;
