/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import { RootStateWithSelectors, RootActions, PostDataBuyForm } from '@store/types';
import get from 'lodash.get';

import ShoppingSummaryPanel from '@components/configurator/shoppingCart/ShoppingSummaryPanel';
import ConfiguratorHeader from '@components/configurator/header/ConfiguratorHeader';
import ConfiguratorStep1 from '@components/configurator/steps/ConfiguratorStep1';
import ConfiguratorStep2 from '@components/configurator/steps/ConfiguratorStep2';
import ConfiguratorStep4 from '@components/configurator/steps/ConfiguratorStep4';
import ConfiguratorStep1Colors from '@components/configurator/steps/ConfiguratorStep1Colors';
import ConfiguratorStep1Accessories from '@components/configurator/steps/ConfiguratorStep1Accessories';
import Tooltip from '@components/common/Tooltip';
import { validateInputs } from '@components/configurator/contactForm/ContactFormInputsData';
import * as utils from '@utils';

import { navigate } from 'gatsby';
import { ConfiguratorDataProps } from '@graphql/queries/ConfiguratorQuery';
import { SettingsDataProps } from '@graphql/queries/SettingsQuery';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import LoaderCover from '@components/common/LoaderCover';
import { LocationDependent } from '@content/types/general';
import Modal from '@components/common/Modal';
import FundingsModal from '@components/configurator/fundings/FundingsModal';
import ConfiguratorStep3 from './steps/ConfiguratorStep3';
import { getAdditionalPrices } from '../../utils/prices';

const MainContainer = styled.div`
  display: flex;
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      height: 100vh;
    `
  )}
`;

const LeftContainer = styled.div`
  flex: 1;
  max-width: 100%;

  ${respondFrom(
    breakpoints.lg,
    css`
      overflow: hidden;
      border-right: 1px solid ${colors.gray};
    `
  )}
`;

const MainContentContainer = styled.div<StyleProps>`
  padding-bottom: 140px;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: static;
      height: calc(100vh - 100px);
      margin-top: 0;
      padding: 0;

      overflow-y: auto;
      overflow-x: hidden;
    `
  )}
`;

interface StyleProps {
  activeStep?: string;
  fullHeight: boolean;
}

export interface ConfiguratorGridProps
  extends RootStateWithSelectors,
    RootActions,
    ConfiguratorDataProps,
    SettingsDataProps,
    LocationDependent {
  activeStep: string;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
}

// TODO: refactor!
// validation / sending should not be here
export default class ConfiguratorGrid extends React.Component<ConfiguratorGridProps> {
  state = {
    nipInDatabase: false,
    lockerValid: true,
    isCustomError: false,
    customErrorMessage: '',
    isTooltipLeasing: false,
    isOrderLoading: false,
    isError: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isTooltipLeasing: true });
    }, 1000);

    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentDidUpdate() {
    // Set default payment type
    if (
      !!this.props.settings.default_payment_type &&
      this.props.configurator.paymentType === undefined
    ) {
      this.props.configuratorActions.setPaymentType(this.props.settings.default_payment_type);
    } else if (
      // Set cash payment type if products does not contain lease
      this.props.configurator.paymentType === 'lease' &&
      this.props.products[this.props.langcode] &&
      !this.props.products[this.props.langcode].find(product =>
        utils.productHasAvailablePaymentType(product, 'lease')
      )
    ) {
      this.props.configuratorActions.setPaymentType('cash');
    }

    // Set default localization if doesn't exists
    if (!this.props.configurator.basket.tariffLocalizations) {
      this.props.configuratorActions.basketSetTariffLocalizations(1);
    } else if (
      // Set products length if tariff localizations is greater
      this.props.configurator.basket.products.length > 0 &&
      this.props.configurator.basket.products.length <
        this.props.configurator.basket.tariffLocalizations
    ) {
      this.props.configuratorActions.basketSetTariffLocalizations(
        this.props.configurator.basket.products.length
      );
    }

    // Clear basket and set version
    if (this.props.settings.current_version > 0) {
      if (!this.props.configurator.version) {
        this.props.configuratorActions.setVersionNumber(this.props.settings.current_version);
      } else if (this.props.configurator.version !== this.props.settings.current_version) {
        this.props.configuratorActions.clearBasket(true);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  onPayButtonClick = () => {
    const formData = { ...this.props.configurator.formData };
    const { agreements, configurator, langcode } = this.props;
    if (
      configurator.paymentType &&
      utils.isAgreementsAccepted(
        agreements[langcode],
        formData.agreements,
        configurator.paymentType
      ) &&
      utils.isFundingAvaiable(this.props.configurator.basket) &&
      utils.isEveryProductAvailable(this.props.configurator.basket, configurator.paymentType) &&
      !this.state.isOrderLoading
    ) {
      this.formValidateAndSend();

      // Works only on the built version !!!
      // if (typeof window !== 'undefined') {
      //   gtag('event', 'conversion', {
      //     send_to: 'AW-867719384/ZIDWCLuS-rABENix4Z0D',
      //     value: 1.0,
      //     currency: 'PLN',
      //     transaction_id: '',
      //     // event_callback: () => {
      //     //   if (typeof this.props.location.href !== 'undefined') {
      //     //     window.location = this.props.location.href;
      //     //   }
      //     // },
      //   });
      //   fbq('track', 'Zaplac', {});
      //   gtag('event', 'conversion', {
      //     allow_custom_scripts: true,
      //     send_to: 'DC-9601776/pv/ipost0+standard',
      //   });
      // }
    }
  };

  formValidate = (): boolean => {
    const formValidation = { ...this.props.configurator.formValidation };
    const formValidationKeys = Object.keys(formValidation);
    const formData = { ...this.props.configurator.formData };
    const formDataKeys = Object.keys(formData);
    const validationUpdate = {};
    const delivery = { ...this.props.configurator.delivery };
    let required = true;
    let match = true;

    this.setState({ lockerValid: true });

    // if (formData.acceptLicense && formData.acceptPersonalData && formData.acceptRegulations) {
    formDataKeys.map(name => {
      const value = formData[name];

      if (validateInputs[name]) {
        if (validateInputs[name].required.value) {
          required = value !== '' ? true : false;
        } else {
          required = true;
        }

        if (validateInputs[name].match.value !== false) {
          match = value.match(validateInputs[name].match.value) !== null ? true : false;
        } else {
          match = true;
        }
      }

      validationUpdate[name] = { required, match };
    });

    delete validationUpdate['agreements'];
    delete validationUpdate['sameDeliveryAddress'];

    if (delivery.deliveryType !== 'newAddress') {
      const validate = { required: true, match: true };

      validationUpdate['address2City'] = validate;
      validationUpdate['address2HouseNumber'] = validate;
      validationUpdate['address2PostalCode'] = validate;
      validationUpdate['address2Street'] = validate;
    }

    this.props.configuratorActions.getFormValidation(
      Object.assign(formValidation, validationUpdate)
    );
    // }

    let valid = true;

    formValidationKeys.map(name => {
      if (!formValidation[name].required || !formValidation[name].match) {
        valid = false;
      }
    });
    if (delivery.deliveryType === 'inpost' && delivery.locker === null) {
      valid = false;
      this.setState({ lockerValid: false });
    }

    return valid;
  };

  formValidateAndSend = () => {
    if (this.formValidate()) {
      this.onFormSubmit();
    }
  };

  onFormSubmit = () => {
    // this.props.configuratorActions.setLoading(true);

    this.setState({ isOrderLoading: true });

    const dataLayer = this.getDataToSetDataLayer();
    fetch(`${process.env.IPOS_BE_API_URL}/order-front`, {
      method: 'POST',
      body: JSON.stringify(this.getDataToSend()),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then((response: { opaqueId: string; reason?: string; message?: string }) => {
        if (response && response.opaqueId && process.env.IPOS_IPSALE_ORDER_URL) {
          utils.setDatalayerPurchase(
            dataLayer.products,
            response.opaqueId,
            dataLayer.coupon,
            dataLayer.totalPrices
          );
          utils.setDatalayerOrderPlaced(response.opaqueId);

          this.props.configuratorActions.clearBasket(true);

          if (typeof window !== typeof undefined) {
            setTimeout(() => {
              window.location.href = `${process.env.IPOS_IPSALE_ORDER_URL}${response.opaqueId}`;
            }, 500);
          }
        } else if (
          response &&
          (response.reason === 'USER_MOBILE_DUPLICATE' ||
            response.reason === 'USER_EMAIL_DUPLICATE' ||
            response.reason === 'CUSTOMER_NOT_NEW_ERROR' ||
            response.reason === 'CUSTOMER_ORDER_EXISTS_ERROR')
        ) {
          this.setState({ nipInDatabase: true });
        } else {
          this.setState({
            isCustomError: true,
            customErrorMessage: response.reason || response.message,
          });
        }
      })
      .catch(() => {
        this.setState({
          isError: true,
        });
      })
      .finally(() => {
        this.props.configuratorActions.setLoading(false);
        this.setState({ isOrderLoading: false });
      });
  };

  getDataToSend = (): PostDataBuyForm => {
    const { basket, formData, formCalendar, paymentType, delivery } = this.props.configurator;
    let formDataToSend;

    if (delivery.deliveryType === 'inpost') {
      formDataToSend = {
        ...formData,
        paczkomatId: delivery.locker.name,
      };

      formDataToSend.address2ApartmentNumber = delivery.locker.address_details.flat_number;
      formDataToSend.address2City = delivery.locker.address_details.city;
      formDataToSend.address2HouseNumber = delivery.locker.address_details.building_number;
      formDataToSend.address2PostalCode = delivery.locker.address_details.post_code;
      formDataToSend.address2Street = delivery.locker.address_details.street;
    } else {
      if (delivery.deliveryType === 'sameAddress') {
        formDataToSend = {
          ...formData,
          sameDeliveryAddress: true,
        };
      } else {
        formDataToSend = formData;
      }
    }

    const fundingsTransformed = utils.transformFundingsSelectionsToSend(
      basket.fundings,
      this.props.fundings[this.props.langcode]
    );

    const tariffPrice = utils.prices.getTariffPrice({
      tariff: basket.tariff,
      priceListGroup: fundingsTransformed[0].apiName,
    });
    return {
      devices: basket.products.map(product => {
        const productPrice = utils.prices.getProductPrice({
          product,
          paymentType,
          priceListGroup: fundingsTransformed[0].apiName,
        });

        return {
          id: product.id,
          color: get(product.currentColor, 'id'),
          accessories: product.accessories.map(accessory => accessory.id),
          priceListID: productPrice ? productPrice.id : null,
          additionalPriceLists: getAdditionalPrices({
            product,
            paymentType,
            priceListGroup: fundingsTransformed[0].apiName,
          }),
        };
      }),
      fundings: fundingsTransformed.map(funding => funding.id),
      tariff: get(tariffPrice, 'id'),
      tariffLocalizations: get(basket, 'tariffLocalizations'),
      formData: formDataToSend,
      formCalendar: {
        selectServiceDate: formCalendar.selectServiceDate,
        serviceDate: this.dateFix(formCalendar.serviceDate),
      },
      discountCoupon: basket.discountCoupon ? basket.discountCoupon.code : null,
      paymentType: paymentType,
      fiscalization: get(basket.fiscalization, 'id'),
    };
  };

  getDataToSetDataLayer = () => {
    const { basket } = this.props.configurator;

    const prices = utils.prices.getConfiguratorPricesSummary(
      basket,
      'cash',
      this.props.leasingInfo
    );

    return {
      totalPrices: prices.oneOff.total,
      products: basket.products.map(product => {
        const productPrice = utils.prices.getProductPrice({
          product,
          paymentType: 'cash',
        });
        return {
          ...product,
          price: productPrice ? productPrice.iposFee : 0,
        };
      }),
      coupon: basket.discountCoupon ? basket.discountCoupon.code : undefined,
    };
  };

  dateFix = (date: string) => {
    const timeStamp = new Date(date).getTime();
    const hourInMs = 3600 * 1000;
    return new Date(timeStamp + 2 * hourInMs);
  };

  onCloseNipInDatabaseModal = () => this.setState({ nipInDatabase: false });
  onCloseCustomErrorModal = () => this.setState({ isCustomError: false, customErrorMessage: '' });
  onCloseErrorModal = () => this.setState({ isError: false });

  handleCloseFundingsModal = () => {
    if (this.props.configurator.basket.fundings) {
      this.props.configurator.basket.fundings.forEach(funding => {
        this.props.configuratorActions.basketRemoveFunding(funding);
      });
    }
  };

  render() {
    const {
      products,
      accessories,
      fundings,
      tariffs,
      configurator,
      configuratorActions,
      activeStep,
      leasingInfo,
      location,
      langcode,
      urls,
      calendarDaysToVisit,
      fiscalizations,
      agreements,
    } = this.props;
    const pageSettings = this.props.pageSettings[this.props.langcode];

    // TODO: can be done a better way?
    if (
      activeStep !== 'krok-1' &&
      !configurator.basket.products.length &&
      typeof window !== typeof undefined
    ) {
      navigate(urls[4]);
      return null;
    }

    const currentProduct =
      typeof configurator.currentProductIndex === 'number'
        ? configurator.basket.products[configurator.currentProductIndex]
        : null;

    const fundingsFiltered = utils.filterFundingsWithNoZeroDiscount(fundings[this.props.langcode]);

    return (
      <>
        <MainContainer>
          <LeftContainer>
            <ConfiguratorHeader
              activeStep={activeStep}
              basket={configurator.basket}
              location={location}
              pageSettings={pageSettings}
              langcode={langcode}
              urls={urls}
              allFundings={fundingsFiltered}
              paymentType={configurator.paymentType}
            />

            <MainContentContainer
              activeStep={activeStep}
              fullHeight={configurator.summaryPanelHiden}
            >
              {this.props.activeStep === 'krok-2' ? (
                <ConfiguratorStep2
                  tariffs={tariffs[this.props.langcode]}
                  configuratorActions={configuratorActions}
                  currentTariff={configurator.basket.tariff}
                  currentFundings={configurator.basket.fundings}
                  basket={configurator.basket}
                  {...this.props}
                  langcode={langcode}
                  allFundings={fundingsFiltered}
                />
              ) : this.props.activeStep === 'krok-3' ? (
                <ConfiguratorStep3
                  configuratorActions={configuratorActions}
                  basket={configurator.basket}
                  pageSettings={pageSettings}
                  calendarDaysToVisit={calendarDaysToVisit}
                  formCalendar={configurator.formCalendar}
                  fiscalizations={fiscalizations[langcode]}
                />
              ) : this.props.activeStep === 'krok-4' ? (
                <ConfiguratorStep4
                  configuratorActions={configuratorActions}
                  formData={configurator.formData}
                  formValidation={configurator.formValidation}
                  nipInDatabase={this.state.nipInDatabase}
                  onCloseNipInDatabaseModal={this.onCloseNipInDatabaseModal}
                  basket={configurator.basket}
                  allFieldsValidation={this.formValidate}
                  {...this.props}
                  langcode={langcode}
                  urls={this.props.urls}
                  deliveryData={configurator.delivery}
                  lockerValid={this.state.lockerValid}
                  customError={{
                    isError: this.state.isCustomError,
                    errorMessage: this.state.customErrorMessage,
                    onCloseModal: this.onCloseCustomErrorModal,
                  }}
                  error={{
                    isError: this.state.isError,
                    onCloseModal: this.onCloseErrorModal,
                  }}
                  paymentType={configurator.paymentType}
                  agreements={agreements[langcode]}
                />
              ) : this.props.activeStep === 'colors' ? (
                <ConfiguratorStep1Colors
                  basket={configurator.basket}
                  configuratorActions={configuratorActions}
                  currentProductIndex={configurator.currentProductIndex}
                  location={location}
                  pageSettings={pageSettings}
                  langcode={langcode}
                  urls={this.props.urls}
                  allFundings={fundingsFiltered}
                />
              ) : this.props.activeStep === 'accessories' && currentProduct ? (
                <ConfiguratorStep1Accessories
                  accessories={accessories[this.props.langcode]}
                  configuratorActions={configuratorActions}
                  currentProductIndex={configurator.currentProductIndex}
                  currentProduct={currentProduct}
                  basket={configurator.basket}
                  location={location}
                  pageSettings={pageSettings}
                  langcode={langcode}
                  urls={this.props.urls}
                  allFundings={fundingsFiltered}
                />
              ) : (
                <ConfiguratorStep1
                  products={products}
                  configurator={configurator}
                  configuratorActions={configuratorActions}
                  langcode={langcode}
                  pageSettings={pageSettings}
                  location={location}
                  allFundings={fundingsFiltered}
                />
              )}
            </MainContentContainer>
          </LeftContainer>
          <ShoppingSummaryPanel
            {...this.props}
            activeStep={activeStep}
            allFundings={fundingsFiltered}
            formData={configurator.formData}
            onPayButtonClick={this.onPayButtonClick}
            paymentType={configurator.paymentType}
            leasingInfo={leasingInfo}
            summaryPanelHiden={configurator.summaryPanelHiden}
            langcode={langcode}
            urls={this.props.urls}
            isOrderLoading={this.state.isOrderLoading}
            configurator={configurator}
            currentFundings={configurator.basket.fundings}
            products={products[langcode]}
            agreements={agreements[langcode]}
          />
        </MainContainer>
        {(!configurator.paymentType ||
          !utils.isAgreementsAccepted(
            agreements[langcode],
            configurator.formData.agreements,
            configurator.paymentType
          )) && <Tooltip text={pageSettings.field_proste_pola[64]} theme="light" id="payButton" />}
        {this.state.isTooltipLeasing && (
          <Tooltip
            text={utils.SafeHtml(
              pageSettings.field_pola_wysywig && pageSettings.field_pola_wysywig[11]
                ? pageSettings.field_pola_wysywig[11].value
                : ''
            )}
            theme="light"
            id="leasingFromOutsideInfo"
            clickable={true}
            width="medium"
          />
        )}

        {products &&
          products[langcode]
            .filter(product => {
              return !!product.tooltipTextForBranches;
            })
            .map(product => {
              const productSlug = [product.name.toLowerCase().split(' '), product.langcode].join(
                '-'
              );

              return (
                <Tooltip
                  key={productSlug}
                  text={product.tooltipTextForBranches}
                  width="medium"
                  theme="light"
                  id={`tooltipBranches-${productSlug}`}
                  effect="solid"
                  border
                />
              );
            })}
        <Tooltip width="medium" theme="light" id={`tooltipFounding`} effect="solid" border />

        <Modal
          isOpened={utils.isFundingUnavaiable(configurator.basket)}
          content={
            <FundingsModal
              pageSettings={pageSettings}
              configuratorActions={configuratorActions}
              basket={configurator.basket}
            />
          }
          onCloseModalPanel={this.handleCloseFundingsModal}
        />

        <Tooltip width="medium" theme="light" id={`tooltipReturnPayment`} effect="solid" border />

        <Tooltip
          width="large"
          theme="light"
          id={`tooltipPaymentType`}
          effect="solid"
          border
          alignLeft
        />

        <LoaderCover active={this.props.configurator.loading} />
      </>
    );
  }
}
