import styled from '@emotion/styled';
import { colors, dimensions, animation, mixins } from '@styles';

export const NipInput = styled.div`
margin-top: 30px;
  position: relative;

  /* ! use @components/common/Button - same styling sa ButtonLink should be used (size=tiny) */
  button {
    position: absolute;
    right: 7px;
    bottom: 13px;
    height: 36px;
    border: none;
    cursor: pointer;
    font-size: ${dimensions.fontSize.smaller}px;
    color: ${colors.white};
    text-transform: uppercase;
    padding: 0 40px;
    ${mixins.gradientGreen}
    transition: ${animation.fast}ms ease-out;
    transition-property: background-position;

    &:focus {
      outline: none;
    }

    &:hover {
      background-position: -20% center;
    }

    &.error {
      bottom: 23px;
    }
  }
`;
