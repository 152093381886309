import React, { useEffect, useMemo, useRef, useState } from 'react';
import Title from '@components/common/Title';
import { Subtitle } from '@components/common/Subtitle';
import TariffsList from '../tariff/TariffsList';
import { Tariff, Funding, ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import TariffLocalizations from '../tariff/TariffLocalizations';
import Modal from '@components/common/Modal';
import TariffLocalizationsModal from '../tariff/TariffLocalizationsModal';
import styled from '@emotion/styled';
import TariffPhone from '../tariff/TariffPhone';
import Container from '@components/layout/Container';
import * as utils from '@utils';

const StyledSubtitle = styled(Subtitle)`
  br {
    display: block;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

interface Props extends LocationDependent {
  tariffs: Tariff[];
  configuratorActions: typeof ConfiguratorActions;
  currentTariff: Tariff | null;
  currentFundings: Funding[];
  basket: ConfiguratorBasket;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  allFundings: Funding[];
}

const MAX_LOCALIZATIONS = 5;

export default (props: Props) => {
  const [showTariffLocalizationsModal, setShowTariffLocalizationsModal] = useState(false);
  const wPole = useMemo(() => props.pageSettings[props.langcode].field_pola_wysywig || [], [
    props.pageSettings,
    props.langcode,
  ]);
  const pPole = useMemo(() => props.pageSettings[props.langcode].field_proste_pola || [], [
    props.pageSettings,
    props.langcode,
  ]);

  const subtitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (subtitleRef.current) {
      const link: HTMLAnchorElement | null = subtitleRef.current.querySelector("a[href='#']");
      link && link.addEventListener('click', handleTariffLocalizationsModalOpen);
      return () => {
        link && link.removeEventListener('click', handleTariffLocalizationsModalOpen);
      };
    }
  }, [props.pageSettings, props.langcode]);

  useEffect(() => {
    if(props.basket.tariffLocalizations > MAX_LOCALIZATIONS) {
      props.configuratorActions.basketSetTariff(null);
    }
  }, [props.basket.tariffLocalizations]);

  const handleTariffLocalizationsModalClose = () => {
    setShowTariffLocalizationsModal(false);
  };
  const handleTariffLocalizationsModalOpen = () => {
    setShowTariffLocalizationsModal(true);
    return false;
  };

  return (
    <Container mode={{ breakpoints: 'md', width: 'narrow' }}>
      <MetaPage title="Krok 2 - Sklep" />
      <Title>{pPole[44]}</Title>
      <StyledSubtitle ref={subtitleRef}>
        {props.basket.products.length < 2 && wPole[24] && utils.SafeHtml(wPole[24].value)}
        {props.basket.products.length >= 2 && wPole[23] && utils.SafeHtml(wPole[23].value)}
      </StyledSubtitle>
      <TariffLocalizations
        configuratorActions={props.configuratorActions}
        basket={props.basket}
        maxLocalizations={MAX_LOCALIZATIONS}
        pageSettings={props.pageSettings[props.langcode]}
      />
      {props.basket.tariffLocalizations <= MAX_LOCALIZATIONS && <TariffsList {...props} />}
      {props.basket.tariffLocalizations > MAX_LOCALIZATIONS && (
        <TariffPhone pageSettings={props.pageSettings[props.langcode]} />
      )}
      <Modal
        isOpened={showTariffLocalizationsModal}
        onCloseModalPanel={handleTariffLocalizationsModalClose}
        content={<TariffLocalizationsModal pageSettings={props.pageSettings[props.langcode]} />}
        closeColor="black"
      />
    </Container>
  );
};
