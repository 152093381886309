import React, { Component } from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css, colors, animation, mixins, dimensions } from '@styles';

import * as utils from '@utils';
import Icon from '@components/common/Icon';
import ButtonLink from '@components/common/ButtonLink';
import { ConfiguratorBasket, Product } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import { FormData } from '@store/content/types';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { Agreement } from '@content/types/agreement';

const StyledButtonLink = styled(ButtonLink)`
  display: block;
`;

const ButtonContainer = styled.div<StyledProps>`
  display: ${props => (props.hideOnMobile ? 'none' : 'block')};

  ${respondFrom(
    breakpoints.lg,
    css`
      display: block;
    `
  )}
`;

const PayButton = styled.button<StyledProps>`
  display: ${props => (props.width === 'full' ? 'block' : 'inline-block')};
  width: 100%;
  text-align: center;
  padding: 1.34em 0.8em 1.2em;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -0.025em; /* 0.4px / 16px */
  color: ${colors.white};
  position: relative;
  white-space: nowrap;
  ${mixins.gradientGreen}
  border: none;
  cursor: pointer;
  font-size: ${dimensions.fontSize.small}px;

  transition: ${animation.fast}ms ease-out;
  transition-property: background-position, transform;

  ${respondFrom(
    breakpoints.lg,
    css`
      font-size: ${dimensions.fontSize.regular}px;
    `
  )}

  &:focus {
    outline: none;
  }

  &.disabled {
    background-image: none;
    background-color: ${colors.text.light};
    /* pointer-events: none; */
  }
`;

interface SummaryButtonProps extends LocationDependent {
  active: boolean;
  basket: ConfiguratorBasket;
  activeStep: string;
  currentProductIndex: number;
  configuratorActions: typeof ConfiguratorActions;
  hideOnMobile?: boolean;
  formData: FormData;
  onPayButtonClick: Function;
  width?: 'full';
  smallOnMobile?: boolean;
  pageSettings: ConfiguratorSettings;
  paymentType?: string;
  langcode: string;
  urls: Array<string>;
  isOrderLoading: boolean;
  agreements: Agreement[];
}

interface StyledProps {
  hideOnMobile?: boolean;
  width?: string;
}

class ShoppingSummaryButton extends Component<SummaryButtonProps> {
  getButtonProperties = () => {
    const { basket, activeStep, location, pageSettings, langcode, urls } = this.props;
    const pPole = pageSettings.field_proste_pola;

    let currentProductIndex = this.props.currentProductIndex;
    let buttonName;
    let buttonPath;

    if (!currentProductIndex) {
      currentProductIndex = 0;
    }

    if (activeStep === 'krok-1') {
      if (
        basket.products.find(product => utils.productHasAvailableColors(product)) ||
        basket.products.length === 0
      ) {
        buttonName = pPole ? pPole[16] : '';
        buttonPath = utils.langLink(langcode, `${urls[9]}${utils.getBranchParameter(location)}`);
      } else if (basket.products.find(product => utils.productHasAvailableAccessories(product))) {
        buttonName = pPole ? pPole[18] : '';
        buttonPath = utils.langLink(langcode, `${urls[10]}${utils.getBranchParameter(location)}`);
      } else {
        buttonName = pPole ? pPole[20] : '';
        buttonPath = utils.langLink(langcode, `${urls[6]}${utils.getBranchParameter(location)}`);
      }
    } else if (activeStep === 'colors') {
      const restOfProducts: Array<Product> = [];
      basket.products.map((p, i) => {
        if (i > currentProductIndex) {
          restOfProducts.push(p);
        }
      });

      if (
        basket.products.length !== currentProductIndex + 1 &&
        restOfProducts.find(product => utils.productHasAvailableColors(product)) !== undefined
      ) {
        buttonName = `${pPole ? pPole[17] : ''} ${
          restOfProducts.find(product => utils.productHasAvailableColors(product)).name
        }`;
        buttonPath = utils.langLink(langcode, `${urls[9]}${utils.getBranchParameter(location)}`);
      } else if (basket.products.find(product => utils.productHasAvailableAccessories(product))) {
        buttonName = pPole ? pPole[18] : '';
        buttonPath = utils.langLink(langcode, `${urls[10]}${utils.getBranchParameter(location)}`);
      } else {
        buttonName = pPole ? pPole[20] : '';
        buttonPath = utils.langLink(langcode, `${urls[6]}${utils.getBranchParameter(location)}`);
      }
    } else if (activeStep === 'accessories') {
      const restOfProducts: Array<Product> = [];
      basket.products.map((p, i) => {
        if (i > currentProductIndex) {
          restOfProducts.push(p);
        }
      });

      if (
        basket.products.length &&
        basket.products.length !== currentProductIndex + 1 &&
        restOfProducts.find(product => utils.productHasAvailableAccessories(product)) !== undefined
      ) {
        buttonName = `${pPole ? pPole[19] : ''} ${
          restOfProducts.find(product => utils.productHasAvailableAccessories(product)).name
        }`;
        buttonPath = utils.langLink(langcode, `${urls[10]}${utils.getBranchParameter(location)}`);
      } else {
        buttonName = pPole ? pPole[20] : '';
        buttonPath = utils.langLink(langcode, `${urls[6]}${utils.getBranchParameter(location)}`);
      }
    } else if (activeStep === 'krok-2') {
      buttonName = pPole ? pPole[21] : '';
      buttonPath = utils.langLink(langcode, `${urls[7]}${utils.getBranchParameter(location)}`);
    } else if (activeStep === 'krok-3') {
      buttonName = pPole ? pPole[22] : '';
      buttonPath = utils.langLink(langcode, `${urls[8]}${utils.getBranchParameter(location)}`);
    } else if (activeStep === 'krok-4') {
      buttonName = pPole ? pPole[23] : '';
      buttonPath = utils.langLink(langcode, urls[20]);
    }

    return { buttonName, buttonPath };
  };

  goToNextProduct = () => {
    const restOfProducts: Array<Product> = [];
    this.props.basket.products.map((p, i) => {
      if (i > this.props.currentProductIndex) {
        restOfProducts.push(p);
      }
    });

    if (this.props.activeStep === 'krok-1') {
      this.props.configuratorActions.setCurrentProductIndex(0);
    } else if (this.props.activeStep === 'colors') {
      if (
        this.props.basket.products.length !== this.props.currentProductIndex + 1 &&
        restOfProducts.find(product => utils.productHasAvailableColors(product)) !== undefined
      ) {
        if (
          this.props.basket.products[this.props.currentProductIndex + 1].availableColors.length > 1
        ) {
          this.props.configuratorActions.setCurrentProductIndex(this.props.currentProductIndex + 1);
        } else {
          const p = restOfProducts.find(product => utils.productHasAvailableColors(product));
          this.props.configuratorActions.setCurrentProductIndex(
            this.props.basket.products.indexOf(p)
          );
        }
      } else {
        this.props.configuratorActions.setCurrentProductIndex(0);
      }
    } else if (this.props.activeStep === 'accessories') {
      if (
        this.props.basket.products.length !== this.props.currentProductIndex + 1 &&
        restOfProducts.find(product => utils.productHasAvailableAccessories(product)) !== undefined
      ) {
        if (
          utils.productHasAvailableAccessories(
            this.props.basket.products[this.props.currentProductIndex + 1]
          )
        ) {
          this.props.configuratorActions.setCurrentProductIndex(this.props.currentProductIndex + 1);
        } else {
          const p = restOfProducts.find(product => utils.productHasAvailableAccessories(product));
          this.props.configuratorActions.setCurrentProductIndex(
            this.props.basket.products.indexOf(p)
          );
        }
      } else {
        this.props.configuratorActions.setCurrentProductIndex(0);
      }
    }
  };

  checkIfDisabled = () => {
    const { activeStep, basket, active, paymentType } = this.props;
    let activeChecked;

    if (utils.isFundingUnavaiable(basket) || utils.isSomeProductUnavailable(basket, paymentType)) {
      activeChecked = true;
    } else {
      if (activeStep === 'krok-2') {
        if (basket.tariff === null) {
          activeChecked = true;
        } else {
          activeChecked = false;
        }
      } else if (activeStep === 'krok-3') {
        if (basket.fiscalization === null) {
          activeChecked = true;
        } else {
          activeChecked = false;
        }
      } else {
        activeChecked = !active;
      }
    }

    return activeChecked;
  };

  render() {
    const {
      activeStep,
      hideOnMobile,
      formData,
      onPayButtonClick,
      width,
      smallOnMobile,
      pageSettings,
      paymentType,
      isOrderLoading,
      agreements,
    } = this.props;
    const pPole = pageSettings.field_proste_pola;

    return (
      <ButtonContainer onClick={this.goToNextProduct} hideOnMobile={hideOnMobile}>
        {activeStep !== 'krok-4' ? (
          <StyledButtonLink
            to={this.getButtonProperties().buttonPath}
            icon="arrow-right"
            color="primary"
            disabled={this.checkIfDisabled()}
            size="basket"
            width={width}
            propagateClickEvent={true}
            smallOnMobile={smallOnMobile}
          >
            {this.getButtonProperties().buttonName}
          </StyledButtonLink>
        ) : (
          <>
            <PayButton
              onClick={() => onPayButtonClick()}
              className={
                !paymentType ||
                this.checkIfDisabled() ||
                !utils.isAgreementsAccepted(agreements, formData.agreements, paymentType) ||
                isOrderLoading
                  ? 'disabled'
                  : ''
              }
              data-tip="React-tooltip"
              data-for="payButton"
              width={width}
            >
              {pPole ? pPole[23] : ''}{' '}
              <Icon className="button-icon" type="arrow-right" inheritColor={true}></Icon>
            </PayButton>
          </>
        )}
      </ButtonContainer>
    );
  }
}

export default ShoppingSummaryButton;
