import { graphql, useStaticQuery } from 'gatsby';
import { Settings } from '@content/types/settings';
import * as settingsTransformers from '@content/transformers/SettingsTransformers';

// import {  } from '@store/content/types';

export interface SettingsDataProps {
  settings: Settings;
}

const SettingsQuery = (): Settings | {} => {
  const settingsData = useStaticQuery(graphql`
    query {
      # api data
      allSiteSettingEntityAdministration {
        edges {
          node {
            ...IposApiSettingsFragment
          }
        }
      }
    }
  `);

  return {
    settings: settingsTransformers.transformSettings(
      settingsData.allSiteSettingEntityAdministration
    ),
  };
};

export default SettingsQuery;
