/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import styled from '@emotion/styled';
import { colors, dimensions, fonts, animation, mixins } from '@styles';
import { FormCalendar } from '@store/content/types';
import dateFormat from 'dateformat';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';

const CalendarWrapper = styled.div`
  display: none;

  &.is-visible {
    display: block;
  }
`;

const CustomCalendar = styled(Calendar)`
  width: 100%;
  margin: 40px 0;
  border: 0;
  max-width: 400px;

  .react-calendar {
    &__navigation {
      font-family: ${fonts.default};
      height: auto;
      position: relative;
      align-items: center;
      margin-bottom: 0;

      &__label {
        font-size: ${dimensions.fontSize.regular}px;
        font-weight: bold;
        text-transform: capitalize;
        color: ${colors.text.default};
        padding: 20px 0;
        pointer-events: none;
      }

      button {
        &:enabled {
          &:hover,
          &:focus {
            background: transparent;
          }
        }
        &:disabled {
          background: transparent;
        }
      }

      &__arrow {
        color: ${colors.white};
        padding: 20px 0;
        width: 60px;
        position: relative;

        &::before {
          content: '';
          width: 14px;
          height: 14px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto 0;
          border: 3px solid #AAA;
          transform: rotate(45deg);
          transition: ${animation.fast}ms ease-out;
        }

        &:hover {
          &::before {
            border-color: ${colors.green.dark};
          }
        }
      }

      &__prev-button {
        &::before {
          border-top: none;
          border-right: none;
        }
      }

      &__next-button {
        &::before {
          border-bottom: none;
          border-left: none;
          margin-left: auto;
        }
      }
    }

    &__month-view {
      &__weekdays {
        padding-left: 20px;
        padding-right: 20px;
        background-color: #bfbfbf;
        abbr {
          color: ${colors.calendar};
          text-decoration: none;
          text-transform: capitalize;
          font-weight: bold;
          font-size: ${dimensions.fontSize.small}px;
          line-height: 1em;
        }
        &__weekday {
          padding: 5px;
        }
      }

      &__days {
        padding: 10px 20px;
        background-color: #f2f2f2;
        abbr {
          color: ${colors.calendar};
          font-family: ${fonts.default};
          font-weight: bold;
          font-size: 15px;
          line-height: 1em;
          min-height: 18px;
          min-width: 18px;
        }
        &__day {
          padding: 10px;

          &--weekend,
          &--neighboringMonth {
            pointer-events: none;
            abbr {
              opacity: 0.4;
            }
          }
        }
      }
    }

    &__tile {
      border-radius: 50%;
      ${mixins.transitionDefault}

      &--active {
        background: transparent;
        ${mixins.gradientGreen}

        abbr {
          color: ${colors.white};
        }

        &:enabled {
          &:focus {
            background: transparent;
            ${mixins.gradientGreen}
          }
        }
        &:hover {
          background: transparent;
          ${mixins.gradientGreen}
        }
      }

      &:disabled {
        background: transparent;

        abbr {
          opacity: 0.4;
        }

        &:hover {
          background: transparent;
        }
      }

      &:hover {
        background-color: ${colors.ui.whisper};
      }
    }
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
`;

interface CalendarProps {
  isOpen: boolean;
  onClickCalendar: Function;
  formCalendar: FormCalendar;
  value?: any;
  pageSettings: ConfiguratorSettings;
  calendarDaysToVisit: number;
}

class CalendarComponent extends Component<CalendarProps> {
  state = {
    holidays: [
      '25.12.2020',
      '01.01.2021',
      '06.01.2021',
      '04.04.2021',
      '05.04.2021',
      '01.05.2021',
      '03.05.2021',
      '23.05.2021',
      '03.06.2021',
      '15.08.2021',
      '01.11.2021',
      '11.11.2021',
      '25.12.2021',
      '26.12.2021',
    ],
  };

  setMinDate = () => {
    let moment;

    if (typeof window !== 'undefined') {
      moment = require('moment-business-days');
      moment.updateLocale('pl', {
        holidays: this.state.holidays,
        holidayFormat: 'DD.MM.YYYY',
      });

      const maxDaysQuantity = this.props.calendarDaysToVisit;
      const nextBusinessDayVariable = dateFormat(
        moment().isBusinessDay() ? new Date() : moment().nextBusinessDay()._d,
        'dd-mm-yyyy'
      );
      const maxDay = moment(nextBusinessDayVariable, 'DD-MM-YYYY').businessAdd(maxDaysQuantity)._d;

      return maxDay;
    }
  };

  setDisabledDays = (date: Date) => {
    const dates = this.state.holidays;
    return dates.includes(dateFormat(date, 'dd.mm.yyyy'));
  };

  render() {
    const { isOpen, onClickCalendar, value, pageSettings } = this.props;
    return (
      <CalendarWrapper className={isOpen ? 'is-visible' : ''}>
        <CustomCalendar
          value={value !== '' ? new Date(value) : undefined}
          onChange={date => {
            onClickCalendar(date);
          }}
          minDate={this.setMinDate()}
          tileDisabled={({ activeStartDate, date, view }) => this.setDisabledDays(date)}
        />
        {pageSettings.field_pola_wysywig &&
          utils.SafeHtml(pageSettings.field_pola_wysywig[9].value)}
      </CalendarWrapper>
    );
  }
}

export default CalendarComponent;
