export const validateInputs = {
  nip: {
    required: { value: true, message: 'Uzupełnij NIP', index: 66 },
    match: { value: /^[0-9]{10}$/, message: 'Nieprawidłowy format NIP', index: 67 },
  },
  company: {
    required: { value: true, message: 'Uzupełnij nazwę firmy', index: 68 },
    match: { value: /^.{2,}$/, message: 'Uzupełnij nazwę firmy', index: 68 },
  },
  name: {
    required: { value: true, message: 'Uzupełnij imię i nazwisko', index: 69 },
    match: { value: /^.{2,}$/, message: 'Uzupełnij imię i nazwisko', index: 69 },
  },
  phone: {
    required: { value: true, message: 'Uzupełnij numer telefonu', index: 70 },
    match: { value: /^[0-9]{9,}$/, message: 'Niepoprawny format numeru telefonu', index: 71 },
  },
  email: {
    required: { value: true, message: 'Uzupełnij email', index: 72 },
    match: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: 'Niepoprawny format email (poprawny format: example@example.com)',
      index: 73,
    },
  },
  address1Street: {
    required: { value: true, message: 'Uzupełnij nazwę ulicy', index: 74 },
    match: {
      value: /^.{2,40}$/,
      message: 'Wymagana ilość znaków: minimalnie 2 znaki, maksymalnie 40 znaków',
      index: 75,
    },
  },
  address1HouseNumber: {
    required: { value: true, message: 'Uzupełnij numer budynku', index: 76 },
    match: { value: /^.{0,10}$/, message: 'Maksymalnie 10 znaków', index: 77 },
  },
  address1ApartmentNumber: {
    required: { value: false, message: '' },
    match: { value: /^.{0,10}$/, message: 'Maksymalnie 10 znaków', index: 77 },
  },
  address1PostalCode: {
    required: { value: true, message: 'Uzupełnij kod pocztowy', index: 78 },
    match: {
      value: /^[0-9]{2}[-][0-9]{3}$/,
      message: 'Nieprawidłowy format kodu pocztowego',
      index: 79,
    },
  },
  address1City: {
    required: { value: true, message: 'Uzupełnij nazwę miejscowości', index: 80 },
    match: {
      value: /^.{2,40}$/,
      message: 'Wymagana ilość znaków: minimalnie 2 znaki, maksymalnie 40 znaków',
      index: 75,
    },
  },
  address2Street: {
    required: { value: true, message: 'Uzupełnij nazwę ulicy', index: 74 },
    match: {
      value: /^.{2,40}$/,
      message: 'Wymagana ilość znaków: minimalnie 2 znaki, maksymalnie 40 znaków',
      index: 75,
    },
  },
  address2HouseNumber: {
    required: { value: true, message: 'Uzupełnij numer budynku', index: 76 },
    match: { value: /^.{0,10}$/, message: 'Maksymalnie 10 znaków', index: 77 },
  },
  address2ApartmentNumber: {
    required: { value: false, message: '' },
    match: { value: /^.{0,10}$/, message: 'Maksymalnie 10 znaków', index: 77 },
  },
  address2PostalCode: {
    required: { value: true, message: 'Uzupełnij kod pocztowy', index: 78 },
    match: {
      value: /^[0-9]{2}[-][0-9]{3}$/,
      message: 'Nieprawidłowy format kodu pocztowego',
      index: 79,
    },
  },
  address2City: {
    required: { value: true, message: 'Uzupełnij nazwę miejscowości', index: 80 },
    match: {
      value: /^.{2,40}$/,
      message: 'Wymagana ilość znaków: minimalnie 2 znaki, maksymalnie 40 znaków',
      index: 75,
    },
  },
};
