/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-this-alias */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { animation, colors, dimensions } from '@styles';
import { ConfiguratorActions } from '@store/actions';
import { FormDeliveryData, FormData, FormValidation } from '@store/content/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { validateInputs } from '@components/configurator/contactForm/ContactFormInputsData';
import FormRadioButton from '@components/common/FormRadioButton';
import Button from '@components/common/Button';
import FormInput from '@components/common/FormInput';
import { Row } from '@components/configurator/contactForm/ContactFormPanel';
import IconPencil from '@assets/svg-inline/pencil.svg';

const DeliveryTitle = styled.h5`
  font-size: ${dimensions.fontSize.large}px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const InpostWrapper = styled.div`
  display: none;

  &.is-visible {
    display: block;
  }
`;

const DeliveryWrapper = styled.div`
  margin: 25px 0;
`;

const DeliveryAddressWrapper = styled.div`
  p {
    font-size: ${dimensions.fontSize.large}px;
    font-weight: 300;
    margin-bottom: 24px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${colors.gray};
      margin: 18px 0 24px;
    }
  }
`;

const LockerButtonWrapper = styled.div`
  margin-top: 15px;
`;

const LockerError = styled.div`
  font-size: ${dimensions.fontSize.smaller}px;
  font-weight: 300;
  color: ${colors.error};
  margin: 5px 0;
`;

const LockerAddressWrapper = styled.div`
  margin: 15px 0;
  border: 1px solid ${colors.ui.light};
  border-radius: 10px;
  padding: 15px;
  max-width: 290px;
  position: relative;

  h6 {
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 600;
    margin: 0 0 3px 0;
  }

  > div {
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    margin: 0 0 3px 0;
  }

  > p {
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    margin: 12px 0 0 0;
    color: ${colors.text.disabled};

    &:before {
      content: none;
    }
  }

  button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: transparent;
    border: none;

    svg {
      width: 15px;
      height: 15px;

      path {
        fill: ${colors.text.default};
        transition: ${animation.fast}ms ease-out;
      }
    }

    &:hover {
      svg path {
        fill: ${colors.green.dark};
      }
    }
  }
`;

interface ContactFormDeliveryProps {
  configuratorActions: typeof ConfiguratorActions;
  onChangeInput: Function;
  onBlurInput: Function;
  deliveryData: FormDeliveryData;
  pageSettings: ConfiguratorSettings;
  formData: FormData;
  formValidation: FormValidation;
  lockerValid: boolean;
}

class ContactFormDelivery extends Component<ContactFormDeliveryProps> {
  state = {
    radioValue: 'sameAddress',
    locker: {
      address: {
        line1: null,
        line2: null,
      },
      name: null,
      opening_hours: null,
    },
  };

  componentDidMount() {
    this.setState({
      radioValue:
        this.props.deliveryData && this.props.deliveryData.deliveryType
          ? this.props.deliveryData.deliveryType
          : 'sameAddress',
      locker: this.props.deliveryData.locker,
    });

    window.easyPackAsyncInit = () => {
      easyPack.init({
        defaultLocale: 'pl',
        mapType: 'osm',
        searchType: 'osm',
        points: {
          types: ['parcel_locker'],
        },
        map: {
          initialTypes: ['parcel_locker'],
        },
      });
    };
  }

  onRadioChange = (value: string) => {
    this.setState({ radioValue: value }, () => {
      this.props.configuratorActions.setDelivertType({
        deliveryType: value,
        locker: this.state.locker,
      });
    });
  };

  onSelectLocer = () => {
    easyPack.modalMap(
      (point, modal) => {
        this.onSaveLocker(point);
        modal.closeModal();
      },
      { width: 800, height: 600 }
    );
  };

  onSaveLocker = point => {
    this.setState({ locker: point }, () => {
      this.props.configuratorActions.setDelivertType({
        deliveryType: this.state.radioValue,
        locker: point,
      });
    });
  };

  render() {
    const pPole = this.props.pageSettings.field_proste_pola;
    const inputsAddress2 = [
      [
        { name: 'address2Street', type: 'text', labelText: pPole ? pPole[56] : '', maxlength: 40 },
        {
          name: 'address2HouseNumber',
          type: 'text',
          labelText: pPole ? pPole[57] : '',
          maxlength: 10,
        },
        {
          name: 'address2ApartmentNumber',
          type: 'text',
          labelText: pPole ? pPole[58] : '',
          maxlength: 10,
        },
      ],
      [
        { name: 'address2PostalCode', type: 'text', labelText: pPole ? pPole[59] : '' },
        { name: 'address2City', type: 'text', labelText: pPole ? pPole[60] : '', maxlength: 40 },
      ],
    ];

    return (
      <DeliveryWrapper>
        <DeliveryTitle>{pPole && pPole[113]}</DeliveryTitle>

        <FormRadioButton
          name="delivery-selection"
          list={[
            {
              value: 'sameAddress',
              label: pPole ? pPole[114] : '',
              price: pPole ? pPole[115] : '',
            },
            {
              value: 'newAddress',
              label: pPole ? pPole[116] : '',
              price: pPole ? pPole[117] : '',
            },
            // {
            //   value: 'inpost',
            //   label: pPole ? pPole[118] : '',
            //   price: pPole ? pPole[119] : '',
            // },
          ]}
          handleRadioChange={this.onRadioChange}
          radioValue={this.state.radioValue}
        />

        <InpostWrapper className={this.state.radioValue === 'inpost' ? 'is-visible' : ''}>
          <div>
            {this.state.locker === null ? (
              <>
                <LockerButtonWrapper>
                  <Button type="button" color="primary" size="smaller" onClick={this.onSelectLocer}>
                    <span>{pPole && pPole[120]}</span>
                  </Button>

                  {!this.props.lockerValid && <LockerError>{pPole && pPole[121]}</LockerError>}
                </LockerButtonWrapper>
              </>
            ) : (
              <LockerAddressWrapper>
                <h6>
                  {pPole && pPole[122]} {this.state.locker.name}
                </h6>
                <div>{this.state.locker.address.line1}</div>
                <div>{this.state.locker.address.line2}</div>
                <p>{this.state.locker.opening_hours}</p>
                <button type="button" onClick={this.onSelectLocer}>
                  <IconPencil />
                </button>
              </LockerAddressWrapper>
            )}
          </div>
        </InpostWrapper>

        {this.state.radioValue === 'newAddress' ? (
          <DeliveryAddressWrapper>
            <p>{pPole && pPole[62]}</p>

            {inputsAddress2.map((row, index) => (
              <Row key={index} className={index === 0 ? 'three-children' : 'two-children'}>
                {row.map(input => (
                  <FormInput
                    key={input.name}
                    name={input.name}
                    type={input.type}
                    labelText={input.labelText}
                    maxlength={input.maxlength}
                    value={this.props.formData[input.name]}
                    onChangeInput={this.props.onChangeInput}
                    onBlurInput={this.props.onBlurInput}
                    validation={this.props.formValidation[input.name]}
                    validateSchema={validateInputs[input.name]}
                    configuratorActions={this.props.configuratorActions}
                    textsList={pPole ? pPole : []}
                  />
                ))}
              </Row>
            ))}
          </DeliveryAddressWrapper>
        ) : null}
      </DeliveryWrapper>
    );
  }
}

export default ContactFormDelivery;
