import React, { useEffect, createRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { ConfiguratorBasket } from '@store/configurator/types';
import { ConfiguratorActions } from '@store/actions';
import ShoppingProduct from './ShoppingProduct';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import smoothscroll from 'smoothscroll-polyfill';
import { RootState, RootActions } from '@store/types';
import * as utils from '@utils';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { Funding } from '@store/content/types';
import closeSummaryIcon from '@assets/svg/arrow.svg';
import { Product } from '@content/types/product';

if (typeof window !== typeof undefined) {
  smoothscroll.polyfill();
}
interface ShoppingBasketProps extends RootState, RootActions {
  activeStep: string;
  currentProductIndex: number;
  basket: ConfiguratorBasket;
  onSummaryCloseOnMobile: Function;
  configuratorActions: typeof ConfiguratorActions;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  allFundings: Funding[];
  mobileShowAllProducts: boolean;
  onShowAllProductsOnMobile: Function;
  paymentType: string;
  products: Product[];
}

const Wrapper = styled.div`
  overflow-y: auto;

  ${respondFrom(
  breakpoints.lg,
  css`
      padding: 32px 15px 40px 15px;
      height: calc(100vh - 345px);
      display: block;
    `
)}
`;

const BasketName = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${colors.grayLight};
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  z-index: 5;

  ${respondFrom(
    breakpoints.lg,
    css`
      box-shadow: none;
      padding: 0;
      position: relative;
      background-color: ${colors.white};
    `
  )}

  h3 {
    margin: 0;

    &.hide-on-mobile {
      display: none;

      ${respondFrom(
        breakpoints.lg,
        css`
          display: block;
        `
      )}
    }

    &.hide-on-desktop {
      display: block;
      font-size: 20px;
      font-weight: 500;

      ${respondFrom(
        breakpoints.lg,
        css`
          display: none;
        `
      )}
    }
  }
`;

const CloseSummaryButton = styled.button`
  position: absolute;
  top: 7px;
  right: 10px;
  height: 40px;
  width: 40px;
  padding: 5px;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
  
  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )}
`;

const ProductsContainer = styled.div`
  padding: 20px 15px;
  margin-top: 54px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 0;
      margin-top: 0;
    `
  )}
`;

const ProductsCounter = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.text.default};
  margin-bottom: 15px;
`;

const ProductsButton = styled.div`
  position: relative;
  padding: 15px 0;
  font-size: 12px;
  font-weight: 500;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  
  &::after {
    content: '';
    display: block;
    margin-top: -5px;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border: 3px solid #848484;
    border-left: 0;
    border-top: 0;
    transform: translate(0, 5px) rotate(225deg);
    transform-origin: center;
    ${mixins.transitionDefault};
  }

  &.is-open {
    &::after {
      transform: rotate(45deg);
    }
  }

  ${respondFrom(
  breakpoints.lg,
  css`
      display: none;
    `
)}
`;

export const ShoppingBasket: React.FC<ShoppingBasketProps> = props => {
  const wrapperRef = createRef<HTMLDivElement>();
  const shouldHighlightItem = props.activeStep === 'colors' || props.activeStep === 'accessories';

  const pPole = useMemo(() => props.pageSettings[props.langcode].field_proste_pola, [props]);

  // on mount, update
  useEffect(() => {
    const wrapperElem = wrapperRef.current;

    // ref already created
    if (wrapperElem && shouldHighlightItem) {
      const itemsElems = wrapperElem.children[1].children;

      // items rendered
      if (itemsElems.length - 1 >= props.currentProductIndex) {
        const elem = itemsElems[props.currentProductIndex] as HTMLDivElement;
        const scrollTop = elem.offsetTop - (wrapperElem.clientHeight / 2 - elem.clientHeight / 2);

        // scroll to current item (centered)
        wrapperElem.scroll({
          top: scrollTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [props.currentProductIndex]); // only when currentProductIndex changes

  // on mount, update
  useEffect(() => {
    props.products.forEach(product => {
      // Remove the product from the cart if the prices does not exist
      if (!product.prices || product.prices.length === 0) {
        props.basket.products.forEach((basketProduct, basketProductIndex) => {
          if (product.id === basketProduct.id) {
            props.configuratorActions.basketRemoveProduct(basketProductIndex);
          }
        });
      }
    });

    // Remove the product from the cart if doesn't contain prices
    props.basket.products.forEach((basketProduct, basketProductIndex) => {
      if (!basketProduct.prices) {
        props.configuratorActions.basketRemoveProduct(basketProductIndex);
      }
    });
  }, [props.products, props.basket.products.length]);

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <BasketName>
          <h3 className="hide-on-mobile">
            {props.pageSettings[props.langcode].field_proste_pola[5]}
          </h3>
          <h3 className="hide-on-desktop">
            {props.pageSettings[props.langcode].field_proste_pola[6]}
          </h3>
          <CloseSummaryButton onClick={() => props.onSummaryCloseOnMobile()}>
            <img src={closeSummaryIcon} alt="close summary" />
          </CloseSummaryButton>
        </BasketName>
        <ProductsContainer>
          <ProductsCounter>
            {`${pPole ? pPole[137] : ''} (${props.basket.products.length})`}
          </ProductsCounter>
          {props.basket.products.map((product, index) => {
            return (
              <ShoppingProduct
                configuratorActions={props.configuratorActions}
                currentProductIndex={props.configurator.currentProductIndex}
                product={product}
                key={index}
                index={index}
                isClickable={
                  (props.activeStep === 'colors' && utils.productHasAvailableColors(product)) ||
                  (props.activeStep === 'accessories' && utils.productHasAvailableAccessories(product))
                }
                border={index === props.currentProductIndex && shouldHighlightItem}
                activeStep={props.activeStep}
                pageSettings={props.pageSettings[props.langcode]}
                basket={props.basket}
                allFundings={props.allFundings}
                paymentType={props.paymentType}
              />
            );
          })}
        </ProductsContainer>
      </Wrapper>
      {
        (props.basket.products.length > 2 || props.mobileShowAllProducts) &&
        <ProductsButton
          className={props.mobileShowAllProducts ? "" : "is-open"}
          onClick={() => props.onShowAllProductsOnMobile()}
        >
          {
            props.mobileShowAllProducts
              ? (pPole ? pPole[139] : '')
              : (pPole ? pPole[138] : '')
          }
        </ProductsButton>
      }
    </>
  );
};

export default ShoppingBasket;
