import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors, respondFrom, css } from '@styles';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';
import Image from 'gatsby-image';

const StyledTariffLocalizationsModal = styled.div``;

const StyledHeadline = styled.h5`
  color: ${colors.text.default};
  text-align: center;
  margin-bottom: 55px;
  font-size: 16px;
  font-weight: bold;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;;
  ${respondFrom(
    breakpoints.md,
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )}
`;

const StyledContent = styled.div`
  flex: 1;
  max-width: 280px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2em;
  color: ${colors.text.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  h6 {
    font-size: 16px;
    font-weight: bold;
    color: ${colors.green.summary};
  }

  p {
    :not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    color: ${colors.text.default};
    text-decoration: none;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin-bottom: 0;
    `
  )}
`;

const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  min-height: 115px;
`;

interface TariffLocalizationsModalProps {
  pageSettings: ConfiguratorSettings;
}

export default (props: TariffLocalizationsModalProps) => {
  const pPole = useMemo(() => props.pageSettings.field_proste_pola || [], [props.pageSettings]);
  const wPole = useMemo(() => props.pageSettings.field_pola_wysywig || [], [props.pageSettings]);
  const iPole = useMemo(() => props.pageSettings.field_images || [], [props.pageSettings]);

  return (
    <StyledTariffLocalizationsModal>
      <StyledHeadline>{pPole[177]}</StyledHeadline>
      <StyledWrapper>
        <StyledContent>
          {pPole[178] && <h6>{pPole[178]}</h6>}
          {iPole[1] && (
            <StyledImageWrapper>
              <Image {...iPole[1].childImageSharp} imgStyle={{ objectFit: 'contain' }} />
            </StyledImageWrapper>
          )}
          {wPole[20] && utils.SafeHtml(wPole[20].value)}
        </StyledContent>
        <StyledContent>
          {pPole[179] && <h6>{pPole[179]}</h6>}
          {iPole[2] && (
            <StyledImageWrapper>
              <Image {...iPole[2].childImageSharp} imgStyle={{ objectFit: 'contain' }} />
            </StyledImageWrapper>
          )}
          {wPole[21] && utils.SafeHtml(wPole[21].value)}
        </StyledContent>
      </StyledWrapper>
    </StyledTariffLocalizationsModal>
  );
};
