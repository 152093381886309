import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css, mixins } from '@styles';
import Image from 'gatsby-image';
import { ConfiguratorActions } from '@store/actions';
import { Accessory, BasketProduct } from '@store/types';
import SVG from 'react-inlinesvg';

import tableBackground from '@assets/images/accessories-bg.jpg';
import AddAccessoryIcon from '@assets/svg/add-accessory-icon.svg';
import RemoveAccessoryIcon from '@assets/svg/minus.svg';

const AccessoriesTable = styled.div`
  position: relative;
  margin: auto;
  min-height: 70%; /* max +30% ProductAccessoriesList */
  max-width: 1015px;
  width: 100%;
  overflow: hidden;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 680px;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 50px;
        width: 10%;
        z-index: 1;
      }
      &:before {
        left: 0;
        background-image: linear-gradient(
          to right,
          ${colors.white} 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &:after {
        right: 0;
        background-image: linear-gradient(to left, ${colors.white} 0%, rgba(255, 255, 255, 0) 100%);
      }
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      width: 800px;
    `
  )}

${respondFrom(
  breakpoints.xxl,
  css`
    width: 1050px;
  `
)}

  > img {
    display: none;

    ${respondFrom(
      breakpoints.lg,
      css`
        display: block;
      `
    )}
  }
`;

const ProductImage = styled(Image)`
  margin: auto;
  padding: 0 30px;
  max-width: 100%;

  ${respondTo(
    breakpoints.xxl,
    css`
      height: 140px !important;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute !important;
      left: 0;
      right: 0;
      top: 95px;
      margin: auto;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      top: 133px;
    `
  )}
`;

const AccessoryItem = styled.div`
  position: absolute;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: block;
    `
  )}

  > img:not([src$='.svg']) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    ${mixins.transitionDefault};
    z-index: 10;
    width: 40px;
    height: 40px;
  }

  svg,
  img[src$='.svg'] {
    height: 100%;
    width: 100%;
    cursor: pointer;

    path {
      stroke: ${colors.black};
      ${mixins.transitionDefault};
    }
  }

  &:hover {
    svg {
      path {
        stroke: ${colors.blue.default};
      }
    }

    img {
      opacity: 1;
    }
  }

  &.scanner {
    top: 170px;
    left: 100px;
    height: 90px;
    width: 90px;

    ${respondFrom(
      breakpoints.xl,
      css`
        top: 210px;
        left: 145px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        top: 250px;
        left: 135px;
        height: 140px;
        width: 140px;
      `
    )}
  }

  &.scale {
    top: 90px;
    right: 25px;
    height: 170px;
    width: 160px;

    ${respondFrom(
      breakpoints.xl,
      css`
        top: 120px;
        right: 55px;
        width: 190px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        top: 180px;
        right: 50px;
        height: 170px;
        width: 240px;
      `
    )}
  }

  &.drawer-small,
  &.drawer-big {
    right: 25px;
    left: 0;
    top: 258px;
    width: 120px;
    margin: auto;
    z-index: 1;

    ${respondFrom(
      breakpoints.xl,
      css`
        top: 302px;
        right: 30px;
        width: 150px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        right: 38px;
        left: 0;
        top: 384px;
        width: 201px;
      `
    )}
  }

  &.drawer-big {
    width: 175px;

    z-index: 0;

    ${respondFrom(
      breakpoints.xl,
      css`
        width: 205px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        width: 260px;
      `
    )}

    img {
      bottom: 10px;
    }
  }

  &.car-charger {
    top: 185px;
    left: 180px;
    height: 50px;
    width: 100px;

    ${respondFrom(
      breakpoints.xl,
      css`
        top: 225px;
        left: 230px;
        width: 110px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        top: 270px;
        left: 275px;
        height: 90px;
        width: 120px;
      `
    )}
  }

  &.printer {
    top: 85px;
    height: 100px;
    width: 105px;
    left: 55px;

    ${respondFrom(
      breakpoints.xl,
      css`
        top: 115px;
        height: 120px;
        width: 120px;
        left: 95px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        top: 140px;
        height: 160px;
        width: 160px;
        left: 40px;
      `
    )}
  }

  .minus {
    display: none;
  }

  &.added {
    .plus {
      display: none;
    }
    .minus {
      display: block;
    }

    svg {
      opacity: 0;
    }
  }

  &.inactive {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    .plus,
    .minus {
      display: none;
    }
  }
`;

const AccessoryItemAdded = styled(Image)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
  visibility: hidden;
  ${mixins.transitionDefault};

  &.added {
    opacity: 1;
    visibility: visible;
    z-index: 5;
    cursor: pointer;
  }
`;

interface AccessoriesTableProps {
  accessories: Accessory[];
  configuratorActions: typeof ConfiguratorActions;
  currentProduct: BasketProduct;
  toggleForCurrentProduct: Function;
}

export default (props: AccessoriesTableProps) => {
  return (
    <AccessoriesTable>
      <img src={tableBackground} alt="" />

      {props.currentProduct.imageForAccessories && (
        <ProductImage
          {...props.currentProduct.imageForAccessories.childImageSharp}
          imgStyle={{ objectFit: 'contain' }}
        />
      )}

      {props.accessories
        .filter(accessory => {
          return props.currentProduct.availableAccessories.find(a => a.id === accessory.id);
        })
        .map((acc, index) => {
          const isAdded = props.currentProduct.accessories.find(a => a.id === acc.id);
          const isInactive =
            acc.groupId &&
            // accessory with the same groupId already added
            props.currentProduct.accessories.find(
              a => a.groupId === acc.groupId && a.name !== acc.name
            );

          return (
            acc.type && (
              <AccessoryItem
                key={index}
                className={`
              ${acc.type}
              ${isInactive ? 'inactive' : ''}
              ${isAdded ? 'added' : ''}
            `}
                onClick={() => props.toggleForCurrentProduct(acc)}
              >
                {acc.imageOutline && <SVG src={acc.imageOutline.publicURL} title={acc.name} />}
                <img className="plus" src={AddAccessoryIcon} alt="" />
                <img className="minus" src={RemoveAccessoryIcon} alt="" />
                {acc.imageTable && (
                  <AccessoryItemAdded
                    {...acc.imageTable.childImageSharp}
                    imgStyle={{ objectFit: 'contain' }}
                    className={isAdded ? 'added' : ''}
                  />
                )}
              </AccessoryItem>
            )
          );
        })}
    </AccessoriesTable>
  );
};
