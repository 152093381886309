import React, { forwardRef, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { dimensions, css, colors } from '@styles';
import FormCheckbox from '@components/common/FormCheckbox';
import Calendar from '@components/configurator/contactForm/ContactFormCalendar';
import { ConfiguratorActions } from '@store/actions';
import { FormCalendar } from '@store/content/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';
import { Fiscalization } from '@store/types';

const FormCalendarPanel = styled.div<StyledProps>`
  display: none;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  ${({ visible }) =>
    visible &&
    css`
      display: flex;
    `};
`;

const CalendarInfo = styled.div`
  margin-top: 70px;

  h3 {
    font-size: 24px;
  }

  p {
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
  }

  h6 {
    font-size: ${dimensions.fontSize.large}px;
  }

  ul {
    padding: 0;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 20px;

    li {
      font-size: ${dimensions.fontSize.small}px;
      font-weight: 700;
      margin: 10px 0;
      color: ${colors.green.summary};

      span {
        margin-right: 20px;
      }
    }
  }
`;

const CalendarWrapper = styled.div`
  max-width: 330px;
`;

interface ContactFormCalendarPanelProps extends StyledProps {
  configuratorActions: typeof ConfiguratorActions;
  formCalendar: FormCalendar;
  pageSettings: ConfiguratorSettings;
  calendarDaysToVisit: number;
  fiscalization: Fiscalization | null;
}

interface StyledProps {
  visible: boolean;
}

const ContactFormCalendarPanel = forwardRef<HTMLDivElement, ContactFormCalendarPanelProps>(
  (props, ref) => {
    const wPole = useMemo(() => props.pageSettings.field_pola_wysywig || [], [props.pageSettings]);

    useEffect(() => {
      if (!props.visible) {
        setTimeout(() => {
          props.configuratorActions.getFormCalendar({
            ...props.formCalendar,
            selectServiceDate: false,
          });
        }, 300);
      }
    }, [props.visible]);

    const handleChangeCheckbox = name => {
      props.configuratorActions.getFormCalendar({
        ...props.formCalendar,
        [name]: !props.formCalendar[name],
      });
    };

    const handleClickCalendar = date => {
      props.configuratorActions.getFormCalendar({
        ...props.formCalendar,
        serviceDate: date,
      });
    };

    return (
      <FormCalendarPanel ref={ref} visible={props.visible}>
        {props.fiscalization && props.fiscalization.calendar_text && (
        <CalendarInfo>
          {utils.SafeHtml(props.fiscalization.calendar_text)}
        </CalendarInfo>
        )}
        {props.pageSettings.field_calendar_available && (
          <>
            <FormCheckbox
              name="selectServiceDate"
              labelText={wPole[10].value}
              value={props.formCalendar.selectServiceDate}
              onChangeCheckbox={handleChangeCheckbox}
            />
            <CalendarWrapper>
              <Calendar
                isOpen={props.formCalendar.selectServiceDate}
                onClickCalendar={handleClickCalendar}
                formCalendar={props.formCalendar}
                value={props.formCalendar.serviceDate}
                pageSettings={props.pageSettings}
                calendarDaysToVisit={props.calendarDaysToVisit}
              />
            </CalendarWrapper>
          </>
        )}
      </FormCalendarPanel>
    );
  }
);

export default ContactFormCalendarPanel;
