import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import ConfiguratorGrid, { ConfiguratorGridProps } from '@components/configurator/ConfiguratorGrid';
import { RootState } from '@store/types';
import { enhanceStateWithSelectors } from '@store/index';

const mapStateToProps = (state: RootState) => enhanceStateWithSelectors(state);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DConfiguratorGridProps extends ConfiguratorGridProps {}
const DConfiguratorGrid: React.FC<DConfiguratorGridProps> = (props: DConfiguratorGridProps) => (
  <ConfiguratorGrid {...props} />
);

const ConfiguratorConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DConfiguratorGrid);

export default ConfiguratorConnected;
