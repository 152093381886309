import React from 'react';
import styled from '@emotion/styled';
import { dimensions, respondFrom, breakpoints, css } from '@styles';
import { ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions } from '@store/actions';
import ShoppingBasketModal from '@components/configurator/shoppingCart/ShoppingBasketModal';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

const LeasingModal = styled.div`
  h4,
  p {
    text-align: center;

    ${respondFrom(
      breakpoints.md,
      css`
        text-align: left;
      `
    )}

    br {
      display: none;

      ${respondFrom(
        breakpoints.md,
        css`
          display: block;
        `
      )}
    }
  }

  p {
    font-size: ${dimensions.fontSize.small}px;
  }
`;

interface LeasingModalProps {
  configuratorActions: typeof ConfiguratorActions;
  basket: ConfiguratorBasket;
  maxPriceForLeasing: string;
  pageSettings: ConfiguratorSettings;
  paymentType?: string;
}

export default (props: LeasingModalProps) => (
  <LeasingModal>
    <h4>
      {props.pageSettings.field_proste_pola[87]} {props.maxPriceForLeasing}
    </h4>
    <p>{props.pageSettings.field_proste_pola[88]}</p>
    <ShoppingBasketModal {...props} />
  </LeasingModal>
);
