import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import styled from '@emotion/styled';
import { colors } from '@styles';
import React from 'react';
import * as utils from '@utils';
import { PricesSummaryType } from '@content/types/prices';

const StyledShoppingSummaryMobilePrices = styled.div`
    display: flex;
    color: ${colors.white};
    margin-right: 20px;
    margin-bottom: 10px;
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

const StyledName = styled.span`
    font-size: 14px;
`;

const StyledCurrency = styled.span`
    font-weight: bold;
    font-size: 14px;
`;

interface Props {
    pageSettings: ConfiguratorSettings;
    prices: PricesSummaryType;
}

const ShoppingSummaryMobilePrices = ({ pageSettings, prices }: Props) => {
    const pPole = pageSettings.field_proste_pola;
    const { monthly, oneOff } = prices;

    return (
        <StyledShoppingSummaryMobilePrices>
            {
                (monthly && !!monthly.total && monthly.total.net >= 0) &&
                <StyledWrapper>
                    <StyledName>
                        {pPole ? pPole[143] : ""}
                    </StyledName>
                    <StyledCurrency>{`${utils.formatCurrency(monthly.total.net.toFixed(2))} ${pPole ? pPole[14] : ''}`}</StyledCurrency>
                </StyledWrapper>
            }
            {
                (oneOff && !!oneOff.total && oneOff.total.net >= 0) &&
                <StyledWrapper>
                    <StyledName>
                        {pPole ? pPole[145] : ""}
                    </StyledName>
                    <StyledCurrency>{`${utils.formatCurrency(oneOff.total.net.toFixed(2))} ${pPole ? pPole[14] : ''}`}</StyledCurrency>
                </StyledWrapper>
            }
        </StyledShoppingSummaryMobilePrices>
    )
}

export default ShoppingSummaryMobilePrices;