import styled from '@emotion/styled';
import { breakpoints, respondFrom, css, mixins, colors } from '@styles';
import React, { useEffect, useMemo, useRef } from 'react';
import Slick from 'react-slick';

const StyledSlider = styled.div`
  .slick {
    &-slider {
      position: relative;
      margin: 0 -8px;
    }

    &-list {
      padding: 2px 0;
      overflow: hidden;
    }

    &-track {
      display: flex;
      flex-direction: column;

      ${respondFrom(
        breakpoints.md,
        css`
          flex-direction: row;
        `
      )};
    }

    &-slide {
      margin-bottom: 20px;

      > div {
        padding: 0 8px;
        height: 100%;
      }
    }

    &-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      width: 175px;
      font-size: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -24px;
        width: 48px;
        height: 48px;
        border: 3px solid #25aa53;
        border-radius: 50%;
        ${mixins.transitionDefault};
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -9px;
        width: 18px;
        height: 18px;
        border-top: 3px solid #25aa53;
        border-right: 3px solid #25aa53;
        ${mixins.transitionDefault};
      }
    }

    &-prev {
      left: -16px;
      background-image: linear-gradient(to right, ${colors.sliderGradient}, rgba(248, 249, 248, 0));

      &::before {
        left: 18px;
      }

      &::after {
        left: 36px;
        transform: rotate(-135deg);
      }

      &:hover {
        &::before {
          transform: scale(1.05);
        }

        &::after {
          transform: rotate(-135deg) scale(1.05);
        }
      }
    }

    &-next {
      right: -16px;
      background-image: linear-gradient(to left, ${colors.sliderGradient}, rgba(248, 249, 248, 0));

      &::before {
        right: 18px;
      }

      &::after {
        right: 36px;
        transform: rotate(45deg);
      }

      &:hover {
        &::before {
          transform: scale(1.05);
        }

        &::after {
          transform: rotate(45deg) scale(1.05);
        }
      }
    }

    &-disabled {
      pointer-events: none;
      opacity: 0;
    }
  }
`;

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1360,
      settings: {
        slidesToShow: 2.5,
      },
    },
  ],
};

interface Props {
  settings: object;
  children: any;
}

const Slider = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const slides = ref.current.querySelectorAll<HTMLElement>('.slick-slide');
      Array.from(slides).reverse().forEach((slide, index) => {
        slide.style.zIndex = `${index}`;
      });
    }
  }, []);
  const settings = useMemo(() => {
    return {
      ...sliderSettings,
      ...(props.settings && props.settings),
    };
  }, [props.settings]);
  return (
    <StyledSlider ref={ref}>
      <Slick {...settings}>{props.children}</Slick>
    </StyledSlider>
  );
};

export default Slider;
