import React, { createRef } from 'react';
import styled from '@emotion/styled';
import { Link } from '@utils';
import { colors, respondFrom, breakpoints, css } from '@styles';
import Logo from '@components/common/Logo';
import ConfiguratorStepLoader from '@components/configurator/header/ConfiguratorStepLoader';
import { ConfiguratorBasket } from '@store/types';
import smoothscroll from 'smoothscroll-polyfill';
import * as utils from '@utils';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import { Funding } from '@store/content/types';

if (typeof window !== typeof undefined) {
  smoothscroll.polyfill();
}

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray};
  padding: 15px 30px;
  padding-bottom: 50px;
  z-index: 10;
  background-color: ${colors.white};

  ${respondFrom(
    breakpoints.lg,
    css`
      position: relative;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      height: 100px;
      padding: 0 30px;
    `
  )}

  img {
    height: 28px;
  }
`;

const Steps = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-x: scroll;

  ${respondFrom(
    breakpoints.xl,
    css`
      position: static;
      width: auto;
      overflow: visible;
      height: 100%;
    `
  )}

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 610px;
    margin: auto;

    ${respondFrom(
      breakpoints.xl,
      css`
        width: auto;
        height: 100%;
      `
    )}
  }

  li {
    position: relative;
    margin-right: 30px;
    padding-bottom: 20px;

    &:last-of-type {
      margin-right: 0;
    }

    ${respondFrom(
      breakpoints.xl,
      css`
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;
      `
    )}
  }
`;

const StepLink = styled(Link)<StepLinkProps>`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text.disabled};
  padding: 10px 0;
  pointer-events: ${props => (props.blocked ? 'none' : 'auto')};

  &.active {
    color: ${colors.text.default};
  }
  &:hover {
    text-decoration: none;
  }
`;

interface Props extends LocationDependent {
  activeStep?: string;
  basket: ConfiguratorBasket;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  location: Location;
  allFundings: Funding[];
  paymentType?: string;
}

interface StepLinkProps {
  blocked?: number;
}

class ConfiguratorHeader extends React.Component<Props> {
  wrapperRef = createRef<HTMLDivElement>();

  getStep1Percentage() {
    switch (this.props.activeStep) {
      case 'krok-1':
        return 33;
      case 'colors':
        return 66;
      case 'accessories':
        return 100;
      default:
        return 100;
    }
  }

  isStepActive(step: number) {
    return (
      this.props.activeStep === `krok-${step}` ||
      (step === 1 && ['colors', 'accessories'].indexOf(this.props.activeStep || '-') !== -1)
    );
  }
  componentDidMount() {
    this.updateScrollPos();
  }

  componentDidUpdate(prevProps: Props) {
    // only if step changed
    if (prevProps.activeStep !== this.props.activeStep) {
      this.updateScrollPos();
    }
  }

  updateScrollPos() {
    const wrapperElem = this.wrapperRef.current;
    const shouldHighlightItem = true;

    // ref already created
    if (wrapperElem && shouldHighlightItem) {
      const itemsElems = wrapperElem.querySelectorAll('li');

      // items rendered
      if (itemsElems.length > 0) {
        const activeStep = parseInt((this.props.activeStep || '').slice(-1)) || 1;
        const elem = itemsElems[activeStep - 1] as HTMLLIElement;
        const scrollLeft = elem.offsetLeft - (wrapperElem.clientWidth / 2 - elem.clientWidth / 2);

        // scroll to current item (centered)
        wrapperElem.scroll({
          left: scrollLeft,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  render() {
    const pPole = this.props.pageSettings.field_proste_pola;

    return (
      <Header>
        <div>
          <Logo noMargin={true} langcode={this.props.langcode} urls={this.props.urls} target={this.isStepActive(4) ? "_blank" : ''}/>
        </div>
        <Steps ref={this.wrapperRef}>
          <ul>
            <li>
              <StepLink
                className={`${this.isStepActive(1) ? 'active' : ''}`}
                to={utils.langLink(
                  this.props.langcode,
                  `${this.props.urls[4]}${utils.getBranchParameter(this.props.location)}`
                )}
              >
                {pPole ? pPole[0] : ''}
              </StepLink>
              <ConfiguratorStepLoader
                active={this.isStepActive(1)}
                percent={this.isStepActive(1) ? this.getStep1Percentage() : 0}
              />
            </li>
            <li>
              <StepLink
                className={`${this.isStepActive(2) ? 'active' : ''}`}
                to={utils.langLink(
                  this.props.langcode,
                  `${this.props.urls[6]}${utils.getBranchParameter(this.props.location)}`
                )}
                blocked={
                  this.props.basket.products.length === 0 ||
                  utils.isFundingUnavaiable(this.props.basket) ||
                  utils.isSomeProductUnavailable(this.props.basket, this.props.paymentType)
                    ? 1
                    : 0
                }
              >
                {pPole ? pPole[1] : ''}
              </StepLink>
              <ConfiguratorStepLoader
                active={this.isStepActive(2)}
                percent={this.isStepActive(2) ? 100 : 0}
              />
            </li>
            <li>
              <StepLink
                className={`${this.isStepActive(3) ? 'active' : ''}`}
                to={utils.langLink(
                  this.props.langcode,
                  `${this.props.urls[7]}${utils.getBranchParameter(this.props.location)}`
                )}
                blocked={
                  this.props.basket.products.length === 0 ||
                  this.props.basket.tariff === null ||
                  utils.isFundingUnavaiable(this.props.basket) ||
                  utils.isSomeProductUnavailable(this.props.basket, this.props.paymentType)
                    ? 1
                    : 0
                }
              >
                {pPole ? pPole[2] : ''}
              </StepLink>
              <ConfiguratorStepLoader
                active={this.isStepActive(3)}
                percent={this.isStepActive(3) ? 100 : 0}
              />
            </li>
            <li>
              <StepLink
                className={`${this.isStepActive(4) ? 'active' : ''}`}
                to={utils.langLink(
                  this.props.langcode,
                  `${this.props.urls[8]}${utils.getBranchParameter(this.props.location)}`
                )}
                blocked={
                  this.props.basket.products.length === 0 ||
                  this.props.basket.tariff === null ||
                  this.props.basket.fiscalization === null ||
                  utils.isFundingUnavaiable(this.props.basket) ||
                  utils.isSomeProductUnavailable(this.props.basket, this.props.paymentType)
                    ? 1
                    : 0
                }
              >
                {pPole ? pPole[3] : ''}
              </StepLink>
              <ConfiguratorStepLoader
                active={this.isStepActive(4)}
                percent={this.isStepActive(4) ? 100 : 0}
              />
            </li>
          </ul>
        </Steps>
        <div></div>
      </Header>
    );
  }
}

export default ConfiguratorHeader;
