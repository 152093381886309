import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import TariffOption from './TariffOption';
import { ConfiguratorBasket, Tariff, Funding } from '@store/types';
import { ConfiguratorActions, CennikActions } from '@store/actions';
import * as utils from '@utils';
import { LocationDependent } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import Slider from '@components/common/Slider';

const Wrapper = styled.div`
  &.recommended {
    ${respondFrom(
      breakpoints.md,
      css`
        > div {
          margin-top: 45px;
        }
      `
    )};
  }
`;

interface Props extends LocationDependent {
  tariffs: Tariff[];
  configuratorActions: typeof ConfiguratorActions | typeof CennikActions;
  currentTariff: Tariff | null;
  currentFundings: Funding[];

  className?: string;
  iconPlus?: boolean;
  pageSettings: ConfiguratorSettings;
  langcode: string;

  basket: ConfiguratorBasket;
  allFundings: Funding[];
  sliderSettings?: object;
  showAdditionalCost?: boolean;
}

class ConfiguratorTariff extends React.Component<Props> {
  render() {
    const {
      tariffs,
      configuratorActions,
      currentFundings,
      className,
      iconPlus,
      location,
      pageSettings,
      langcode,
      showAdditionalCost
    } = this.props;
    const tariffsLength = tariffs ? tariffs[langcode].length : 0;

    return (
      <Wrapper
        className={`
        ${className || ''}
        ${
          tariffs[langcode].find(tariff => utils.setRecommendedBadge(location, tariff.recommended))
            ? 'recommended'
            : ''
        }
      `}
      >
        <Slider 
          settings={{
            slidesToShow: tariffsLength < 4 ? tariffsLength : 4,
            ...(this.props.sliderSettings && this.props.sliderSettings),
          }}
        >
          {tariffs[langcode].map((tariff, index) => {
            const tariffPrice = utils.prices.getTariffPrice({
              tariff,
            });
            return (
              <TariffOption
                {...this.props}
                tariff={tariff}
                key={index}
                configuratorActions={configuratorActions}
                isSelected={!!this.props.currentTariff && this.props.currentTariff.id === tariff.id}
                iconPlus={iconPlus}
                className={className}
                tariffPrice={tariffPrice}
                showAdditionalCost={showAdditionalCost}
              />
            );
          })}
        </Slider>
      </Wrapper>
    );
  }
}

export default ConfiguratorTariff;
