import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css, mixins } from '@styles';

import { Accessory, BasketProduct, ConfiguratorBasket } from '@store/types';
import { ConfiguratorActions, CennikActions } from '@store/actions';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

import Slider from 'react-slick';
import Container from '@components/layout/Container';
import AccessoriesProductListItem from '@components/configurator/accessories/AccessoriesListItem';
import { LocationDependent } from '@content/types/general';
import * as utils from '@utils';
import { Funding } from '@store/content/types';

const ProductAccessoriesListWrapper = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      /* overflow-x: hidden; */
    `
  )}

  .slick {
    &-track {
      display: flex;
      flex-flow: column;

      ${respondTo(
        breakpoints.lg,
        css`
          height: auto !important;
        `
      )}

      ${respondFrom(
        breakpoints.lg,
        css`
          flex-flow: row;
        `
      )}
    }

    &-arrow {
      position: absolute;
      top: -50px;
      bottom: 0;
      margin: auto;
      z-index: 10;
      width: 60px;
      font-size: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 3px;
        width: 13px;
        background-color: ${colors.green.dark};
        border-radius: 2px;
        ${mixins.transitionDefault};
      }

      &:hover {
        &::before,
        &::after {
          background-color: ${colors.green.default};
        }
      }
    }

    &-prev {
      left: 0;
      background-image: linear-gradient(to right, ${colors.sliderGradient}, rgba(248, 249, 248, 0));

      &::before {
        transform: rotate(-45deg) translateY(-5px);
      }

      &::after {
        transform: rotate(45deg) translateY(5px);
      }

      &:hover {
        &::before {
          transform: rotate(-45deg) translateY(-6px) scale(1.2);
        }

        &::after {
          transform: rotate(45deg) translateY(6px) scale(1.2);
        }
      }
    }

    &-next {
      right: 0;
      background-image: linear-gradient(to left, ${colors.sliderGradient}, rgba(248, 249, 248, 0));

      &::before {
        transform: rotate(45deg) translateY(-5px);
      }

      &::after {
        transform: rotate(-45deg) translateY(5px);
      }

      &:hover {
        &::before {
          transform: rotate(45deg) translateY(-6px) scale(1.2);
        }

        &::after {
          transform: rotate(-45deg) translateY(6px) scale(1.2);
        }
      }
    }

    &-disabled {
      pointer-events: none;
      opacity: 0;
    }
  }
`;

interface Props extends LocationDependent {
  accessories: Accessory[];
  basket: ConfiguratorBasket;
  configuratorActions: typeof ConfiguratorActions | typeof CennikActions;
  currentProduct: BasketProduct | null;
  iconPlus?: boolean;
  pageSettings: ConfiguratorSettings;
  allFundings: Funding[];
}

class AccessoriesList extends React.Component<Props> {
  toggleForCurrentProduct = (accessory: Accessory) => {
    const currentAccessoriesRef = this.getCurrentAccessories();
    const { name, id, price } = accessory;

    if (!currentAccessoriesRef.find(a => a.id === accessory.id)) {
      this.props.configuratorActions.basketAddAccessory({ accessory });
      utils.setDataLayerAddToCart(name, id, price, 'Accessory');
    } else {
      this.props.configuratorActions.basketRemoveAccessory({ accessory });
      utils.setDatalayerRemoveFromCart(name, id, price, 'Accessory');
    }
  };

  componentDidUpdate() {
   if(this.props.currentProduct && !utils.productHasAvailableAccessories(this.props.currentProduct)) {
    this.props.currentProduct.accessories.forEach(accessory => {
      this.props.configuratorActions.basketRemoveAccessory({ accessory });
    });
   }
  }

  getCurrentAccessories = (): Accessory[] => {
    return this.props.currentProduct
      ? this.props.currentProduct.accessories
      : this.props.basket.accessories;
  };

  isAdded = (accessory: Accessory): boolean => {
    return !!this.getCurrentAccessories().find(a => a.id === accessory.id);
  };

  render() {
    const { accessories, iconPlus, location, pageSettings } = this.props;

    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            arrows: false,
            slidesToShow: accessories.length,
            vertical: true,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1560,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };

    return (
      <ProductAccessoriesListWrapper>
        <Container>
          {this.props.currentProduct && (
            <Slider {...sliderSettings}>
              {accessories
                .filter(accessory => {
                  return this.props.currentProduct.availableAccessories.find(
                    a => a.id === accessory.id
                  );
                })
                .map((accessory, index) => {
                  // accessory with the same groupId already added
                  const isInactive =
                    (!!this.props.currentProduct &&
                      !utils.productHasAvailableAccessories(this.props.currentProduct)) ||
                    (!!accessory.groupId &&
                      !!this.getCurrentAccessories().find(
                        a => a.groupId === accessory.groupId && a.name !== accessory.name
                      ));

                  return (
                    <AccessoriesProductListItem
                      key={index}
                      isAdded={this.isAdded(accessory)}
                      isInactive={isInactive}
                      accessory={accessory}
                      toggleForCurrentProduct={this.toggleForCurrentProduct}
                      iconPlus={iconPlus}
                      location={location}
                      pageSettings={pageSettings}
                    />
                  );
                })}
            </Slider>
          )}
        </Container>
      </ProductAccessoriesListWrapper>
    );
  }
}

export default AccessoriesList;
