import React, { Component } from 'react';
import styled from '@emotion/styled';
import { validateInputs } from '@components/configurator/contactForm/ContactFormInputsData';
import { colors, dimensions, respondFrom, breakpoints, css, mixins } from '@styles';
import FormInput from '@components/common/FormInput';
import FormCheckbox from '@components/common/FormCheckbox';
import Title from '@components/common/Title';
import freeDeliveryIcon from '@assets/svg/free-delivery-icon.svg';
import { NipInput } from '@components/configurator/contactForm/ContactFormInputNip';
import { ConfiguratorActions } from '@store/actions';
import { FormData, FormValidation, FormDeliveryData } from '@store/content/types';
import Modal from '@components/common/Modal';
import ContactFormModal from '@components/configurator/contactForm/ContactFormModalSelectNip';
import ContactFormModalRewriteData from '@components/configurator/contactForm/ContactFormModalRewriteData';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import ContactFormDelivery from '@components/configurator/contactForm/ContactFormDelivery';
import * as utils from '@utils';
import Collapsible from 'react-collapsible';
import { Agreement } from '@content/types/agreement';

const ContactForm = styled.div`
  padding: 0 30px;
  position: relative;
  max-width: 750px;
  margin: 0 auto;

  ${respondFrom(
    breakpoints.xl,
    css`
      padding: 0 50px;
    `
  )}

  p {
    font-size: ${dimensions.fontSize.large}px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  h5 {
    font-size: ${dimensions.fontSize.large}px;
    font-weight: 300;
    margin-bottom: 24px;
  }
`;

const FormBanner = styled.div`
  padding: 20px 0;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  background-color: rgba(47, 175, 88, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 16px;
    font-weight: bold;
    color: #2faf58;
    margin-left: 20px;
  }
`;

export const Row = styled.div<StyleProps>`
  ${respondFrom(
    breakpoints.md,
    css`
      display: flex;
      justify-content: space-between;
    `
  )}

  &.two-children {
    > div {
      ${respondFrom(
        breakpoints.md,
        css`
          &:nth-last-of-type(2) {
            width: 130px;
            margin-right: 20px;
            margin-bottom: 0;
          }
          &:nth-last-of-type(1) {
            width: calc(100% - 150px);
            margin-bottom: 0;
          }
        `
      )}
    }
  }

  &.three-children {
    > div {
      ${respondFrom(
        breakpoints.md,
        css`
          &:nth-last-of-type(3) {
            width: calc(100% - 300px);
            margin-right: 20px;
            margin-top: 0;
            margin-bottom: 0;
          }
          &:nth-last-of-type(2) {
            width: 130px;
            margin-right: 20px;
            margin-top: 0;
            margin-bottom: 0;
          }
          &:nth-last-of-type(1) {
            width: 130px;
            margin-top: 0;
            margin-bottom: 0;
          }
        `
      )}
    }
  }
`;

const AccordionWrapper = styled.div`
  .Collapsible {
    margin-top: -15px;
    margin-bottom: 20px;
    margin-left: 30px;
    font-size: ${dimensions.fontSize.smaller}px;

    ol {
      padding-left: 20px;
      margin: 0;
      li {
        p {
          font-size: ${dimensions.fontSize.smaller}px;
          margin-bottom: 5px;
          &:before {
            display: none;
          }
        }
      }
    }

    &__trigger {
      position: relative;
      color: ${colors.green.dark};
      cursor: pointer;
      padding-right: 20px;

      &::after {
        content: '';
        position: absolute;
        top: -3px;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 7px;
        height: 7px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: center;
        ${mixins.transitionDefault};
      }

      &.is-open {

        &::after {
          transform: translate(0, 3px) rotate(225deg);
        }
      }
    }
  }
`;

interface StyleProps {
  address?: boolean;
}

interface FormProps {
  configuratorActions: typeof ConfiguratorActions;
  formData: FormData;
  formValidation: FormValidation;
  allFieldsValidation: Function;
  pageSettings: ConfiguratorSettings;
  langcode: string;
  urls: Array<string>;
  allSimplePages: any;
  deliveryData: FormDeliveryData;
  lockerValid: boolean;
  paymentType?: string;
  agreements: Agreement[];
}

class ContactFormPanel extends Component<FormProps> {
  state = {
    modalOpened: false,
    modalRewriteDataOpened: false,
    nipApiData: [],
  };

  componentDidMount() {
    this.setState(this.props.formData);
  }

  componentDidUpdate(prevProps) {
    // Remove unnecessary agreements after change method payment
    if(prevProps.paymentType !== this.props.paymentType) {
      if (this.props.agreements && this.props.paymentType && this.props.formData.agreements) {
        const agreementsByPaymentType = utils
          .filterAgreementsByPaymentType(this.props.agreements, this.props.paymentType)
          .map(agreement => agreement.id);
        this.props.configuratorActions.getFormData({
          ...this.props.formData,
          agreements: this.props.formData.agreements.filter(agreement =>
            agreementsByPaymentType.includes(agreement)
          )
        });
      }
    }
  }

  onCheckNip = () => {
    this.props.configuratorActions.setLoading(true);
    const nip = this.props.formData.nip;
    const { required, match } = this.props.formValidation.nip;

    if (required && match && nip !== '') {
      fetch(`${process.env.IPOS_IPSALE_API_URL}/nip/${nip}`, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(response => {
          if (response && response.length > 1) {
            this.setState({ modalOpened: true, nipApiData: response });
          } else {
            this.updateFieldsByNip(response[0]);
            this.props.allFieldsValidation();
          }
        })
        .finally(() => {
          this.props.configuratorActions.setLoading(false);
        });
    }
  };

  updateFieldsByNip = data => {
    if (!data) return;

    const formData = { ...this.props.formData };

    formData.company = data.name ? data.name : '';
    formData.address1Street = data.address.street ? data.address.street : '';
    formData.address1HouseNumber = data.address.num1 ? data.address.num1 : '';
    formData.address1ApartmentNumber = data.address.num2 ? data.address.num2 : '';
    formData.address1PostalCode = data.address.zip ? data.address.zip : '';
    formData.address1City = data.address.city ? data.address.city : '';

    this.setState({ modalOpened: false }, () => {
      this.onCloseModalPanel();
      this.props.configuratorActions.getFormData(formData);
      setTimeout(() => this.props.allFieldsValidation(), 100);
    });
  };

  onChangeInput = (value, name) => {
    const formStore = { ...this.props.formData };
    formStore[name] = value;

    this.props.configuratorActions.getFormData(formStore);
    this.onInputValidate(name, value, '');
  };

  onBlurInput = name => {
    const value = this.props.formData[name];
    this.onInputValidate(name, value, 'blur');
  };

  onInputValidate = (name, value, type) => {
    const validation = { ...this.props.formValidation };
    const validationUpdate = {};
    let required = true;
    let match = true;

    if (type === 'blur') {
      if (validateInputs[name]) {
        if (validateInputs[name].required.value) {
          required = value !== '' ? true : false;
        } else {
          required = true;
        }

        if (validateInputs[name].match.value !== false) {
          match = value.match(validateInputs[name].match.value) !== null ? true : false;
        } else {
          match = true;
        }
      }
    } else {
      if (validateInputs[name] && !this.props.formValidation[name].match) {
        if (validateInputs[name].required.value) {
          required = value !== '' ? true : false;
        } else {
          required = true;
        }

        if (validateInputs[name].match.value !== false) {
          match = value.match(validateInputs[name].match.value) !== null ? true : false;
        } else {
          match = true;
        }
      }
    }

    validationUpdate[name] = { required, match };
    this.props.configuratorActions.getFormValidation(Object.assign(validation, validationUpdate));

    if (name === 'nip') {
      this.props.configuratorActions.checkNipControlSum(this.nipControlSum(value));
    }
  };

  onChangeCheckbox = name => {
    const formData = { ...this.props.formData };

    formData[name] = !this.props.formData[name];
    this.props.configuratorActions.getFormData(formData);
  };

  onChangeCheckboxAgreement = id => {
    const agreements = (this.props.formData.agreements && this.props.formData.agreements.includes(id)) ?
      this.props.formData.agreements.filter(agreementID => agreementID !== id) :
      [...this.props.formData.agreements || [], id];
    
    this.props.configuratorActions.getFormData({
      ...this.props.formData,
      agreements
    });
  };

  onCloseModalPanel = () => this.setState({ modalOpened: false });
  onCloseModalRewriteData = () => this.setState({ modalRewriteDataOpened: false });

  checkIfDataWillRewrite = () => {
    const nip = this.props.formData.nip;
    const { required, match } = this.props.formValidation.nip;
    const toCheck = [
      'company',
      'address1City',
      'address1HouseNumber',
      'address1Street',
      'address1PostalCode',
    ];
    let fieldsEmpty = true;

    toCheck.forEach(field => {
      if (this.props.formData[field] !== '') {
        fieldsEmpty = false;
      }
    });

    if (required && match && nip !== '') {
      if (!fieldsEmpty) {
        this.setState({ modalRewriteDataOpened: true });
      } else {
        this.onCheckNip();
      }
    }
  };

  onRewriteData = () => {
    this.setState({ modalRewriteDataOpened: false }, () => {
      this.onCheckNip();
    });
  };

  onNotRewriteData = () => {
    this.setState({ modalRewriteDataOpened: false });
  };

  nipControlSum = (nip: string) => {
    const nipWithoutDashes = nip.replace(/-/g, '');
    const regex = /^[0-9]{10}$/;
    const regexSameChars = /^(.)\1+$/;

    if (!regex.test(nipWithoutDashes) || regexSameChars.test(nipWithoutDashes)) {
      return false;
    } else {
      const digits = ('' + nipWithoutDashes).split('');
      const checksum =
        (6 * parseInt(digits[0]) +
          5 * parseInt(digits[1]) +
          7 * parseInt(digits[2]) +
          2 * parseInt(digits[3]) +
          3 * parseInt(digits[4]) +
          4 * parseInt(digits[5]) +
          5 * parseInt(digits[6]) +
          6 * parseInt(digits[7]) +
          7 * parseInt(digits[8])) %
        11;

      return parseInt(digits[9]) === checksum;
    }
  };

  render() {
    const pPole = this.props.pageSettings.field_proste_pola;
    const wPole = this.props.pageSettings.field_pola_wysywig;
    const inputsInfo = [
      { name: 'company', type: 'text', labelText: pPole ? pPole[51] : '', maxlength: 100 },
      { name: 'name', type: 'text', labelText: pPole ? pPole[52] : '', maxlength: 100 },
      { name: 'phone', type: 'tel', labelText: pPole ? pPole[53] : '', maxlength: 9 },
      { name: 'email', type: 'email', labelText: pPole ? pPole[54] : '', maxlength: 100 },
    ];
    const inputsAddress1 = [
      [
        { name: 'address1Street', type: 'text', labelText: pPole ? pPole[56] : '', maxlength: 40 },
        {
          name: 'address1HouseNumber',
          type: 'text',
          labelText: pPole ? pPole[57] : '',
          maxlength: 10,
        },
        {
          name: 'address1ApartmentNumber',
          type: 'text',
          labelText: pPole ? pPole[58] : '',
          maxlength: 10,
        },
      ],
      [
        { name: 'address1PostalCode', type: 'text', labelText: pPole ? pPole[59] : '' },
        { name: 'address1City', type: 'text', labelText: pPole ? pPole[60] : '', maxlength: 40 },
      ],
    ];

    return (
      <ContactForm>
        <Title>{pPole ? pPole[47] : ''}</Title>
        <FormBanner>
          <img src={freeDeliveryIcon} />
          <span>{pPole ? pPole[48] : ''}</span>
        </FormBanner>
        <form>
          <NipInput>
            <FormInput
              name="nip"
              type="text"
              labelText={pPole ? pPole[49] : ''}
              labelDescription={pPole ? pPole[50] : ''}
              maxlength={10}
              value={this.props.formData.nip}
              onChangeInput={this.onChangeInput}
              onBlurInput={this.onBlurInput}
              validation={this.props.formValidation.nip}
              validateSchema={validateInputs.nip}
              configuratorActions={this.props.configuratorActions}
              nipControlSumErrorMessage={pPole ? pPole[81] : ''}
              nipControlSum={
                this.props.formData.nip === ''
                  ? false
                  : !this.nipControlSum(this.props.formData.nip)
              }
              textsList={pPole ? pPole : []}
            />
            <button
              onClick={this.checkIfDataWillRewrite}
              type="button"
              className={
                !this.props.formValidation.nip.required ||
                !this.props.formValidation.nip.match ||
                (!this.nipControlSum(this.props.formData.nip) && this.props.formData.nip !== '')
                  ? 'error'
                  : ''
              }
            >
              {pPole ? pPole[65] : ''}
            </button>
          </NipInput>
          {inputsInfo.map(input => (
            <FormInput
              key={input.name}
              name={input.name}
              type={input.type}
              labelText={input.labelText}
              maxlength={input.maxlength}
              value={this.props.formData[input.name]}
              onChangeInput={this.onChangeInput}
              onBlurInput={this.onBlurInput}
              validation={this.props.formValidation[input.name]}
              validateSchema={validateInputs[input.name]}
              configuratorActions={this.props.configuratorActions}
              textsList={pPole ? pPole : []}
            />
          ))}

          <h5>{pPole ? pPole[55] : ''}</h5>

          {inputsAddress1.map((row, index) => (
            <Row key={index} className={index === 0 ? 'three-children' : 'two-children'}>
              {row.map(input => (
                <FormInput
                  key={input.name}
                  name={input.name}
                  type={input.type}
                  labelText={input.labelText}
                  maxlength={input.maxlength}
                  value={this.props.formData[input.name]}
                  onChangeInput={this.onChangeInput}
                  onBlurInput={this.onBlurInput}
                  validation={this.props.formValidation[input.name]}
                  validateSchema={validateInputs[input.name]}
                  configuratorActions={this.props.configuratorActions}
                  textsList={pPole ? pPole : []}
                />
              ))}
            </Row>
          ))}

          <ContactFormDelivery
            configuratorActions={this.props.configuratorActions}
            onChangeInput={this.onChangeInput}
            onBlurInput={this.onBlurInput}
            deliveryData={this.props.deliveryData}
            pageSettings={this.props.pageSettings}
            formData={this.props.formData}
            formValidation={this.props.formValidation}
            lockerValid={this.props.lockerValid}
          />

          {/* <FormCheckbox
            name="sameDeliveryAddress"
            labelText={pPole ? pPole[61] : ''}
            space="big"
            value={this.props.formData.sameDeliveryAddress}
            onChangeCheckbox={this.onChangeCheckbox}
          /> */}

          {/* {!this.props.formData.sameDeliveryAddress ? (
            <div>
              <h5>
                {pPole
                  ? pPole[62]
                  : ''}
              </h5>
              {inputsAddress2.map((row, index) => (
                <Row key={index} className={index === 0 ? 'three-children' : 'two-children'}>
                  {row.map(input => (
                    <FormInput
                      key={input.name}
                      name={input.name}
                      type={input.type}
                      labelText={input.labelText}
                      maxlength={input.maxlength}
                      value={this.props.formData[input.name]}
                      onChangeInput={this.onChangeInput}
                      onBlurInput={this.onBlurInput}
                      validation={this.props.formValidation[input.name]}
                      validateSchema={validateInputs[input.name]}
                      configuratorActions={this.props.configuratorActions}
                      textsList={
                        pPole
                          ? pPole
                          : []
                      }
                    />
                  ))}
                </Row>
              ))}
            </div>
          ) : null} */}

          <p>{pPole ? pPole[63] : ''}</p>
          {
            (this.props.agreements && this.props.paymentType) &&
              utils.filterAgreementsByPaymentType(this.props.agreements, this.props.paymentType)
              .map(agreement => (
                <React.Fragment
                  key={agreement.id}
                >
                  <FormCheckbox
                    name={agreement.id}
                    labelText={`${agreement.content} <a target="_blank" rel="noopener noreferrer" href=${agreement.pdf.url}>${agreement.pdf.title}</a>`}
                    value={this.props.formData.agreements && this.props.formData.agreements.includes(agreement.id)}
                    onChangeCheckbox={this.onChangeCheckboxAgreement}
                  />
                  {
                    agreement.type === 'extended' &&
                    <AccordionWrapper>
                      <Collapsible trigger={pPole ? pPole[165] : ''} triggerWhenOpen={pPole ? pPole[166] : ''}>
                        {utils.SafeHtml(agreement.explication)}
                      </Collapsible>
                    </AccordionWrapper>
                  }
                </React.Fragment>
              ))
          }
        </form>
        <Modal
          isOpened={this.state.modalOpened}
          content={
            <ContactFormModal
              nipData={this.state.nipApiData}
              updateFieldsByNip={this.updateFieldsByNip}
              pageSettings={this.props.pageSettings}
            />
          }
          onCloseModalPanel={this.onCloseModalPanel}
        />
        <Modal
          isOpened={this.state.modalRewriteDataOpened}
          content={
            <ContactFormModalRewriteData
              onRewriteData={this.onRewriteData}
              onNotRewriteData={this.onNotRewriteData}
              pageSettings={this.props.pageSettings}
            />
          }
          onCloseModalPanel={this.onCloseModalRewriteData}
        />
      </ContactForm>
    );
  }
}

export default ContactFormPanel;
