import React from 'react';
import styled from '@emotion/styled';
import { dimensions, respondFrom, breakpoints, css } from '@styles';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import * as utils from '@utils';

const DiscountModal = styled.div`
  h4,
  p {
    text-align: center;

    ${respondFrom(
      breakpoints.md,
      css`
        text-align: left;
      `
    )}
  }

  p {
    font-size: ${dimensions.fontSize.small}px;
  }
`;

interface DiscountModalProps {
  pageSettings: ConfiguratorSettings;
}

export default (props: DiscountModalProps) => (
  <DiscountModal>
    {props.pageSettings.field_pola_wysywig &&
      props.pageSettings.field_pola_wysywig[18].value &&
      utils.SafeHtml(props.pageSettings.field_pola_wysywig[18].value)}
  </DiscountModal>
);
